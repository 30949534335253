import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Observable,
  Subscription,
  combineLatest,
  delay,
  filter,
  map
} from 'rxjs';
import * as fromUIStore from 'src/app/core/store';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { searchTextClearAttempt } from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';

import { LocalStorageService } from '../../services/local-storage.service';
import { locatorFeatureClicked } from '../../store/actions/locator-ui.actions';
import { smFeatureClicked } from '../../store/actions/spatial-modeller-ui.actions';
import { profilerFeatureClicked } from '../../store/actions/profiler-ui.actions';
import { isShapeDisabled } from 'src/app/locator/helpers/locator-shape.helper';

type FeatureTab = {
  feature: string | LocatorShapeTypes;
  condition: Observable<boolean>;
  tooltip: string;
  class?: string;
  icon?: string;
  disabled?: boolean;
};

@Component({
  selector: 'atlas-feature-buttons',
  templateUrl: './feature-buttons.component.html',
  styleUrls: ['./feature-buttons.component.less']
})
export class FeatureButtonsComponent implements OnInit, OnDestroy {
  isSMFeatureAllowed$ = this.UIStore$.select(fromUIStore.isSMFeatureAllowed);
  isLocatorFeatureAllowed$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureAllowed
  );
  isProfilerFeatureAllowed$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureAllowed
  );
  isSMFeatureSelected$ = this.UIStore$.select(fromUIStore.isSMFeatureSelected);
  isLocatorFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureSelected
  );
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );
  locatorShapeDefaults$ = this.UIStore$.select(
    fromUIStore.getLocatorShapesDefaults
  );

  loadedTabs: FeatureTab[] = [];
  selectedButton: string | LocatorShapeTypes;

  isProfilerFeatureDisplayed$ = this.UIStore$.select(
    fromUIStore.isProfilerDisplayedInGazetteerCarousel
  );
  isSMFeatureDisplayed$ = this.UIStore$.select(
    fromUIStore.isSmFeatureDisplayedInGazetteerCarousel
  );

  selectedFeature$ = combineLatest([
    this.isSMFeatureSelected$,
    this.isLocatorFeatureSelected$,
    this.isProfilerFeatureSelected$
  ]).pipe(
    delay(100),
    map(([smSelected, locatorSelected, profileSelected]) => {
      if (smSelected) {
        return 'Model';
      } else if (locatorSelected) {
        return this.localStorageService.get('locator-default-shape');
      } else if (profileSelected) {
        return 'Profiler';
      }
    })
  );

  private subscription = new Subscription();

  constructor(
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.selectedFeature$.subscribe((selectedFeauture) => {
        this.selectedButton = selectedFeauture;
      })
    );

    this.subscription.add(
      this.isSMFeatureAllowed$.subscribe((isSmAllowed) => {
        if (isSmAllowed) {
          const networkPlanningTab = {
            feature: 'Model',
            tooltip: 'Model catchment',
            condition: this.isSMFeatureDisplayed$,
            class: 'atlas-icon icon-model'
          };
          this.loadedTabs.splice(0, 0, networkPlanningTab);
        }
      })
    );

    this.subscription.add(
      this.isLocatorFeatureAllowed$.subscribe((isLocatorAllowed) => {
        {
          if (isLocatorAllowed) {
            const catchmentReportTabs = [
              {
                feature: LocatorShapeTypes.Circle,
                tooltip: 'Circle catchment',
                condition: this.isLocatorFeatureAllowed$,
                class: 'atlas-icon icon-circle'
              },
              {
                feature: LocatorShapeTypes.Car,
                tooltip: 'Drive catchment',
                condition: this.isLocatorFeatureAllowed$,
                icon: 'directions_car'
              },
              {
                feature: LocatorShapeTypes.PublicTransport,
                tooltip: 'Public transport catchment',
                condition: this.isLocatorFeatureAllowed$,
                icon: 'train'
              },
              {
                feature: LocatorShapeTypes.Walk,
                tooltip: 'Walk catchment',
                condition: this.isLocatorFeatureAllowed$,
                icon: 'directions_walk'
              },
              {
                feature: LocatorShapeTypes.FreeForm,
                tooltip: 'Polygon catchment',
                condition: this.isLocatorFeatureAllowed$,
                class: 'material-symbols-outlined',
                icon: 'activity_zone'
              }
            ];
            this.insertCatchmentReportingTabs(catchmentReportTabs);
          }
        }
      })
    );

    this.subscription.add(
      this.locatorShapeDefaults$
        .pipe(filter((locatorShapeDefaults) => !!locatorShapeDefaults))
        .subscribe((locatorShapeDefaults) => {
          this.loadedTabs.map((item) => {
            switch (item.feature) {
              case LocatorShapeTypes.Car:
                item.disabled = isShapeDisabled(locatorShapeDefaults?.car!);
                break;
              case LocatorShapeTypes.Walk:
                item.disabled = isShapeDisabled(locatorShapeDefaults?.walk!);
                break;
              case LocatorShapeTypes.Circle:
                item.disabled = isShapeDisabled(locatorShapeDefaults?.circle!);
                break;
              case LocatorShapeTypes.FreeForm:
                item.disabled = isShapeDisabled(locatorShapeDefaults?.polygon!);
                break;
              case LocatorShapeTypes.PublicTransport:
                item.disabled = isShapeDisabled(
                  locatorShapeDefaults?.publicTransport!
                );
                break;
            }
          });
        })
    );

    this.subscription.add(
      this.isProfilerFeatureAllowed$.subscribe((isProfilerAllowed) => {
        if (isProfilerAllowed) {
          const profilerTab = {
            feature: 'Profiler',
            tooltip: 'Profiling',
            condition: this.isProfilerFeatureDisplayed$,
            class: undefined,
            icon: 'people'
          };
          this.loadedTabs.splice(this.loadedTabs.length, 0, profilerTab);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onTabButtonClicked(arg: string | LocatorShapeTypes) {
    if (typeof arg === 'number') {
      this.onButtonValueChange(arg);
    } else if (arg === 'Model') {
      this.onSmClicked();
    } else if (arg === 'Profiler') {
      this.onProfilerClicked();
    }
  }

  onSmClicked() {
    this.gazetteerStore$.dispatch(searchTextClearAttempt());
    this.UIStore$.dispatch(smFeatureClicked());
  }

  onLocatorClicked(locatorShape: LocatorShapeTypes) {
    this.UIStore$.dispatch(
      locatorFeatureClicked({ locatorShapeType: locatorShape })
    );
  }

  onProfilerClicked() {
    this.UIStore$.dispatch(profilerFeatureClicked());
    this.gazetteerStore$.dispatch(searchTextClearAttempt());
  }

  onButtonValueChange(value: any) {
    this.localStorageService.set('locator-default-shape', value);

    setTimeout(() => this.gazetteerStore$.dispatch(searchTextClearAttempt()));
    this.onLocatorClicked(value);
  }

  getSelectedTabIndex(): number {
    const selectedIndex = this.loadedTabs
      .map((tab) => tab.feature)
      .indexOf(this.selectedButton);
    return selectedIndex >= 0 ? selectedIndex : 0;
  }

  private insertCatchmentReportingTabs(catchmentReportTabs: FeatureTab[]) {
    if (this.loadedTabs.length > 0 && this.loadedTabs[0].feature === 'Model') {
      this.loadedTabs.splice(1, 0, ...catchmentReportTabs);
    } else {
      this.loadedTabs.splice(0, 0, ...catchmentReportTabs);
    }
  }
}
