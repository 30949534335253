import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'atlas-single-button-dialog',
  templateUrl: './atlas-single-button-dialog.component.html',
  styleUrls: ['./atlas-single-button-dialog.component.less'],
})
export class AtlasSingleButtonDialogComponent {
  @Input() headerText: string;
  @Input() closeOnSave = true;
  @Input() buttonText = 'Cancel';
  @Input() buttonDisabled = false;

  @Output() buttonClicked = new EventEmitter();
}
