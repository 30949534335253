import { createSelector } from '@ngrx/store';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { getLocatorState } from '../reducers';
import * as fromLocator from '../reducers/locator.reducer';

export const getLocatorLibraryId = createSelector(
  getLocatorState,
  fromLocator.getId
);

export const getLocatorLibraryName = createSelector(
  getLocatorState,
  fromLocator.getName
);

export const getShapeGenerated = createSelector(
  getLocatorState,
  fromLocator.getShapeGenerated
);

export const getLocatorPowerBiReports = createSelector(
  getLocatorState,
  fromLocator.getPowerBiReports
);

export const getSelectedShapes = createSelector(
  getLocatorState,
  fromLocator.getSelectedShapes
);

export const getSelectedShapeIds = createSelector(
  getSelectedShapes,
  getShapeIds
);

export const getSelectedLocation = createSelector(
  getLocatorState,
  fromLocator.getSelectedLocation
);

export const getLofiReport = createSelector(
  getLocatorState,
  fromLocator.getLofiReport
);

export const getIsLofiReportAvailable = createSelector(
  getLofiReport,
  isLofiReportAvailable
);

export const getSelectedLocationId = createSelector(
  getSelectedLocation,
  convertSelectedDatapointId
);

export const getIsAnyShapeAvailable = createSelector(
  getSelectedShapes,
  isAnyShapeSelected
);

export const getEditingExistingPolygonShape = createSelector(
  getLocatorState,
  fromLocator.getEditingExistingPolygonShape
);

export const getLocatorReferenceLibraries = createSelector(
  getLocatorState,
  fromLocator.getReferenceLibraries
);

export const getBatchOperationLocationIds = createSelector(
  getLocatorState,
  fromLocator.getBatchOperationLocationIds
);

export const areMultipleLocationsSelected = createSelector(
  getBatchOperationLocationIds,
  (locationIds) => locationIds && locationIds.length > 1
);

function convertSelectedDatapointId(
  selectedDataPoint: LocatorLibraryData | null
): number | null {
  return selectedDataPoint == null ? null : selectedDataPoint.id;
}

function isLofiReportAvailable(
  report: LocatorLofiQueryResult[] | null
): boolean {
  return !!report && report.length > 0;
}

function isAnyShapeSelected(locatorShapes: LocatorShape[] | null): boolean {
  return !!locatorShapes && locatorShapes.length > 0;
}

function getShapeIds(locatorShapes: LocatorShape[] | null): number[] {
  return locatorShapes != null ? locatorShapes.map((shape) => shape.id) : [];
}
