import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Scenario } from 'src/app/core/models/scenario';

import { environment } from 'src/environments/environment';
import { DeltaSummary } from '../models/delta-summary';
import { SupplyPoint } from '../models/supply-point';

@Injectable({ providedIn: 'root' })
export class ScenarioService {
  private atlasScenarioApiUrl = `${environment.baseUrl}api/scenarios/`;

  constructor(private http: HttpClient) {}

  getScenarioById(id: number) {
    return this.http.get<Scenario>(`${this.atlasScenarioApiUrl}${id}`);
  }

  getLastedEditedScenario() {
    return this.http.get<Scenario>(`${this.atlasScenarioApiUrl}last-edited`);
  }

  getAllScenarios() {
    return this.http.get<Scenario[]>(`${this.atlasScenarioApiUrl}`);
  }

  createScenario(scenarioRequest: {
    name: string;
    baseNames: string[];
    deltaNames: string[];
  }) {
    return this.http.post<Scenario>(
      `${this.atlasScenarioApiUrl}`,
      scenarioRequest
    );
  }

  updateScenario(
    id: number,
    scenarioRequest: {
      name: string;
      baseNames: string[];
      deltaNames: string[];
    }
  ) {
    return this.http.put<Scenario>(
      `${this.atlasScenarioApiUrl}${id}`,
      scenarioRequest
    );
  }

  deleteScenario(id: number) {
    return this.http.delete(`${this.atlasScenarioApiUrl}${id}`);
  }

  saveSupplyPoint(scenarioId: number, supplyPoint: SupplyPoint) {
    return this.http.post<SupplyPoint>(
      `${this.atlasScenarioApiUrl}${scenarioId}/delta/supply-points`,
      supplyPoint
    );
  }

  deleteSupplyPoint(scenarioId: number, supplyPointId: number) {
    return this.http.delete<SupplyPoint>(
      `${this.atlasScenarioApiUrl}${scenarioId}/delta/supply-points/${supplyPointId}`
    );
  }

  getDeltaSupplyPointSummary(scenarioId: number) {
    return this.http.get<DeltaSummary>(
      `${this.atlasScenarioApiUrl}${scenarioId}/delta/supply-point-summary`
    );
  }

  closeSupplyPoint(scenarioId: number, supplyPoint: SupplyPoint) {
    return this.http.put<SupplyPoint>(
      `${this.atlasScenarioApiUrl}${scenarioId}/delta/supply-points/closed`,
      supplyPoint
    );
  }

  reopenSupplyPoint(scenarioId: number, supplyPoint: SupplyPoint) {
    return this.http.put<SupplyPoint>(
      `${this.atlasScenarioApiUrl}${scenarioId}/delta/supply-points/reopen`,
      supplyPoint
    );
  }

  getAllDeltaSupplyPoints(scenarioId: number) {
    return this.http.get<SupplyPoint[]>(
      `${this.atlasScenarioApiUrl}${scenarioId}/delta/supply-points`
    );
  }

  moveDeltaSupplyPoints(
    scenarioId: number,
    toScenarioId: number,
    supplyIds: string[]
  ) {
    return this.http.post<boolean>(
      `${this.atlasScenarioApiUrl}${scenarioId}/delta/move-scenario`,
      { toScenarioId: toScenarioId, supply_Ids: supplyIds }
    );
  }
}
