<form [formGroup]="locatorImportLibraryDataForm" autocomplete="off">
  @if(hasLocatorLibraryLocationsLimitBeenExceeded){
  <div class="header-padding text error-message">
    You have exceeded the number of locations that can be imported into a
    library, please import fewer records, or select a different library with
    fewer locations
  </div>
  }
  <div class="header-padding text">
    Select a .csv file to upload a location data file.
  </div>
  <div class="file-container">
    <div class="file-button-padding">
      <input
        type="file"
        (change)="onFileSelected($event)"
        accept=".csv"
        class="file file-button-padding"
        #fileUpload
      />
      <button (click)="fileUpload.click()" class="file-button">
        Select File
      </button>
    </div>
    <div class="link">
      <a (click)="onDownloadClicked()">Download template</a>
    </div>
  </div>

  <mat-form-field appearance="outline" hideRequiredMarker="true">
    <mat-label>File location</mat-label>
    <input
      matInput
      type="text"
      [value]="fileName || ''"
      placeholder="File location"
      readonly
    />
  </mat-form-field>
  <div>
    <mat-radio-group
      aria-label="Select an option"
      formControlName="geocodingSelection"
    >
      <mat-radio-button
        [value]="false"
        class="radio-button-margin-left radio-button-margin-right"
        ><mat-label>Latitude and Longitude</mat-label>
      </mat-radio-button>

      <mat-radio-button [value]="true"
        ><mat-label>Geocode</mat-label></mat-radio-button
      >
    </mat-radio-group>
  </div>
  @if(locatorImportLibraryDataForm.controls.geocodingSelection.value){
  <div class="warning-text">
    <span> Automated geocoding will be used to provide coordinates. </span>
  </div>
  }
</form>
