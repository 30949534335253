import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LocatorService } from 'src/app/locator/services/locator.service';
import * as fromAppFeatureUIStore from 'src/app/core/store';
import * as fromLocatorStore from 'src/app/locator-store';

import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { Store } from '@ngrx/store';
import { LocatorImportLibraryDataFormComponent } from '../locator-import-library-data-form/locator-import-library-data-form';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import {
  hasLocatorLibraryLocationsLimitBeenExceededAttempt,
  locatorFileImportAttempt
} from 'src/app/locator-store/actions/locator-import.actions';

@Component({
  selector: 'atlas-locator-import-library-data-dialog',
  templateUrl: './locator-import-library-data-dialog.component.html'
})
export class LocatorImportLibraryDataDialogComponent implements OnInit {
  dialogMode: DialogMode;
  userLibraries: string[] = [];
  hasLocatorLibraryLocationsLimitBeenExceeded: boolean;

  tenantImportUsingGeocoding: boolean;
  importUsingGeocoding$ = this.featureUIStore$.select(
    fromAppFeatureUIStore.getCatchmentReportingImportUsingGeocoding
  );

  hasLocatorLibraryLocationsLimitBeenExceeded$ = this.store$.select(
    fromLocatorStore.getHasLocatorLibraryLocationsImportLimitBeenExceeded
  );

  private subscription = new Subscription();

  constructor(
    private featureUIStore$: Store<fromAppFeatureUIStore.State>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      libraryId: number;
      affirmativeButtonText: string;
    },
    private dialogRef: MatDialogRef<LocatorImportLibraryDataDialogComponent>,
    private store$: Store<fromLocatorStore.State>
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.importUsingGeocoding$.subscribe(
        (useGeocoding) => (this.tenantImportUsingGeocoding = useGeocoding)
      )
    );

    this.subscription.add(
      this.hasLocatorLibraryLocationsLimitBeenExceeded$.subscribe(
        (limitExceeded) =>
          (this.hasLocatorLibraryLocationsLimitBeenExceeded = limitExceeded)
      )
    );

    this.store$.dispatch(
      hasLocatorLibraryLocationsLimitBeenExceededAttempt({
        libraryId: this.data.libraryId
      })
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onLocatorImportDataClicked(
    locatorImportLibraryDataForm: LocatorImportLibraryDataFormComponent
  ) {
    if (locatorImportLibraryDataForm.locatorImportLibraryDataForm.valid) {
      this.store$.dispatch(
        locatorFileImportAttempt({
          file: locatorImportLibraryDataForm.selectedFile,
          importUsingGeocoding:
            locatorImportLibraryDataForm.locatorImportLibraryDataForm.controls
              .geocodingSelection.value
        })
      );

      this.dialogRef.close({
        action: DialogResult.Affirmative
      });
    }
  }
}
