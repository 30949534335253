import { createReducer, on } from '@ngrx/store';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { AppFeatures } from '../../models/feature';
import { LocatorShapeDefaults } from '../../models/locator-shape-defaults';

import {
  clearAppFeaturesStateOnLogoff,
  loadAppFeaturesSucceeded
} from '../actions/app-feature-ui.actions';
import { DriveCatchmentSpeeds } from '../../enums/drive-catchment-speeds.enum';
import { TooltipLayouts } from '../../enums/tooltip-layout.enum';
import { NetworkPlanningSettings } from '../../models/network-planning-settings';
import { CatchmentReportingSettings } from '../../models/catchment-reporting-settings';

export interface State {
  spatialModellerFeature: boolean;
  locatorFeature: boolean;
  profilerFeature: boolean;
  initialMapPosition: Point;
  zoom: number;
  locatorShapeDefaults: LocatorShapeDefaults | null;
  networkPlanningDefaults: NetworkPlanningSettings | null;
  catchmentReportingDefaults: CatchmentReportingSettings | null;
  distanceUnit: string;
  removeSystemLayers: string[];
  driveCatchmentSpeed: DriveCatchmentSpeeds;
  hideUsageSection: boolean;
  tooltipLayout: TooltipLayouts;
  gazetteerCountryRestriction: string | null;
  allowInternalUsersUsage: boolean;
}

const initialState: State = {
  spatialModellerFeature: false,
  locatorFeature: false,
  profilerFeature: false,
  initialMapPosition: {
    longitude: -2.89479,
    latitude: 54.093409
  },
  zoom: 5,
  locatorShapeDefaults: null,
  catchmentReportingDefaults: null,
  networkPlanningDefaults: null,
  distanceUnit: 'mile',
  removeSystemLayers: [],
  driveCatchmentSpeed: DriveCatchmentSpeeds.MidDay,
  hideUsageSection: false,
  tooltipLayout: TooltipLayouts.TwoColumns,
  gazetteerCountryRestriction: null,
  allowInternalUsersUsage: false
};

export const appFeatureUIReducer = createReducer(
  initialState,
  on(loadAppFeaturesSucceeded, (state, { appFeatures }) => {
    const initialPosition = getMapposition(appFeatures, initialState);
    return {
      ...state,
      spatialModellerFeature: appFeatures.spatialModellerFeature,
      locatorFeature: appFeatures.locatorFeature,
      profilerFeature: appFeatures.profilerFeature,
      initialMapPosition: initialPosition.mapPosition,
      zoom: initialPosition.zoom,
      locatorShapeDefaults: appFeatures.locator
        ? applyDefaultsIfNeeded(appFeatures.locator)
        : null,
      catchmentReportingDefaults: appFeatures.catchmentReporting,
      networkPlanningDefaults: appFeatures.networkPlanning,
      distanceUnit: appFeatures.distanceUnit,
      removeSystemLayers: appFeatures.removeSystemLayers
        ? appFeatures.removeSystemLayers
        : [],
      driveCatchmentSpeed: appFeatures.driveCatchmentSpeed,
      hideUsageSection: appFeatures.hideUsageSection,
      tooltipLayout: appFeatures.tooltipLayout,
      gazetteerCountryRestriction: appFeatures.gazetteerCountryRestriction,
      allowInternalUsersUsage: appFeatures.allowInternalUsersUsage
    };
  }),
  on(clearAppFeaturesStateOnLogoff, (state) => {
    return {
      ...initialState
    };
  })
);

function getMapposition(appFeatures: AppFeatures, initialState: State) {
  return appFeatures.initialMapPosition == null
    ? { mapPosition: initialState.initialMapPosition, zoom: initialState.zoom }
    : { mapPosition: appFeatures.initialMapPosition, zoom: appFeatures.zoom };
}

function applyDefaultsIfNeeded(locatorShapeDefaults: LocatorShapeDefaults) {
  var newShapeDefaults = JSON.parse(JSON.stringify(locatorShapeDefaults));
  if (newShapeDefaults?.car?.rangeLimit <= 0) {
    newShapeDefaults.car.rangeLimit = 240;
  }
  if (newShapeDefaults?.walk?.rangeLimit <= 0) {
    newShapeDefaults.walk.rangeLimit = 240;
  }
  if (newShapeDefaults?.circle?.rangeLimit <= 0) {
    newShapeDefaults.circle.rangeLimit = 100;
  }
  if (newShapeDefaults?.publicTransport?.rangeLimit <= 0) {
    newShapeDefaults.publicTransport.rangeLimit = 120;
  }
  return newShapeDefaults;
}
