<button
  mat-flat-button
  type="button"
  class="dialog-cancel-button secondary-button"
  mat-dialog-close
  (click)="clicked.emit($event)"
  [disabled]="disabled"
>
  {{ label }}
</button>
