import { LocatorLibraryEffects } from './locator-library.effects';
import { LocatorReportingEffects } from './locator-reporting.effects';
import { LocatorLocationEffects } from './locator-location.effects';
import { SnackbarEffects } from './snack-bar.effects';
import { LocatorShapeEffects } from './locator-shape.effects';
import { LocatorDataGridEffects } from './locator-datagrid.effects';
import { LocatorImportEffects } from './locator-import.effects';
import { LocatorImportStatusEffects } from './locator-import-status.effects';

export const effects = [
  LocatorLibraryEffects,
  LocatorReportingEffects,
  LocatorLocationEffects,
  LocatorShapeEffects,
  SnackbarEffects,
  LocatorDataGridEffects,
  LocatorImportEffects,
  LocatorImportStatusEffects
];
