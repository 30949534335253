import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'src/app/core/services/dialog.service';

import * as fromLocatorStore from 'src/app/locator-store';
import * as fromLocatorUIStore from 'src/app/core/store';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DeleteLocationConfirmationDialogComponent } from './delete-location-confirmation-dialog/delete-location-confirmation-dialog.component';
import { delay } from 'rxjs';

@Component({
  selector: 'atlas-locator-properties',
  templateUrl: './locator-properties.component.html',
  styleUrls: ['./locator-properties.component.less']
})
export class LocatorPropertiesComponent {
  @Input() areMultipleLocationsSelected: boolean | null;

  selectedLocation$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedLocation
  ).pipe(delay(100));

  selectedShapes$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedShapes
  ).pipe(delay(100));

  addingEditingPolygon$ = this.locatorUIStore$.select(
    fromLocatorUIStore.getAddingEditingPolygon
  );

  generatingCatchmentInProgress$ = this.locatorUIStore$.select(
    fromLocatorUIStore.getGeneratingCatchments)

  constructor(
    private locatorStore$: Store<fromLocatorStore.State>,
    private locatorUIStore$: Store<fromLocatorUIStore.State>,
    private dialogService: DialogService
  ) {}

  onDeleteClicked() {
    this.dialogService.show(DeleteLocationConfirmationDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-95vw-width'
    });
  }
}
