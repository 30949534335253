import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store';
import { UsageSummary } from '../../models/usage-summary';
import { clearUsage, getUsageSummarySucceeded } from '../actions/usage.actions';

export interface State {
  usageSummary: UsageSummary | null;
}

const initialState: State = {
  usageSummary: null
};

export const usageReducer = createReducer(
  initialState,

  on(getUsageSummarySucceeded, (state, { usageSummary }) => {
    return {
      ...state,
      usageSummary: usageSummary
    };
  }),
  on(clearUsage, (state) => {
    return {
      ...state,
      usageSummary: null
    };
  })
);
