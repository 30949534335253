import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.less'],
})
export class DialogHeaderComponent {
  @Input() headerText: string;
}
