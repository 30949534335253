import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromLocator from './locator.reducer';
import * as fromLocatorImportLibraryData from './locator-import-library-data.reducer';

export const locatorFeatureKey = 'locator';

export interface State {
  locator: fromLocator.State;
  locatorImportLibraryData: fromLocatorImportLibraryData.State;
}

export const reducers: ActionReducerMap<State> = {
  locator: fromLocator.locatorReducer,
  locatorImportLibraryData:
    fromLocatorImportLibraryData.locatorImportLibraryDataReducer
};

export const getLocatorModuleState =
  createFeatureSelector<State>(locatorFeatureKey);

export const getLocatorState = createSelector(
  getLocatorModuleState,
  (state) => state && state.locator
);

export const getLocatorImportLibraryDataState = createSelector(
  getLocatorModuleState,
  (state) => state && state.locatorImportLibraryData
);
