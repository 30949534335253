import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLocatorStore from 'src/app/locator-store/';
import { PowerBiReportViewerService } from '../../../../services/report-viewer.service';
import { Subscription } from 'rxjs';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogService } from 'src/app/core/services/dialog.service';
import { delay, map } from 'rxjs/operators';
import { PowerBiReportTypes } from 'src/app/locator/types/power-bi-report.types';
import { LocatorReportingDialogComponent } from '../locator-reporting-dialog/locator-reporting-dialog.component';
import { LocatorPowerBiReportingInfo } from 'src/app/locator/models/locator-power-bi-reporting-info';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { locatorLaunchReportingClicked } from 'src/app/locator-store/actions/locator-shape.actions';

@Component({
  selector: 'atlas-locator-reports-panel-buttons',
  templateUrl: './locator-reports-panel-buttons.component.html',
  styleUrls: ['./locator-reports-panel-buttons.component.less']
})
export class LocatorReportsPanelButtonsComponent implements OnInit, OnDestroy {
  pbiReportList: LocatorPowerBiReportingInfo[] = [];
  selectedShapes: LocatorShape[] = [];

  hasReportsForSelectedShapes$ = this.locatorStore$
    .select(fromLocatorStore.getSelectedShapes)
    .pipe(
      delay(100),
      map((selectedShapes) => {
        this.selectedShapes = selectedShapes ? selectedShapes : [];
        if (this.pbiReportList.length === 0) {
          return false;
        }
        return (
          this.selectedShapes.length === 1 ||
          (this.selectedShapes.length > 1 &&
            this.pbiReportList.some(
              (pbiReport) => pbiReport.isMultiShapeReport
            ))
        );
      })
    );

  private selectedLocationData: LocatorLibraryData | null;
  private subscription: Subscription = new Subscription();

  powerBiReportTypes = PowerBiReportTypes;

  constructor(
    private locatorStore$: Store<fromLocatorStore.State>,
    public powerBiReportViewerService: PowerBiReportViewerService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.locatorStore$
        .select(fromLocatorStore.getLocatorPowerBiReports)
        .subscribe((reports) => {
          this.pbiReportList = reports ? reports : [];
        })
    );
    this.subscription.add(
      this.locatorStore$
        .select(fromLocatorStore.getSelectedLocation)
        .subscribe((selectedLocationData) => {
          this.selectedLocationData = selectedLocationData;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onLaunchReporting() {
    this.locatorStore$.dispatch(locatorLaunchReportingClicked());

    this.dialogService.show(LocatorReportingDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        pbiReportList: this.pbiReportList,
        selectedLocationData: this.selectedLocationData,
        selectedShapes: this.selectedShapes
      },
      disableClose: true
    });
  }
}
