// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  redirectSignIn: 'https://insite-dev.app.caci.co.uk/',
  redirectSignOut: 'https://insite-dev.app.caci.co.uk/',
  baseUrl: 'https://insite-dev.app.caci.co.uk/',
  userPoolId: 'eu-west-2_jrfKXzMRv',
  webClientId: '32gcabe80erub7552ssvm92dt6',
  reportingApiBaseUrl: 'https://reporting-dev.app.insite.caci.co.uk/',
  powerBiReportingAppBaseUrl: 'https://reporting-dev.app.insite.caci.co.uk/',
  appVersion: require('../../package.json').version,
  ddAppId: '400260f6-48a7-474f-a87a-9b28709bccd2',
  ddClientToken: 'pub5ce7514e0cc5a4deaf526198d8ceb908',
  ddEnv: 'dev',
  ddService: 'insite-everywhere'
};
