import { createAction, props } from '@ngrx/store';
import { UsageSummary } from '../../models/usage-summary';

export const usageErrorOccured = createAction(
  '[Usage Effect] Error occured in usage effect',
  props<{ errorOn: string; error: string }>()
);
export const clearUsage = createAction('[Usage Component] Clear Usage');

export const getUsageSummaryAttempt = createAction(
  '[Usage Component] Get  Usage Summary Attempt'
);
export const getUsageSummarySucceeded = createAction(
  '[Atlas API] Get  Usage  Summary Succeeded',
  props<{ usageSummary: UsageSummary }>()
);
