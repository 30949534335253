<div class="auth-container">
  <div class="auth-body">
    <form
      class="form-container verification-container"
      (ngSubmit)="onConfirmVerificationCode()"
      [formGroup]="verificationForm"
      autocomplete="off"
    >
      <div class="centered"></div>
      <div class="centered column-direction">
        <span class="auth-title">Verify that it is you</span>
        <span class="first-time-login-description body-large">
          To authorise this login we will require you to verify your account
          using the mobile number you registered with ending
          {{ userInfo$.getValue().challengeParam?.CODE_DELIVERY_DESTINATION }}.
          The code you will received is valid for 3 minutes.
        </span>

        <span
          class="first-time-login-description body-large"
          [hidden]="authService.authServiceError$ | async"
        >
          Please wait for the code we have just sent you. It can take up to 5
          minutes for it to arrive.</span
        >
      </div>
      <div>
        <div class="first-time-login-control">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Verification code</mat-label>
            <input
              matInput
              type="text"
              formControlName="verificationCode"
              placeholder="Verification code"
            />
            <mat-error *ngIf="emptyCode()"> Please enter your code </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="authService.authServiceError$ | async">
        <span class="auth-error-message error-code-message">
          {{ authService.authServiceError$.value }}
          <div>
            <a class="resend-text" (click)="onResendVerificationCode()">
              Press here to resend</a
            >
          </div>
        </span>
      </div>
      <div class="resend-code-message">
        <span [hidden]="authService.authServiceError$ | async"
          >Still haven't recieved a code?
          <a class="resend-text" (click)="onResendVerificationCode()"
            >Press here to resend</a
          >
          or contact support if this problem persists.</span
        >
      </div>
      <div class="verify-new-password-control verify-button">
        <button
          mat-raised-button
          class="auth-btn"
          color="primary"
          type="submit"
          [disabled]="invalidForm()"
        >
          Verify and Login
        </button>
      </div>
      <!-- <div class="back-to-login centered">
        <span>Go back to login?</span>
        <span class="link" (click)="onGoBackToLogin()">Click here</span>
      </div> -->
    </form>
  </div>
  <div class="auth-footer">
    <img src="../../../../assets/logos/CACI-logo 1.svg" class="caci-logo" />
  </div>
</div>
