import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';

import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { LocatorShape } from 'src/app/locator/models/locator-shape';

import { ActionCreatorWithProps } from 'src/app/shared/actions/action-creator-with-props';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';

import {
  ReferenceLibrariesErrorOccurred,
  createLibrarySucceeded,
  deleteLibrarySucceeded,
  generateTempLocatorLibraryDataPointAttempt,
  generateTempLocatorLibraryDataPointErrorOccurred,
  generateTempLocatorLibraryDataPointSucceded,
  getLocatorPowerBiReportingInfoErrorOccurred,
  importReferenceLibraryDataSucceded,
  importReferenceLibraryErrorOccurred,
  libraryErrorOccurred,
  swapLibrarySucceeded,
  updateLibrarySucceeded
} from '../actions/locator-library.actions';
import { deleteLibraryLocationsSucceeded } from '../actions/locator-location.actions';
import {
  createLocatorShapesAttempt,
  createLocatorShapesErrorOccurred,
  createLocatorShapesSucceded,
  deleteLibraryLocationShapeSucceeded,
  generateLocatorLoFiReportErrorOccurred,
  SharePowerBIReportErrorOccurred,
  SharePowerBIReportSucceeded,
  updateLocatorShapesErrorOccurred
} from '../actions/locator-shape.actions';
import { locatorFileImportHeaderValidationFailed } from '../actions/locator-import-validation.actions';
import {
  pollLocatorImportLibraryDataStatusCompleted,
  pollLocatorImportLibraryDataStatusFailed
} from '../actions/locator-import-status.actions';
import { LocatorImportStatus } from 'src/app/locator/models/locator-import-status';

@Injectable()
export class SnackbarEffects {
  constructor(private actions$: Actions, private snackbar: SnackbarService) {}

  snackbarOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          generateTempLocatorLibraryDataPointErrorOccurred,
          getLocatorPowerBiReportingInfoErrorOccurred,
          libraryErrorOccurred,
          generateLocatorLoFiReportErrorOccurred,
          createLocatorShapesErrorOccurred,
          updateLocatorShapesErrorOccurred,
          SharePowerBIReportErrorOccurred,
          importReferenceLibraryErrorOccurred,
          ReferenceLibrariesErrorOccurred
        ),
        tap(({ errorOn, error: message }) => {
          this.snackbar.error(errorOn, message);
        })
      ),
    { dispatch: false }
  );

  locatorGeneratingTempShapeStartedSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [generateTempLocatorLibraryDataPointAttempt],
    'Creating catchment, please wait'
  );

  locatorGeneratingTempShapeCompletedSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [generateTempLocatorLibraryDataPointSucceded],
    'Catchment complete'
  );

  createLibrarySucceededSnackbar$ = this.createEditLibrarySnackbarEffect(
    this.actions$,
    [createLibrarySucceeded],
    'library successfully created.',
    '',
    'prefix'
  );

  updateLibrarySucceededSnackbar$ = this.createEditLibrarySnackbarEffect(
    this.actions$,
    [updateLibrarySucceeded],
    'library successfully updated.',
    '',
    'prefix'
  );

  swapLibrarySucceededSnackbar$ = this.createEditLibrarySnackbarEffect(
    this.actions$,
    [swapLibrarySucceeded],
    'Library switched to ',
    '',
    'suffix'
  );

  deleteLibrarySucceededSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [deleteLibrarySucceeded],
    'Library successfully deleted.'
  );

  deleteLibraryLocationShapeSucceededSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [deleteLibraryLocationShapeSucceeded],
    'Library Location Shape successfully deleted.'
  );

  deleteLibraryLocationSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteLibraryLocationsSucceeded),
        tap(({ libraryDataIds }) => {
          const locationText =
            libraryDataIds.length > 1 ? 'Locations' : 'Location';
          this.snackbar.show(
            this.getSnackbarText(
              `Library ${locationText} successfully deleted.`
            )
          );
        })
      ),
    { dispatch: false }
  );

  sharePowerBiReportAttempt$ = this.createSnackbarEffect(
    this.actions$,
    [SharePowerBIReportSucceeded],
    'Report Link copied to clipboard'
  );

  importReferenceLibraryDataSucceded$ = this.createSnackbarEffect(
    this.actions$,
    [importReferenceLibraryDataSucceded],
    'Data successfully copied.'
  );

  createLocatorShapesAttemptSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createLocatorShapesAttempt),
        filter((shapeAttempt) => shapeAttempt.multipleCatchments),
        tap(() => {
          this.snackbar.show(
            this.getSnackbarText('Creating catchments, please wait.')
          );
        })
      ),
    { dispatch: false }
  );

  createLocatorShapesSuccededSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createLocatorShapesSucceded),
        filter((shapeAttempt) => shapeAttempt.multipleCatchments),
        tap(() => {
          this.snackbar.show(this.getSnackbarText('Catchments complete.'));
        })
      ),
    { dispatch: false }
  );

  // Temporary added until we have a proper UI panel with the import steps
  pollLocatorImportLibraryDataStatusCompleted$ = this.createSnackbarEffect(
    this.actions$,
    [pollLocatorImportLibraryDataStatusCompleted],
    'import locations to library completed'
  );

  private createSnackbarEffect(
    actions$: Actions,
    initiatedActions:
      | ActionCreatorWithProps[]
      | ActionCreatorWithProps<{
          library: LocatorLibrary;
        }>[]
      | ActionCreatorWithProps<{
          id: number;
        }>[]
      | ActionCreatorWithProps<{
          shape: LocatorShape;
        }>[]
      | ActionCreatorWithProps<{
          locationData: LocatorLibraryData;
        }>[]
      | ActionCreatorWithProps<{
          location: Point;
        }>[]
      | ActionCreatorWithProps<{
          shapeId: number;
          LibraryDataId: number;
        }>[]
      | ActionCreatorWithProps<{
          libraryDataIds: number[];
        }>[]
      | ActionCreatorWithProps<{
          locatorImportStatus: LocatorImportStatus;
        }>[],
    actionString: string,
    headerActionString?: string
  ) {
    return createEffect(
      () =>
        actions$.pipe(
          ofType(...initiatedActions),
          tap(() =>
            this.snackbar.show(
              this.getSnackbarText(actionString),
              headerActionString
            )
          )
        ),

      { dispatch: false }
    );
  }

  private createEditLibrarySnackbarEffect(
    actions$: Actions,
    initiatedActions: ActionCreatorWithProps<{
      library: LocatorLibrary;
    }>[],
    actionString: string,
    headerActionString?: string,
    libraryPosition?: string
  ) {
    return createEffect(
      () =>
        actions$.pipe(
          ofType(...initiatedActions),
          tap(({ library }) =>
            this.snackbar.show(
              this.getSnackbarText(actionString, library.name, libraryPosition),
              headerActionString
            )
          )
        ),

      { dispatch: false }
    );
  }

  private getSnackbarText(
    action: string,
    objectName?: string,
    objectPosition?: string
  ) {
    if (objectName) {
      if (objectPosition) {
        switch (objectPosition) {
          case 'prefix':
            return objectName ? `${objectName} ${action}` : `${action}`;

          case 'suffix':
            return objectName ? `${action} ${objectName}` : `${action}`;

          default:
            return objectName ? `${objectName} ${action}` : `${action}`;
        }
      }
    }

    return `${action}`;
  }
}
