import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GazetteerService } from '../../services/atlas-gazetteer.service';
import {
  allowManualDropSet,
  allowManualDropPinClicked,
  searchLocationAttempt,
  searchLocationSucceded,
  selectLocationByNameAttempt,
  selectLocationByCoordinatesAttempt,
  selectLocationSucceded,
  findMyLocationClicked,
  searchTextClearAttempt,
  searchTextCleared,
  mapPinCleared,
  selectLocationFailed,
  searchLocationFailed,
  findMyLocationSucceded,
  reverseLocationSucceded,
  reverseLocationFailed,
  reverseLocationAttempt,
  manualPinDropSucceded,
  manualPinDragSucceded,
  existingPinDragSucceded,
  selectNewLocationForSelectionPin,
  selectNewLocationForSelectionPinSucceeded,
  selectNewLocationForSelectionPinByCoordinates,
  deactivateManualDropSet,
  deactivateManualDropPinClicked
} from '../actions/gazetteer.actions';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { of, forkJoin, EMPTY } from 'rxjs';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import {
  legendLayerTurnedOff,
  manualPinClicked,
  supplyPointDeselectAttempt,
  supplyPointDeselected,
  supplyPointSelected,
  unsavedSupplyPointDeselected
} from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as fromScenarioStore from 'src/app/spatial-modeller-store';

import { SearchResultGroup } from '../../models/search-result-group';
import { SearchResult } from '../../models/search-result';
import {
  DropPinAction,
  executeSearchResultAction
} from '../../helpers/search-helper';

import { deleteSupplyPointSucceeded } from 'src/app/spatial-modeller-store/actions/supply-point.actions';
import {
  getIsSupplyPoint,
  getIsSupplyPointAndPendingUnsaved,
  getPendingUnsavedLocationSupplyPoint,
  getPendingUnsavedSupplyPoint
} from 'src/app/spatial-modeller-store';
import { Point } from '../../models/point.model';
import { revertToExistingSelectedLocation } from 'src/app/core/store/actions/spatial-modeller-ui.actions';
import * as fromUIStore from 'src/app/core/store';
import { getAppSelectedFeature, isLocationChanging } from 'src/app/core/store';
import { locatorLocationSelected } from 'src/app/locator-store/actions/locator-location.actions';
import { getLocatorLibraryId } from 'src/app/locator-store';

@Injectable()
export class GazetteerEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private UIStore$: Store<fromUIStore.State>,
    private gazetteerService: GazetteerService,
    private mapService: MapService,
    private pinDropAndSelectionService: PinDropAndSelectionService
  ) {}

  searchLocationAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchLocationAttempt),
      debounceTime(250),
      withLatestFrom(
        this.store$.select(fromScenarioStore.getScenarioId),
        this.store$.select(getIsSupplyPointAndPendingUnsaved),
        this.UIStore$.select(isLocationChanging),
        this.UIStore$.select(getAppSelectedFeature),
        this.UIStore$.select(getLocatorLibraryId),
        this.UIStore$.select(fromUIStore.getGazetteerCountryRestriction)
      ),
      distinctUntilChanged(),
      switchMap(
        ([
          { searchedText },
          scenarioId,
          isSupplyPointAndPendingUnsaved,
          isLocationChanging,
          selectedAppfeature,
          locatorLibraryId,
          gazetterCountryRestriction
        ]) => {
          let featureGroupId = this.getFeatureGroupId(
            selectedAppfeature,
            scenarioId,
            locatorLibraryId
          );

          return this.handleSearchLocation(
            searchedText,
            featureGroupId,
            isSupplyPointAndPendingUnsaved,
            isLocationChanging,
            selectedAppfeature,
            gazetterCountryRestriction
          );
        }
      )
    )
  );

  selectLocationByNameAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectLocationByNameAttempt),
      withLatestFrom(
        this.UIStore$.select(fromUIStore.getGazetteerCountryRestriction)
      ),
      switchMap(([{ location }, gazetterCountryRestrictions]) =>
        this.gazetteerService
          .getLatLongLocation(
            location && location.googleSearchText == ''
              ? location.description
              : location.googleSearchText,
            gazetterCountryRestrictions
          )
          .pipe(
            map((latlon) => {
              executeSearchResultAction(
                location.action,
                location,
                latlon,
                this.mapService,
                this.pinDropAndSelectionService
              );

              return selectLocationSucceded({ location: latlon });
            }),
            catchError((error) =>
              of(
                selectLocationFailed({
                  errorOn: 'Error adding location to map: ',
                  error
                })
              )
            )
          )
      )
    )
  );

  selectLocationByCoordinatesAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectLocationByCoordinatesAttempt),
      switchMap(({ location }) => {
        executeSearchResultAction(
          location.action,
          location,
          location,
          this.mapService,
          this.pinDropAndSelectionService
        );

        if (
          location.action !== 'SpatialModelerCustom' &&
          location.action !== 'LocatorCustom'
        ) {
          return of(selectLocationSucceded({ location }));
        } else {
          // executeSearchResultAction (action === 'SpatialModellerCustom')
          // already dispatches supplyPointClicked
          return EMPTY;
        }
      }),
      catchError((error) =>
        of(
          selectLocationFailed({
            errorOn: 'Error trying to find my location: ',
            error
          })
        )
      )
    )
  );

  selectNewLocationForExistingPin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectNewLocationForSelectionPin),
      withLatestFrom(
        this.UIStore$.select(fromUIStore.getGazetteerCountryRestriction)
      ),
      switchMap(([{ location }, gazetterCountryRestrictions]) =>
        this.gazetteerService
          .getLatLongLocation(
            location && location.googleSearchText == ''
              ? location.description
              : location.googleSearchText,
            gazetterCountryRestrictions
          )
          .pipe(
            map((latlon) => {
              executeSearchResultAction(
                location.action,
                location,
                latlon,
                this.mapService,
                this.pinDropAndSelectionService
              );

              return selectNewLocationForSelectionPinSucceeded({
                location: latlon
              });
            }),
            catchError((error) =>
              of(
                selectLocationFailed({
                  errorOn: 'Error changing location to map: ',
                  error
                })
              )
            )
          )
      )
    )
  );

  selectNewLocationForSelectionPinByCoordinates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectNewLocationForSelectionPinByCoordinates),
      switchMap(({ location }) => {
        executeSearchResultAction(
          location.action,
          location,
          location,
          this.mapService,
          this.pinDropAndSelectionService
        );

        return of(selectNewLocationForSelectionPinSucceeded({ location }));
      }),
      catchError((error) =>
        of(
          selectLocationFailed({
            errorOn: 'Error changing location to map: ',
            error
          })
        )
      )
    )
  );

  allowManualPinDropAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(allowManualDropPinClicked),
      withLatestFrom(
        this.store$.select(getPendingUnsavedSupplyPoint),
        this.store$.select(getIsSupplyPoint)
      ),
      switchMap(([_, pendingUnsavedSupplyPoint, isSupplyPoint]) => {
        return this.handleAllowManualDropSet(
          pendingUnsavedSupplyPoint,
          isSupplyPoint
        );
      })
    )
  );

  deactivateManualDropAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deactivateManualDropPinClicked),
      switchMap(() => {
        return this.deactivateManualDropPinSet();
      })
    )
  );

  findMyLocationAttemp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findMyLocationClicked),
      withLatestFrom(
        this.store$.select(getPendingUnsavedSupplyPoint),
        this.store$.select(getIsSupplyPoint)
      ),
      switchMap(([{ location }, pendingUnsavedSupplyPoint, isSupplyPoint]) => {
        return this.handleFindMyLocationClicked(
          location,
          pendingUnsavedSupplyPoint,
          isSupplyPoint
        );
      }),
      catchError((error) =>
        of(
          selectLocationFailed({
            errorOn: 'Error trying to find my location: ',
            error
          })
        )
      )
    )
  );

  retrieveLocationSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        findMyLocationSucceded,
        manualPinDropSucceded,
        manualPinDragSucceded,
        manualPinClicked
      ),
      switchMap(({ location }) => {
        this.pinDropAndSelectionService.removeSelectionLayer();
        return of(reverseLocationAttempt({ location, requestFrom: null }));
      })
    )
  );

  reverseLookUpAfterPinDrag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(existingPinDragSucceded),
      switchMap(({ location }) => {
        return of(reverseLocationAttempt({ location, requestFrom: null }));
      })
    )
  );

  reverseLocationAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reverseLocationAttempt),
      switchMap(({ location, requestFrom }) =>
        this.gazetteerService.getAddressFromLatLong(location).pipe(
          map((address: string) =>
            reverseLocationSucceded({ address, location, requestFrom })
          ),
          catchError((error) =>
            of(
              reverseLocationFailed({
                errorOn: 'Error getting the address from Lat/long: ',
                error
              })
            )
          )
        )
      )
    )
  );

  clearSearchTextAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchTextClearAttempt),
      withLatestFrom(
        this.store$.select(getPendingUnsavedSupplyPoint),
        this.store$.select(getIsSupplyPoint)
      ),
      switchMap(([_, pendingUnsavedSupplyPoint, isSupplyPoint]) => {
        return this.handleClearSearchText(
          pendingUnsavedSupplyPoint,
          isSupplyPoint
        );
      })
    )
  );

  supplyPointSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supplyPointSelected, revertToExistingSelectedLocation),
      switchMap(({ location }) => {
        return of(reverseLocationAttempt({ location, requestFrom: null }));
      })
    )
  );

  locatorLocationSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorLocationSelected),
      switchMap(({ location }) => {
        return of(
          reverseLocationAttempt({
            location,
            requestFrom: 'LocatorLocationList'
          })
        );
      })
    )
  );

  supplyPointDeselectAttemp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supplyPointDeselectAttempt),
      withLatestFrom(
        this.store$.select(getPendingUnsavedSupplyPoint),
        this.store$.select(getPendingUnsavedLocationSupplyPoint),
        this.store$.select(getIsSupplyPoint)
      ),
      switchMap(
        ([
          _,
          pendingUnsavedSupplyPoint,
          pendingUnsavedLocationSupplyPoint,
          isSupplyPoint
        ]) => {
          return this.handleDeselectSupplyPoint(
            pendingUnsavedSupplyPoint,
            pendingUnsavedLocationSupplyPoint,
            isSupplyPoint
          );
        }
      )
    )
  );

  supplyPointDeselected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        supplyPointDeselected,
        deleteSupplyPointSucceeded,
        legendLayerTurnedOff,
        searchTextCleared
      ),
      switchMap(() => {
        return of(mapPinCleared());
      })
    )
  );

  private buildSearchResultsGroup(
    googleResults: SearchResult[],
    customResults: SearchResult[],
    feature: string
  ): SearchResultGroup[] {
    let groupedResutlts: SearchResultGroup[] = [];

    var insiteGroupName = this.getGroupNameForFeature(feature);

    if (googleResults.length > 0) {
      groupedResutlts.push({
        groupIcon: 'assets/logos/Google__G__Logo.svg',
        groupName: 'Google',
        results: googleResults?.slice(0, 10)
      });
    }

    if (customResults.length > 0) {
      groupedResutlts.push({
        groupIcon: 'assets/logos/Insite-pin-logo.svg',
        groupName: insiteGroupName,
        results: customResults
      });
    }

    return groupedResutlts;
  }

  private getGroupNameForFeature(selectedAppfeature: string) {
    if (selectedAppfeature == 'SpatialModeler') {
      return 'This Scenario';
    }

    if (selectedAppfeature == 'Locator' || selectedAppfeature == 'Profiler') {
      return 'This Library';
    }

    return '';
  }

  private handleSearchLocation(
    searchedText: string,
    featureGroupId: number,
    isSupplyPointAndPendingUnsaved: boolean,
    isLocationChanging: boolean,
    selectedFeature: string,
    countryCode: string | null
  ) {
    return isSupplyPointAndPendingUnsaved && !isLocationChanging
      ? this.dispatchUnsavedSupplyPointDeselected()
      : this.dispatchSearchLocation(
          searchedText,
          featureGroupId,
          selectedFeature,
          countryCode
        );
  }

  private handleDeselectSupplyPoint(
    pendingUnsavedSupplyPoint: boolean,
    pendingUnsavedLocationSupplyPoint: boolean,
    isSupplyPoint: boolean
  ) {
    return (pendingUnsavedSupplyPoint || pendingUnsavedLocationSupplyPoint) &&
      isSupplyPoint
      ? this.dispatchUnsavedSupplyPointDeselected()
      : of(supplyPointDeselected());
  }

  private handleClearSearchText(
    pendingUnsavedSupplyPoint: boolean,
    isSupplyPoint: boolean
  ) {
    return pendingUnsavedSupplyPoint && isSupplyPoint
      ? this.dispatchUnsavedSupplyPointDeselected()
      : of(searchTextCleared());
  }

  private handleFindMyLocationClicked(
    location: Point,
    pendingUnsavedSupplyPoint: boolean,
    isSupplyPoint: boolean
  ) {
    return pendingUnsavedSupplyPoint && isSupplyPoint
      ? this.dispatchUnsavedSupplyPointDeselected()
      : this.dispatchFindMyLocationSucceeded(location);
  }

  private handleAllowManualDropSet(
    pendingUnsavedSupplyPoint: boolean,
    isSupplyPoint: boolean
  ) {
    this.pinDropAndSelectionService.setAllowManualPinDrop(
      !(pendingUnsavedSupplyPoint && isSupplyPoint)
    );

    return pendingUnsavedSupplyPoint && isSupplyPoint
      ? this.dispatchUnsavedSupplyPointDeselected()
      : of(allowManualDropSet());
  }

  private deactivateManualDropPinSet() {
    this.pinDropAndSelectionService.setAllowManualPinDrop(false);
    return of(deactivateManualDropSet());
  }

  private dispatchUnsavedSupplyPointDeselected() {
    return of(
      unsavedSupplyPointDeselected({
        nextSelectedSupplyPointInfo: {
          newSelectedSupplyPointId: null,
          newSelectedLocation: null
        }
      })
    );
  }

  private dispatchSearchLocation(
    searchedText: string,
    featureGroupId: number,
    feature: string,
    countryCode: string | null
  ) {
    let googleResults$ = this.gazetteerService.getLocationPredictions(
      searchedText,
      countryCode
    );

    let customSearchResults$ = this.gazetteerService.getCustomSearchResults(
      featureGroupId,
      feature,
      searchedText
    );

    if (searchedText?.trim().length === 0) {
      return of(searchLocationSucceded({ foundLocations: [] }));
    }

    return forkJoin([googleResults$, customSearchResults$]).pipe(
      map(([googleResults, customResults]) => {
        let groupedResutlts: SearchResultGroup[] = this.buildSearchResultsGroup(
          googleResults,
          customResults,
          feature
        );

        return searchLocationSucceded({ foundLocations: groupedResutlts });
      }),
      catchError((error) => {
        return of(
          searchLocationFailed({
            errorOn: 'Error searching suggestions for location: ',
            error
          })
        );
      })
    );
  }

  private dispatchFindMyLocationSucceeded(location: Point) {
    DropPinAction(this.mapService, this.pinDropAndSelectionService, location);
    return of(findMyLocationSucceded({ location }));
  }

  private getFeatureGroupId(
    selectedAppfeature: string,
    scenarioId: number,
    locatorLibraryId: number
  ) {
    if (selectedAppfeature == 'SpatialModeler') {
      return scenarioId;
    }

    if (selectedAppfeature == 'Locator') {
      return locatorLibraryId;
    }

    return 0;
  }
}
