export type GeocodeLevel =
  | 'Unmatched'
  | 'Unknown'
  | 'Country'
  | 'State'
  | 'County'
  | 'City'
  | 'District'
  | 'Street'
  | 'Postal Code'
  | 'Intersection'
  | 'Place'
  | 'House Number'
  | 'Floor'
  | 'Multiple Results'
  | 'Coordinates'
  | 'locality'
  | 'postalCodePoint'
  | 'administrativeArea'
  | 'addressBlock';

export const weakGeocodeLevels: GeocodeLevel[] = [
  'Unmatched',
  'Unknown',
  'Country',
  'State',
  'County',
  'City',
  'District',
  'Multiple Results'
];

export const mediumGeocodeLevels: GeocodeLevel[] = [
  'Street',
  'Postal Code',
  'Intersection',
  'locality',
  'postalCodePoint',
  'administrativeArea',
  'addressBlock'
];

export const strongGeocodeLevels: GeocodeLevel[] = [
  'Place',
  'House Number',
  'Floor',
  'Coordinates'
];

const getGeocodeLevelNumber = (geocodeLevel: GeocodeLevel) =>
  strongGeocodeLevels.includes(geocodeLevel)
    ? 1
    : mediumGeocodeLevels.includes(geocodeLevel)
    ? 2
    : weakGeocodeLevels.includes(geocodeLevel)
    ? 3
    : 0;

export const geocodeLevelType: Record<
  GeocodeLevel,
  (geocodeLevel: GeocodeLevel) => number
> = Object.fromEntries(
  [
    'Unmatched',
    'Unknown',
    'Country',
    'State',
    'County',
    'City',
    'District',
    'Street',
    'Postal Code',
    'Intersection',
    'Place',
    'House Number',
    'Floor',
    'Multiple Results',
    'Coordinates',
    'locality',
    'postalCodePoint',
    'administrativeArea',
    'addressBlock'
  ].map((level) => [level, getGeocodeLevelNumber])
) as Record<GeocodeLevel, (geocodeLevel: GeocodeLevel) => number>;
