import { hideLocatorEditPolygonSystemLayer } from 'src/app/shared/atlas-mapping/helpers/system-layers-helper';
import {
  locatorDataShapesLayerIdentifier,
  locatorDataShapeSpatialFunctionLayerIdentifier
} from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { environment } from 'src/environments/environment';
import { LocatorShape } from '../models/locator-shape';
import { LocatorShapeTypes } from '../types/locator-shape.types';
import {
  CircleShapeDefaults,
  DriveTimeShapeDefaults
} from 'src/app/core/models/locator-shape-defaults';

export function showLocatorShapeMapLayer(
  libraryId: number,
  locationDataId: number,
  shapeId: number,
  mapService: MapService
) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      locatorDataShapesLayerIdentifier
    )
  ) {
    if (mapService.getLayer(locatorDataShapesLayerIdentifier)) {
      mapService.updateLayer(locatorDataShapesLayerIdentifier, {
        visible: true,
        data: `${environment.baseUrl}api/mapping/locator/libraries/${libraryId}/library-data/${locationDataId}/shapes/${shapeId}/boundaries`
      });
    }
  }
}

export function showShapesFromLibraryDataOnMap(
  libraryId: number,
  libraryDataId: number,
  mapService: MapService
) {
  if (mapService.getLayer(locatorDataShapesLayerIdentifier)) {
    mapService.updateLayer(locatorDataShapesLayerIdentifier, {
      visible: true,
      data: `${environment.baseUrl}api/mapping/locator/libraries/${libraryId}/library-data/${libraryDataId}/shapes/boundaries`
    });
  }
}

export function showLocatorShapeBuildingBlockSelectionSystemLayer(
  libraryId: number,
  shapeId: number,
  mapService: MapService
) {
  var usersVisiblePreference = mapService.getLayerVisiblityFromStorage(
    locatorDataShapeSpatialFunctionLayerIdentifier
  );

  var layer = mapService.getLayer(
    locatorDataShapeSpatialFunctionLayerIdentifier
  );

  if (layer) {
    mapService.updateLayer(locatorDataShapeSpatialFunctionLayerIdentifier, {
      visible: usersVisiblePreference,
      data: `${environment.baseUrl}api/mapping/locator/libraries/${libraryId}/shapes/${shapeId}/building-block/within/locations`
    });
  }
}

export function showSelectedLocatorDataShapeAfterAddEdit(
  mapService: MapService,
  libraryId: number,
  shape: LocatorShape
) {
  showLocatorShapeMapLayer(
    libraryId,
    shape.libraryDataId,
    shape.id,
    mapService
  );
  showLocatorShapeBuildingBlockSelectionSystemLayer(
    libraryId,
    shape.id,
    mapService
  );
  hideLocatorEditPolygonSystemLayer(mapService);
}

export function isShapeDuplicate(
  sizeValues: number[],
  shapes: LocatorShape[] | null,
  shapeType: LocatorShapeTypes,
  driveCatchmentSpeed: string
): boolean {
  let isDuplicate = false;

  if (shapes === undefined) return isDuplicate;

  const sameShapeType = shapes!.filter((s) => s.type === shapeType);
  sameShapeType.forEach((shape) => {
    const circleConfig = shape.circleConfiguration
      ? JSON.parse(shape.circleConfiguration)
      : null;
    const circleRadius = circleConfig?.Radius;

    const driveTimeConfig = shape.drivetimeConfiguration
      ? JSON.parse(shape.drivetimeConfiguration)
      : null;
    const driveTimeInSeconds = driveTimeConfig?.TimeInSeconds;
    const driveTimeInMinutes = driveTimeInSeconds / 60;
    const shapeDriveCatchmentSpeed = driveTimeConfig?.DriveCatchmentSpeed;
    if (
      sizeValues.includes(circleRadius) ||
      (sizeValues.includes(driveTimeInMinutes) &&
        (shapeType === LocatorShapeTypes.Walk ||
          shapeDriveCatchmentSpeed === driveCatchmentSpeed))
    ) {
      isDuplicate = true;
    }
  });
  return isDuplicate;
}

export function isShapeSizeExceeded(
  shapeCircleConfig: CircleShapeDefaults,
  shapeCarDriveTimeConfig: DriveTimeShapeDefaults,
  shapeWalkDriviTimeConfig: DriveTimeShapeDefaults,
  shapePublicTransportDriveTimeConfig: DriveTimeShapeDefaults,
  shapeType: LocatorShapeTypes,
  sizeValues: number[]
): boolean {
  if (shapeType === LocatorShapeTypes.Car) {
    return sizeValues.some((s) => s > shapeCarDriveTimeConfig.rangeLimit);
  } else if (shapeType === LocatorShapeTypes.Walk) {
    return sizeValues.some((s) => s > shapeWalkDriviTimeConfig.rangeLimit);
  } else if (shapeType === LocatorShapeTypes.Circle) {
    return sizeValues.some((s) => s > shapeCircleConfig.rangeLimit);
  } else if (shapeType === LocatorShapeTypes.PublicTransport) {
    return sizeValues.some(
      (s) => s > shapePublicTransportDriveTimeConfig.rangeLimit
    );
  }
  return false;
}

export function getShapeTypeName(type: LocatorShapeTypes): string {
  const shapeTypes = {
    1: 'Drive Catchment',
    2: 'Walk Catchment',
    3: 'Circle Catchment',
    4: 'Polygon Catchment',
    5: 'Public Transport Catchment',
    6: 'Polygon Catchment Locked'
  };
  return shapeTypes[type];
}

export function getShapeRangeLimit(
  shapeCircleConfig: CircleShapeDefaults,
  shapeCarDriveTimeConfig: DriveTimeShapeDefaults,
  shapeWalkDriviTimeConfig: DriveTimeShapeDefaults,
  shapePublicTransportDriveTimeConfig: DriveTimeShapeDefaults,
  shapeType: LocatorShapeTypes
): number {
  const shapeTypes = {
    1: shapeCarDriveTimeConfig.rangeLimit,
    2: shapeWalkDriviTimeConfig.rangeLimit,
    3: shapeCircleConfig.rangeLimit,
    4: 0,
    5: shapePublicTransportDriveTimeConfig.rangeLimit,
    6: 0
  };
  return shapeTypes[shapeType];
}

export function isShapeDisabled(shapeType: any) {
  return (
    shapeType === null ||
    shapeType?.isDisabled === true ||
    shapeType?.isDisabled === undefined
  );
}
