import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';

import { AuthService } from 'src/app/auth/services/auth.service';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import * as fromUIStore from 'src/app/core/store';
import { MapService } from '../../atlas-mapping/services/map.service';
import {
  allowManualDropPinClicked,
  searchLocationAttempt,
  selectLocationByCoordinatesAttempt,
  selectLocationByNameAttempt,
  selectNewLocationForSelectionPin,
  selectNewLocationForSelectionPinByCoordinates
} from '../store/actions/gazetteer.actions';
import { SearchResultGroup } from '../models/search-result-group';
import { LayoutService } from '../../atlas-layout/services/layout.service';
import * as fromProfilerStore from 'src/app/profiler-store';
import { getProfilerLibraryId } from 'src/app/profiler-store';

@Component({
  selector: 'atlas-gazetteer',
  templateUrl: './atlas-gazetteer.component.html',
  styleUrls: ['./atlas-gazetteer.component.less'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'gazetteer-autocomplete-overlay-pane'
      }
    }
  ]
})
export class GazetteerComponent implements OnInit, OnDestroy {
  destroyedSubject = new Subject<void>();
  gazetteerControl = new UntypedFormControl();
  foundLocations$: Observable<SearchResultGroup[]> =
    this.gazetteerStore$.select(fromGazetteerStore.getFoundLocations);
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  userInfo$ = this.authService.userInfo$;
  searchText$ = this.gazetteerStore$.select(fromGazetteerStore.getSearchText);
  libraryId$ = this.profilerStore$.select(getProfilerLibraryId);
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );
  private isLocationChanging: boolean;

  constructor(
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    public mapService: MapService,
    private authService: AuthService,
    public layoutService: LayoutService,
    private profilerStore$: Store<fromProfilerStore.State>
  ) {}

  ngOnInit(): void {
    combineLatest([this.libraryId$, this.isProfilerFeatureSelected$])
      .pipe()
      .subscribe(([libraryId, isProfilerFeatureSelected]) => {
        this.gazetteerControl[
          libraryId === 0 && isProfilerFeatureSelected ? 'disable' : 'enable'
        ]();
      });

    this.gazetteerControl.valueChanges
      .pipe(
        map((searchText) => {
          if (searchText?.description !== undefined) {
            searchText = searchText.description;
          }

          this.gazetteerStore$.dispatch(
            searchLocationAttempt({ searchedText: searchText })
          );
        }),
        takeUntil(this.destroyedSubject)
      )
      .subscribe();

    this.UIStore$.select(fromUIStore.isLocationChanging)
      .pipe(
        map((locationChanging) => {
          this.isLocationChanging = locationChanging;
        }),
        takeUntil(this.destroyedSubject)
      )
      .subscribe();
  }

  onFindLocation(evt: any) {
    let locationResult = evt.option.value;

    this.isLocationChanging
      ? this.dispatchNewLocationForExistingPinActions(locationResult)
      : this.dispatchNewLocationPinActions(locationResult);

    // Search input is regaining the focus after forcing to blur it due to the material autocomplete component behaviour.
    // It's happening in Dev env but not in the developer local machines.
    // This is a Workaround to achieve the right behaviour in all environments
    setTimeout(() => this.searchInput.nativeElement.blur(), 100);
  }

  private dispatchNewLocationPinActions(locationResult: any) {
    if (locationResult.latitude === 0 && locationResult.longitude === 0) {
      this.gazetteerStore$.dispatch(
        selectLocationByNameAttempt({
          location: locationResult
        })
      );
    } else {
      this.gazetteerStore$.dispatch(
        selectLocationByCoordinatesAttempt({
          location: locationResult
        })
      );
    }
  }

  private dispatchNewLocationForExistingPinActions(locationResult: any) {
    let newLocationResult = { ...locationResult };
    newLocationResult.action = 'ChangeSelectedPinLocation';
    if (newLocationResult.latitude === 0 && newLocationResult.longitude === 0) {
      this.gazetteerStore$.dispatch(
        selectNewLocationForSelectionPin({
          location: newLocationResult
        })
      );
    } else {
      this.gazetteerStore$.dispatch(
        selectNewLocationForSelectionPinByCoordinates({
          location: newLocationResult
        })
      );
    }
  }

  onManuallyAddPinToMapClick() {
    this.gazetteerStore$.dispatch(allowManualDropPinClicked());
  }

  ngOnDestroy() {
    this.destroyedSubject.next();
  }
}
