import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';

import { AtlasDxGridStateService } from 'src/app/shared/dev-express/services/atlas-dx-grid-state.service';
import { AtlasDxColumnFormatService } from 'src/app/shared/dev-express/services/atlas-dx-column-format.service';
import { ColumnWidth } from 'src/app/shared/dev-express/grid-column-width.constants';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'atlas-delta-supply-points-list',
  templateUrl: './delta-supply-points-list.component.html'
})
export class DeltaSupplyPointsListComponent {
  private initialUse: boolean;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  @Output()
  deltaLocationClickSelected = new EventEmitter<string[]>();

  @Input()
  selectedSupplyIds: string[] = [];

  @Input()
  dataSource: any;

  @Input()
  template: TemplateRef<any>;

  constructor(
    public atlasFormat: AtlasDxColumnFormatService,
    public atlasGridState: AtlasDxGridStateService
  ) {}

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.push({
      location: 'before',
      template: 'parentTemplate'
    });
  }

  loadState = () => {
    var state = this.atlasGridState.loadState(
      'delta-supply-points-grid-storage'
    );

    this.initialUse = state.columns == undefined;

    if (this.initialUse) {
      this.grid.visible = false;
    }

    return this.atlasGridState.loadState('delta-supply-points-grid-storage');
  };

  saveState = (state: any) => {
    if (this.initialUse) {
      this.initialSave(state);
    } else {
      this.atlasGridState.saveState('delta-supply-points-grid-storage', state);
    }
  };

  private initialSave(state: any) {
    state = this.configureColumnsforInitialUse(state);
    this.atlasGridState.saveState('delta-supply-points-grid-storage', state);
    this.grid.columns = state.columns;
    this.grid.visible = true;
  }

  // very first use we dont know all the columns but we do know which 4 we want visible we need to configre this
  private configureColumnsforInitialUse(state: any) {
    var defaultColumns = [
      {
        visibleIndex: 0,
        dataField: 'supplyKey',
        name: 'supplyKey',
        dataType: 'string',
        visible: true
      },
      {
        visibleIndex: 1,
        dataField: 'name',
        name: 'name',
        dataType: 'string',
        visible: true
      },

      {
        visibleIndex: 2,
        dataField: 'fascia',
        name: 'fascia',
        dataType: 'string',
        visible: true
      },
      {
        visibleIndex: 3,
        dataField: 'status',
        name: 'status',
        dataType: 'string',
        visible: true
      }
    ];

    let vIndex = 4;
    state.columns?.forEach((element: any) => {
      var match = defaultColumns.find((c) => c.dataField == element.dataField);

      if (match == undefined) {
        let column = element;
        column.visibleIndex = vIndex;
        column.visible = false;
        defaultColumns.push(column);
        vIndex = vIndex + 1;
      }
    });

    state.columns = defaultColumns;
    this.initialUse = false;
    return state;
  }
}
