<div class="atlas-dialog-container">
  <atlas-dialog-header [headerText]="headerText"></atlas-dialog-header>

  <mat-dialog-content>
    <ng-content select="[formContent]"></ng-content>
  </mat-dialog-content>

  <atlas-dialog-footer>
    <atlas-dialog-validation-messages
      ><ng-content select="[formValidation]"></ng-content
    ></atlas-dialog-validation-messages>
    <div class="dialog-buttons-container">
      <atlas-dialog-cancel-button
        (clicked)="buttonClicked.emit()"
        [label]="buttonText"
        ></atlas-dialog-cancel-button>
    </div>
  </atlas-dialog-footer>
</div>
