import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, delay, map } from 'rxjs';
import * as fromUIStore from 'src/app/core/store';
import * as fromProfilerStore from 'src/app/profiler-store';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DefineNewLocationDialogComponent } from '../atlas-define-new-location-dialog/atlas-define-new-location-dialog.component';

@Component({
  selector: 'atlas-define-new-location',
  templateUrl: './atlas-define-new-location.component.html',
  styleUrls: ['../less/atlas-gazetteer-button.less']
})
export class DefineNewLocationComponent {
  isLocationChanging$ = this.UIStore$.select(fromUIStore.isLocationChanging);

  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );

  isNetworkPlanningReadonlySystem$ = this.store$.select(
    fromUIStore.getNetworkPlanningReadonlySystem
  );

  isAddNewLocationDisabled$ = combineLatest([
    this.isLocationChanging$,
    this.isProfilerFeatureSelected$,
    this.isNetworkPlanningReadonlySystem$
  ]).pipe(
    delay(100),
    map(
      ([
        isLocationChanging,
        isProfilerFeatureSelected,
        isNetworkPlanningReadonlySystem
      ]) => {
        return (
          isLocationChanging ||
          isProfilerFeatureSelected ||
          isNetworkPlanningReadonlySystem
        );
      }
    )
  );

  constructor(
    private dialogService: DialogService,
    private UIStore$: Store<fromUIStore.State>,
    private store$: Store<fromProfilerStore.State>
  ) {}

  onOpenDefineNewLocationDialogClick() {
    this.dialogService.show(DefineNewLocationDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: '',
        mode: DialogMode.Add,
        headerPrefix: 'Define a New Location',
        affirmativeButtonText: 'Confirm'
      },
      disableClose: true
    });
  }
}
