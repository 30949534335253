import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DialogMode } from '../../enums/dialog-mode.enum';

@Component({
  selector: 'atlas-add-edit-dialog',
  templateUrl: './atlas-add-edit-dialog.component.html',
  styleUrls: ['./atlas-add-edit-dialog.component.less']
})
export class AtlasAddEditDialogComponent implements OnInit {
  @Input() entityName: string;
  @Input() mode: DialogMode;
  @Input() headerPrefix: string;
  @Input() closeOnSave = true;
  @Input() useHeaderPrefix = true;
  @Input() saveButtonText = 'Save and close';
  @Input() saveDisabled = false;

  @Output() saveClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();

  headerText: string;

  ngOnInit(): void {
    this.headerText = this.useHeaderPrefix
      ? this.headerPrefix
        ? `${this.headerPrefix} ${this.entityName}`
        : `${this.mode} ${this.entityName}`
      : `${this.entityName}`;
  }
}
