import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as fromAppFeatureUIStore from 'src/app/core/store';
import * as fromLocatorStore from 'src/app/locator-store';

import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { Store } from '@ngrx/store';
import { LocatorImportLibraryDataFormComponent } from '../locator-import-library-data-form/locator-import-library-data-form';
import {
  hasLocatorLibraryLocationsLimitBeenExceededAttempt,
  locatorFileImportAttempt
} from 'src/app/locator-store/actions/locator-import.actions';
import { LocatorStatusProgressStep } from 'src/app/locator/models/locator-status-progress-step';
import { LocatorUiDialogSteps } from 'src/app/locator/helpers/locator-status-helper';
import { LocatorImportStatusError } from 'src/app/locator/models/locator-import-status-error';

@Component({
  selector: 'atlas-locator-import-library-data-dialog',
  templateUrl: './locator-import-library-data-dialog.component.html',
  styleUrls: ['./locator-import-library-data-dialog.component.less']
})
export class LocatorImportLibraryDataDialogComponent implements OnInit {
  dialogMode: DialogMode;
  userLibraries: string[] = [];
  hasLocatorLibraryLocationsLimitBeenExceeded: boolean;

  tenantImportUsingGeocoding: boolean;
  importUsingGeocoding$ = this.featureUIStore$.select(
    fromAppFeatureUIStore.getCatchmentReportingImportUsingGeocoding
  );

  hasLocatorLibraryLocationsLimitBeenExceeded$ = this.locatorStore$.select(
    fromLocatorStore.getHasLocatorLibraryLocationsImportLimitBeenExceeded
  );

  importProcessStepState: LocatorStatusProgressStep[] = [
    {
      name: 'select_csv_file',
      state: 'NotStarted'
    } as LocatorStatusProgressStep,
    {
      name: 'processing_data',
      state: 'NotStarted'
    } as LocatorStatusProgressStep
  ];
  locatorImportProcessStepState$ = this.locatorStore$.select(
    fromLocatorStore.getLocatorImportDataProgressSteps
  );
  locatorImportProcessStatus$ = this.locatorStore$.select(
    fromLocatorStore.getLocatorImportDataStatus
  );
  locatorImportStatusError: LocatorImportStatusError = {
    status: 'LocatorImportDialogOpened',
    errors: []
  };

  screenToShow: LocatorUiDialogSteps | 'summary' = 'select_csv_file';
  affirmativeButtonText: string;
  affirmativeButtonDisabled: boolean = false;
  currentStage: number = 1;
  totalRecords: number = 0;
  processedRecords: number = 0;
  importedRecords: number = 0;

  private subscription = new Subscription();

  constructor(
    private featureUIStore$: Store<fromAppFeatureUIStore.State>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      libraryId: number;
      affirmativeButtonText: string;
    },
    private locatorStore$: Store<fromLocatorStore.State>
  ) {
    this.affirmativeButtonText = data.affirmativeButtonText;
  }

  ngOnInit() {
    this.subscription.add(
      this.importUsingGeocoding$.subscribe(
        (useGeocoding) => (this.tenantImportUsingGeocoding = useGeocoding)
      )
    );

    this.subscription.add(
      this.hasLocatorLibraryLocationsLimitBeenExceeded$.subscribe(
        (limitExceeded) => {
          this.hasLocatorLibraryLocationsLimitBeenExceeded = limitExceeded;
          this.locatorImportStatusError.errors = [];
          if (this.hasLocatorLibraryLocationsLimitBeenExceeded) {
            this.locatorImportStatusError.errors.push({
              error: `You have reached the limit for the number of locations in this library.`
            });
          }
        }
      )
    );

    this.locatorStore$.dispatch(
      hasLocatorLibraryLocationsLimitBeenExceededAttempt({
        libraryId: this.data.libraryId
      })
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onLocatorImportDataClicked(
    locatorImportLibraryDataForm: LocatorImportLibraryDataFormComponent
  ) {
    if (this.currentStage === 1) {
      this.locatorImportStatusError.errors = [];
      if (locatorImportLibraryDataForm.locatorImportLibraryDataForm.valid) {
        this.locatorStore$.dispatch(
          locatorFileImportAttempt({
            file: locatorImportLibraryDataForm.selectedFile,
            importUsingGeocoding:
              locatorImportLibraryDataForm.locatorImportLibraryDataForm.controls
                .geocodingSelection.value
          })
        );

        this.subscription.add(
          this.locatorImportProcessStepState$.subscribe(
            (importProcessStepState) => {
              this.importProcessStepState = importProcessStepState;
              this.screenToShow = this.getNextScreen(
                this.importProcessStepState
              );
              this.currentStage = this.getCurrentStage(this.screenToShow);
            }
          )
        );
        this.subscription.add(
          this.locatorImportProcessStatus$.subscribe(
            (locatorImportProcessStatus) => {
              if (locatorImportProcessStatus) {
                if (locatorImportProcessStatus.errorMessage) {
                  this.locatorImportStatusError = JSON.parse(
                    locatorImportProcessStatus?.errorMessage
                  );
                }
                if (
                  locatorImportProcessStatus.totalRecords &&
                  locatorImportProcessStatus.processedRecords
                ) {
                  this.totalRecords = locatorImportProcessStatus.totalRecords;
                  this.processedRecords =
                    locatorImportProcessStatus.processedRecords;
                  this.importedRecords =
                    locatorImportProcessStatus.importedRecords;
                }
              }
            }
          )
        );
      }
    } else {
      this.screenToShow = 'summary';
      this.currentStage = this.getCurrentStage(this.screenToShow);
      this.affirmativeButtonText = 'Close';
    }
  }

  getNextScreen(
    importProcessStepState: LocatorStatusProgressStep[]
  ): LocatorUiDialogSteps {
    if (
      importProcessStepState.length === 0 ||
      importProcessStepState[0].state === 'NotStarted' ||
      importProcessStepState[0].state === 'Processing' ||
      importProcessStepState[0].state === 'Error'
    ) {
      return 'select_csv_file';
    } else {
      return 'processing_data';
    }
  }

  getCurrentStage(currentScreen: LocatorUiDialogSteps | 'summary'): number {
    if (currentScreen === 'processing_data') {
      return 2;
    }
    if (currentScreen === 'summary') {
      return 3;
    }
    return 1;
  }

  onSelectedFileChanged(newFileName: string) {
    this.locatorImportStatusError.errors = [];
  }

  isAffirmativeButtonDisabled(
    locatorImportLibraryDataForm: LocatorImportLibraryDataFormComponent
  ) {
    return (
      locatorImportLibraryDataForm.invalidForm() ||
      this.locatorImportStatusError.errors.length > 0 ||
      this.importProcessStepState[0]?.state === 'Processing' ||
      (this.importProcessStepState[0]?.state === 'Completed' &&
        this.importProcessStepState[1]?.state !== 'Completed')
    );
  }

  isCancelButtonDisabled() {
    return (
      this.importProcessStepState[0]?.state === 'Completed' &&
      this.importProcessStepState[1]?.state === 'Processing'
    );
  }
}
