import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import { getScenarioBase, getScenarioDelta } from './scenario.selectors';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';

export const getSpatialModellerSupplyPoint = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.currentSupplyPoint
);

export const getSpatialModellerSupplyPointAttributes = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.supplyPointAttributes
);

export const getSpatialModellerSupplyPointAttributesByFascia = createSelector(
  getSpatialModellerSupplyPointAttributes,
  getSpatialModellerSupplyPoint,
  getSupplyPointAttributesByFascia
);

function getSupplyPointAttributesByFascia(
  supplyPointAttributes: SupplyPointAttribute[] | null,
  currentSupplyPoint: SupplyPoint | null
) {
  let supplyPointAttributesByFascia = null;
  if (currentSupplyPoint) {
    supplyPointAttributesByFascia = supplyPointAttributes?.filter(
      (attribute) => {
        const fasciaDependencies = attribute.fasciaDependency?.split(';');
        return (
          attribute.fasciaDependency == null ||
          fasciaDependencies?.includes(currentSupplyPoint?.fascia)
        );
      }
    );
  }
  return supplyPointAttributesByFascia ? supplyPointAttributesByFascia : null;
}

export const getTestInProgress = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.testInProgress
);

export const getTestInProgressFromSave = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.testInProgressFromSave
);

export const getTestInProgressFromClose = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.testInProgressFromClose
);

export const getTestInProgressFromReopen = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.testInProgressFromReopen
);

export const getIsSupplyPoint = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.isSupplyPoint
);

export const getSupplyKey = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.currentSupplyPoint?.supplyKey
);

export const getTestId = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.testId
);

export const getTestModelJobId = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.testModelJobId
);

export const getNextSupplyPointInfoToSelect = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.nextSupplyPointInfoToSelect
);

export const getPendingUnsavedSupplyPoint = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.pendingUnsavedSupplyPoint
);

export const getPendingUnsavedLocationSupplyPoint = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) => state.pendingUnsavedLocationSupplyPoint
);

export const getIsDeltaSupplyPoint = createSelector(
  getScenarioDelta,
  getSpatialModellerSupplyPoint,
  (delta, currentSupplyPoint) =>
    delta[0]?.supplyPointGroupId == currentSupplyPoint?.supplyPointGroupId
);

export const getIsBaseSupplyPoint = createSelector(
  getScenarioBase,
  getSpatialModellerSupplyPoint,
  (base, currentSupplyPoint) =>
    base.some(
      (baseCorr) =>
        baseCorr.supplyPointGroupId === currentSupplyPoint?.supplyPointGroupId
    )
);

export const getIsSupplyPointClosed = createSelector(
  fromFeature.getSpatialModellerTestState,
  (state) =>
    state.currentSupplyPoint?.isClosed != null &&
    state.currentSupplyPoint?.isClosed
);

export const getIsSupplyPointAndPendingUnsaved = createSelector(
  getPendingUnsavedSupplyPoint,
  getIsSupplyPoint,
  (pendingUnsavedSupplyPoint, isSupplyPoint) =>
    pendingUnsavedSupplyPoint && isSupplyPoint
);
