import { TooltipLayouts } from 'src/app/core/enums/tooltip-layout.enum';

export function buildTooltip(
  pickingInfo: any,
  mapTooltipLayout: TooltipLayouts
) {
  let content = buildTooltipContent(pickingInfo, mapTooltipLayout);
  let offset = calculateOffset(
    pickingInfo,
    content.numberOfRows,
    mapTooltipLayout
  );

  let style = {
    backgroundColor: '#FFF',
    color: '#111',
    fontFamily: 'Euclid Circular B',
    top: offset.top.toString() + 'px',
    left: offset.left.toString() + 'px',
    width: '250px'
  };
  return (
    pickingInfo.object && {
      html: content.html,
      style: style,
      className: offset.triangleClass
    }
  );
}

export function buildTooltipContent(
  pickingInfo: any,
  mapTooltipLayout: TooltipLayouts
) {
  let propertiesObj =
    pickingInfo.layer.props.mapLayerType == 'H3Hexagon'
      ? pickingInfo.object
      : pickingInfo.object.properties;

  let tooltipItemOrder = pickingInfo?.layer?.props?.tooltipItemOrder
    ? pickingInfo.layer.props.tooltipItemOrder
    : [];

  let hiddenTooltipColumns = pickingInfo?.layer?.props?.hiddenTooltipColumns
    ? pickingInfo.layer.props.hiddenTooltipColumns
    : [];

  let html = getTooltipHtmlTemplate(pickingInfo);

  let entries = buildTooltipItemList(
    propertiesObj,
    tooltipItemOrder,
    hiddenTooltipColumns
  );

  if (propertiesObj) {
    switch (mapTooltipLayout) {
      case TooltipLayouts.TwoColumns:
        return buildTwoColumnsTooltipContent(propertiesObj, html, entries);
      case TooltipLayouts.WrappedText:
        return buildWrappedTextTooltipContent(propertiesObj, html, entries);
      default:
        return buildLeftAlignedTooltipContent(propertiesObj, html, entries);
    }
  } else {
    return { html, numberOfRows: 0 };
  }
}

function getTooltipHtmlTemplate(pickingInfo: any) {
  return `<div  class="deck-tooltip-header" style="padding-bottom: 10px; border-bottom: solid 1px; margin-bottom:5px;">${truncateWithEllipsis(
    pickingInfo.layer.props.name,
    28
  )}</div>`;
}

function buildTooltipItemList(
  properties: any,
  tooltipItemOrder: string[],
  hiddenTooltipColumns: string[]
) {
  const defaultPropertiesToExclude = [
    'layerName',
    'cartodb_id',
    '_carto_point_agg_count',
    'is_supply_point',
    'is_library_location_point',
    '__total_of_features_in_group',
    '__latitude',
    '__longitude'
  ];

  const propertiesToExclude = [
    ...defaultPropertiesToExclude,
    ...hiddenTooltipColumns
  ];

  let entries: any[] = [];

  if (properties) {
    return tooltipItemOrder.length == 0
      ? orderTooltipItemsAlphabetically(properties, propertiesToExclude)
      : orderPropertiesinSpecifiedOrder(
          properties,
          propertiesToExclude,
          tooltipItemOrder
        );
  }

  return entries;
}

function orderTooltipItemsAlphabetically(
  properties: any,
  propertiesToExclude: string[]
) {
  let entries: any[] = [];

  entries = removeExcludedProperties(properties, propertiesToExclude);

  // Sort the entries alphabetically by name
  return entries.sort((a, b) => a.name.localeCompare(b.name));
}

function orderPropertiesinSpecifiedOrder(
  properties: any,
  propertiesToExclude: string[],
  propertyOrder: string[]
) {
  let entries: any[] = [];

  entries = removeExcludedProperties(properties, propertiesToExclude);

  // Sort the entries based on the specified property order
  entries.sort((a, b) => {
    const indexA = propertyOrder.findIndex(
      (prop) => prop.toLowerCase() === a.name.toLowerCase()
    );
    const indexB = propertyOrder.findIndex(
      (prop) => prop.toLowerCase() === b.name.toLowerCase()
    );

    // If neither property is in the specified order, sort alphabetically
    if (indexA === -1 && indexB === -1) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }

    // If one property is not in the specified order, prioritize the one that is
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    // Compare indices for sorting
    return indexA - indexB;
  });

  return entries;
}

function removeExcludedProperties(
  properties: any,
  propertiesToExclude: string[]
) {
  const entries = [];

  // Iterate over the properties and add them to the array
  for (const [name, value] of Object.entries(properties)) {
    if (!propertiesToExclude.includes(name)) {
      entries.push({ name, value });
    }
  }

  return entries;
}

function buildLeftAlignedTooltipContent(
  propertiesObj: any,
  html: string,
  entries: {
    name: string;
    value: unknown;
  }[]
) {
  let numberOfRows = 0;

  if (propertiesObj) {
    html += `<div>`;
    let rowsHtml = ``;

    for (const { name, value } of entries) {
      let nameHtml = `<b>${replaceUnderScore(capitalizeWord(name))}</b>`;
      let valueText = String(value);
      let rowContent = `${nameHtml}: ${valueText}`;

      rowsHtml += `<p class="deck-left-aligned-tooltip-row">${truncateWithEllipsis(
        rowContent,
        50
      )}</p>`;
      numberOfRows += 1;
    }

    html += rowsHtml;
    html += `</div>`;
  }

  return { html, numberOfRows };
}

function buildTwoColumnsTooltipContent(
  propertiesObj: any,
  html: string,
  entries: {
    name: string;
    value: unknown;
  }[]
) {
  let numberOfRows = 0;

  if (propertiesObj) {
    html += `<div class="deck-tooltip-row">`;
    let namesHtml = ``;
    let valuesHtml = ``;

    for (const { name, value } of entries) {
      namesHtml += `<p>${truncateWithEllipsis(
        replaceUnderScore(capitalizeWord(name)),
        14
      )}: </p>`;
      valuesHtml += `<p>${truncateWithEllipsis(String(value), 20)}</p>`;
      numberOfRows += 1;
    }

    html += `<div class="deck-tooltip-column-left" >` + namesHtml + `</div>`;
    html += `<div class="deck-tooltip-column-right" >` + valuesHtml + `</div>`;
    html += `</div>`;
  }

  return { html, numberOfRows };
}

function buildWrappedTextTooltipContent(
  propertiesObj: any,
  html: string,
  entries: {
    name: string;
    value: unknown;
  }[]
) {
  let numberOfRows = 0;

  if (propertiesObj) {
    html += `<div class="deck-wrap-text-tooltip">`;
    let rowsHtml = ``;

    for (const { name, value } of entries) {
      let nameHtml = `<b>${replaceUnderScore(capitalizeWord(name))}</b>`;
      let valueText = String(value);
      let rowContent = `${nameHtml}:<br> ${valueText}`;

      rowsHtml += `<p class="deck-left-aligned-tooltip-row">${truncateWithEllipsis(
        rowContent,
        255
      )}</p>`;
      numberOfRows += 1;
    }

    html += rowsHtml;
    html += `</div>`;
  }

  return { html, numberOfRows };
}

export function capitalizeWord(word: string) {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1);
}

export function replaceUnderScore(word: string) {
  return word.replace('_', ' ');
}

export function truncateWithEllipsis(word: string, numChars: number) {
  return word.length > numChars
    ? word.substring(0, numChars - 3) + '...'
    : word;
}

// calculate if tooltip will open off of screen
export function calculateOffset(
  pickingInfo: any,
  numberOfRows: number,
  mapTooltipLayout: TooltipLayouts
) {
  let tooltipHeight = calculateTooltipHeight(numberOfRows, mapTooltipLayout);

  let offset = {
    left: -125,
    top: -1 * tooltipHeight,
    triangleClass: 'deck-tooltip deck-tooltip-triangle-bottom'
  };

  let isMatch = false;

  if (pickingInfo.viewport) {
    if (isPointInBottomRightCorner(pickingInfo) && !isMatch) {
      offset.left = -225;
      offset.triangleClass = 'deck-tooltip';
      isMatch = true;
    }
    if (isPointInBottomLeftCorner(pickingInfo) && !isMatch) {
      offset.left = 10;
      offset.triangleClass = 'deck-tooltip';
      isMatch = true;
    }

    if (isPointInTopRightCorner(pickingInfo, tooltipHeight) && !isMatch) {
      offset.left = -225;
      offset.top = 10;
      offset.triangleClass = 'deck-tooltip';
      isMatch = true;
    }

    if (isPointInTopLeftCorner(pickingInfo, tooltipHeight) && !isMatch) {
      offset.left = 10;
      offset.top = 10;
      offset.triangleClass = 'deck-tooltip';
      isMatch = true;
    }

    if (isPointNearRightOfScreen(pickingInfo) && !isMatch) {
      offset.left = -235;
      offset.top = offset.top / 2;
      offset.triangleClass = 'deck-tooltip deck-tooltip-triangle-right';
      isMatch = true;
    }

    if (isPointNearLeftOfScreen(pickingInfo) && !isMatch) {
      offset.left = 10;
      offset.top = offset.top / 2;
      offset.triangleClass = 'deck-tooltip deck-tooltip-triangle-left';
      isMatch = true;
    }

    if (isPointNearTopOfScreen(pickingInfo, tooltipHeight) && !isMatch) {
      offset.top = 10;
      offset.triangleClass = 'deck-tooltip deck-tooltip-triangle-top';
      isMatch = true;
    }

    if (isPointNearBottomfScreen(pickingInfo) && !isMatch) {
      (offset.top = -1 * tooltipHeight - 5),
        (offset.triangleClass = 'deck-tooltip deck-tooltip-triangle-bottom');
      isMatch = true;
    }
  }

  return offset;
}

function calculateTooltipHeight(
  numberOfRows: number,
  mapTooltipLayout: TooltipLayouts
) {
  if (mapTooltipLayout === TooltipLayouts.WrappedText) {
    return numberOfRows == 0 ? 70 : 95 + numberOfRows * 42;
  }
  return numberOfRows == 0 ? 70 : 95 + numberOfRows * 21;
}
function isPointNearRightOfScreen(pickingInfo: any) {
  return pickingInfo.x > pickingInfo.viewport.width - 120;
}

function isPointNearLeftOfScreen(pickingInfo: any) {
  return pickingInfo.x < 120;
}

function isPointNearTopOfScreen(pickingInfo: any, tooltipHeight: number) {
  return pickingInfo.y < tooltipHeight;
}

function isPointNearBottomfScreen(pickingInfo: any) {
  return pickingInfo.y > pickingInfo.viewport.height - 100;
}

function isPointInBottomRightCorner(pickingInfo: any) {
  return (
    isPointNearBottomfScreen(pickingInfo) &&
    isPointNearRightOfScreen(pickingInfo)
  );
}

function isPointInTopRightCorner(pickingInfo: any, tooltipHeight: number) {
  return (
    isPointNearTopOfScreen(pickingInfo, tooltipHeight) &&
    isPointNearRightOfScreen(pickingInfo)
  );
}

function isPointInTopLeftCorner(pickingInfo: any, tooltipHeight: number) {
  return (
    isPointNearTopOfScreen(pickingInfo, tooltipHeight) &&
    isPointNearLeftOfScreen(pickingInfo)
  );
}

function isPointInBottomLeftCorner(pickingInfo: any) {
  return (
    isPointNearBottomfScreen(pickingInfo) &&
    isPointNearLeftOfScreen(pickingInfo)
  );
}
