import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/reporting/store';

import {
  clearUsage,
  getUsageSummaryAttempt
} from 'src/app/reporting/store/actions/usage.actions';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import { supplyPointDeselectAttempt } from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import * as fromUIStore from 'src/app/core/store';
import { ClearStateService } from 'src/app/core/services/clear-state.service';
@Component({
  selector: 'atlas-usage-workspace',
  templateUrl: './usage-workspace.component.html',
  styleUrls: ['./usage-workspace.component.less']
})
export class UsageWorkspaceComponent implements OnInit, OnDestroy {
  constructor(
    private route: Router,
    public layoutService: LayoutService,
    private store$: Store<fromStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    private clearStateService: ClearStateService
  ) {}

  showGrid = true;
  isSMFeatureAllowed$ = this.UIStore$.select(fromUIStore.isSMFeatureAllowed);
  isLocatorFeatureAllowed$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureAllowed
  );
  isProfilerFeatureAllowed$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureAllowed
  );

  ngOnInit(): void {
    this.store$.dispatch(getUsageSummaryAttempt());
  }

  ngOnDestroy(): void {
    this.store$.dispatch(clearUsage());
  }

  onBack() {
    this.clearStateService.clearFeatureState();
    this.route.navigate(['/home']);
  }

  onToggleGridChart() {
    this.showGrid = !this.showGrid;
  }
}
