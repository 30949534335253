import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'atlas-release-notes-workspace',
  templateUrl: './release-notes-workspace.component.html',
  styleUrls: ['./release-notes-workspace.component.less']
})
export class ReleaseNotesWorkspaceComponent {
  constructor(private route: Router) {}
  onBack() {
    this.route.navigate(['/home']);
  }
}
