import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { EditLibraryDialogComponent } from '../edit-library-dialog/edit-library-dialog.component';

@Component({
  selector: 'atlas-edit-library',
  templateUrl: './edit-library.component.html',
  styleUrls: ['../../less/library-management-button.component.less']
})
export class EditLibraryComponent {
  @Input() currentLibrary: LocatorLibrary;
  constructor(public dialogService: DialogService) {}

  onOpenEditLibraryDialog() {
    this.dialogService.show(EditLibraryDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: 'Library',
        mode: DialogMode.Add,
        headerPrefix: 'Edit',
        library: this.currentLibrary,
        affirmativeButtonText: 'Update'
      },
      disableClose: true
    });
  }
}
