<dx-data-grid
  class="standard-grid"
  [dataSource]="dataSource"
  keyExpr="supplyId"
  atlasDxDataGridDefaultOptions
  (onToolbarPreparing)="onToolbarPreparing($event)"
>
  <dxo-state-storing
    [enabled]="true"
    type="custom"
    [customLoad]="loadState"
    [customSave]="saveState"
  >
  </dxo-state-storing>

  <atlas-dx-grid-selection-handler
    identifier="Changes List"
    [selectedItemIds]="selectedSupplyIds"
    (itemSelected)="deltaLocationClickSelected.emit($event)"
  ></atlas-dx-grid-selection-handler>

  <dxo-load-panel [enabled]="false"> </dxo-load-panel>

  <atlas-dx-grid-header gridTitle="Changes"> </atlas-dx-grid-header>
  <div *dxTemplate="let data of 'parentTemplate'">
    <ng-template [ngTemplateOutlet]="template"></ng-template>
  </div>
</dx-data-grid>
