import { Component, Input } from '@angular/core';
import { LocatorImportStatusError } from 'src/app/locator/models/locator-import-status-error';

@Component({
  selector: 'atlas-locator-import-library-data-errors',
  templateUrl: './locator-import-library-data-errors.component.html',
  styleUrls: ['./locator-import-library-data-errors.component.less']
})
export class LocatorImportLibraryDataErrorsComponent {
  @Input() locatorImportStatusError: LocatorImportStatusError;

  constructor() {}
}
