import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromLocatorStore from 'src/app/locator-store';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'atlas-locator-reports',
  templateUrl: './locator-reports.component.html',
  styleUrls: ['./locator-reports.component.less']
})
export class LocatorReportsComponent {
  selectedQueryItem = new LocatorLofiQueryResult();

  selectedShapes$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedShapes
  ).pipe(
    delay(100)
  );
  
  constructor(private locatorStore$: Store<fromLocatorStore.State>) {}
}
