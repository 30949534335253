import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromLocatorStore from 'src/app/locator-store';
import {
  locationFromLocationGridClicked,
  selectLocationsForBatchOperation
} from 'src/app/locator-store/actions/locator-location.actions';
import { FeatureManagementOptions } from './feature-management-options';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';

@Component({
  selector: 'atlas-feature-management-panel',
  templateUrl: './feature-management-panel.component.html',
  styleUrls: ['./feature-management-panel.component.less']
})
export class FeatureManagementComponent {
  constructor(private locatorStore$: Store<fromLocatorStore.State>) {}

  libraryId$ = this.locatorStore$.select(fromLocatorStore.getLocatorLibraryId);

  selectedLocationId$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedLocationId
  );

  featureManagementOption: FeatureManagementOptions = 'Layers';

  onFeatureManagementOptionSelected(option: FeatureManagementOptions) {
    this.featureManagementOption = option;
  }

  onLocationClickSelected(locations: LocatorLibraryData[]) {
    if (locations.length === 1) {
      if (locations[0].latitude && locations[0].longitude) {
        this.locatorStore$.dispatch(
          locationFromLocationGridClicked({ locationData: locations[0] })
        );
      }
    } else {
      const locationIds = locations.map((location) => location.id);
      this.locatorStore$.dispatch(
        selectLocationsForBatchOperation({ locationIds })
      );
    }
  }
}
