<atlas-add-edit-dialog
  [saveButtonText]="data.affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  (saveClicked)="onLocatorImportDataClicked(locatorImportLibraryDataForm)"
  [saveDisabled]="locatorImportLibraryDataForm.invalidForm()"
  class="add-edit-dialog-content"
>
  <atlas-locator-import-library-data-form
    #locatorImportLibraryDataForm
    formContent
    [hasLocatorLibraryLocationsLimitBeenExceeded]="
      hasLocatorLibraryLocationsLimitBeenExceeded
    "
    [libraryId]="data.libraryId"
    [tenantImportUsingGeocoding]="tenantImportUsingGeocoding"
  >
  </atlas-locator-import-library-data-form>
</atlas-add-edit-dialog>
