import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { DialogService } from '../../services/dialog.service';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { LocatorReferenceLibraryDialogComponent } from '../library-management/reference-library-dialog/reference-library-dialog.component';
import { Subscription } from 'rxjs';
import * as fromLocatorStore from 'src/app/locator-store/';
import { LocatorReferenceLibrary } from 'src/app/locator/models/locator-reference-library';
import { ClearStateService } from '../../services/clear-state.service';
import { getHideUsageSection } from '../../store';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
@Component({
  selector: 'atlas-menu',
  templateUrl: './atlas-menu.component.html',
  styleUrls: ['./atlas-menu.component.less']
})
export class AtlasMenuComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private referenceLibraries: LocatorReferenceLibrary[];

  constructor(
    private themeService: ThemeService,
    private route: Router,
    private authService: AuthService,
    private dialogService: DialogService,
    private locatorStore$: Store<fromLocatorStore.State>,
    private clearStateService: ClearStateService,
    private mapService: MapService
  ) {}

  userInfo$ = this.authService.userInfo$;

  hideUsageSection$ = this.locatorStore$.select(getHideUsageSection);

  ngOnInit(): void {
    this.subscription.add(
      this.locatorStore$
        .select(fromLocatorStore.getLocatorReferenceLibraries)
        .subscribe((libraries) => {
          this.referenceLibraries = libraries ? libraries : [];
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onToggleTheme() {
    this.themeService.swapTheme();
  }
  onNavigateToStyleGuide() {
    this.route.navigate(['/style-guide']);
  }

  onNavigateToUsage() {
    this.route.navigate(['/reporting/usage']);
    this.clearStateService.resetApplication(false);
  }

  onNavigateToSettings() {
    this.route.navigate(['/reporting/settings']);
    this.clearStateService.resetApplication(false);
  }

  onNavigateToHelp() {
    this.route.navigate(['/reporting/help']);
    this.clearStateService.resetApplication();
  }

  onNavigateToReleaseNotes() {
    this.route.navigate(['/reporting/release-notes']);
    this.clearStateService.resetApplication();
  }

  onShowReferenceLibaries() {
    this.dialogService.show(LocatorReferenceLibraryDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        referenceLibraries: this.referenceLibraries
      },
      disableClose: true
    });
  }

  onCopyMap() {
    this.mapService.takeMapScreenshot('Clipboard');
  }

  onDownloadMap() {
    this.mapService.takeMapScreenshot('Download');
  }

  showDarkMode() {
    return environment.baseUrl.includes('localhost');
  }

  showStyleGuide() {
    return environment.baseUrl.includes('localhost');
  }

  showLocatorReferenceLibraries() {
    return this.referenceLibraries.length > 0;
  }

  onLogout() {
    this.authService.logout();
  }
}
