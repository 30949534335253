<div *ngIf="isProfilerFeatureSelected$ | async; else otherFeatures">
  <div atlasStatusScreenDisableUI>
    <app-map></app-map>
  </div>
</div>
<ng-template #otherFeatures>
  <app-map></app-map>
</ng-template>
<div *ngIf="loadingOverlayService.loading$ | async" class="loading-background">
  <mat-spinner class="spinner"></mat-spinner>
</div>
<div class="home-container">
  <atlas-main-tools-panel></atlas-main-tools-panel>
  <atlas-properties-layout></atlas-properties-layout>
  <atlas-map-navigation-control></atlas-map-navigation-control>
  <atlas-copyright-notices></atlas-copyright-notices>
  <atlas-profile-status-screen></atlas-profile-status-screen>
  <!-- <atlas-expand-lofi-report></atlas-expand-lofi-report> -->
</div>
