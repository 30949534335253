import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, iif, EMPTY } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import { SpatialModellerDataService } from 'src/app/spatial-modeller/services/spatial-modeller-data.service';
import {
  generateNewSupplyPointClicked,
  initialiseSpatialModellerSucceeded,
  spatialModellerTestCompleted,
  spatialModellerTestErrorOccurred,
  spatialModellerTestStarted,
  supplyPointClicked,
  loadSupplyPointSucceeded,
  supplyPointSelected,
  spatialModellerTestPollingStatus,
  manualPinClicked,
  supplyPointValuesResetAttempt,
  unsavedSupplyPointDeselected,
  supplyPointDeselected,
  spatialModellerTestResultUpdate,
  spatialModellerModelLocationStarted,
  spatialModellerModelLocationPollingStatus,
  spatialModellerModelLocationSucceed,
  spatialModellerModelLocationSucceedFromSPSave,
  spatialModellerModelLocationStartedFromSPSave,
  spatialModellerModelLocationStartedFromSPClose,
  spatialModellerModelLocationSucceedFromSPClose,
  spatialModellerModelLocationSucceedFromSPReOpen,
  spatialModellerModelLocationStartedFromSPReOpen,
  spatialModellerTestAfterLocationGeneratedStarted,
  spatialModellerModelLocationPollingCancelled,
  spatialModellerOpenRetryDialogAfterModelLocationFail,
  spatialModellerModelLocationStartedFromSPUntouchedTemporarySave
} from '../actions/spatial-modeller-test.actions';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';
import {
  findMyLocationSucceded,
  manualPinDragSucceded,
  manualPinDropSucceded,
  mapPinCleared,
  selectLocationSucceded
} from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import {
  getIsSupplyPoint,
  getPendingUnsavedLocationSupplyPoint,
  getPendingUnsavedSupplyPoint,
  getSpatialModellerSupplyPoint
} from '../selectors/spatial-modeller-test.selectors';
import { State } from '../reducers';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import {
  smModelResultsCircleLayerIdentifier,
  smModelResultsDriveTimeLayerIdentifier,
  smModelTestResultsLayerIdentifier
} from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import { spatialModellerGetStatsAttempt } from '../actions/spatial-modeller-stats.actions';
import { TestStatusResponse } from 'src/app/spatial-modeller/models/test-status-response';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { environment } from 'src/environments/environment';
import {
  closeSupplyPointSucceeded,
  reopenSupplyPointSucceeded
} from '../actions/supply-point.actions';
import { saveSupplyPointSucceeded } from '../actions/supply-point.actions';
import { loadAppFeaturesSucceeded } from 'src/app/core/store/actions/app-feature-ui.actions';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { ModelLocationResponse } from 'src/app/spatial-modeller/models/model-location-response';

import { getScenarioId } from 'src/app/spatial-modeller-store';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { RetrySupplyPointActionDialogComponent } from 'src/app/core/components/properties-panel/retry-supply-point-action-dialog/retry-supply-point-action-dialog.component';
import { SmTestUserActionSources } from 'src/app/spatial-modeller/models/test-action-sources';
import {
  getSpatialModellerLiveModeDisabled,
  getUserNetworkPlanningSettingsLiveModeDisabled
} from 'src/app/core/store';

@Injectable()
export class SpatialModellerTestEffects {
  constructor(
    private actions$: Actions,
    private spatialModellerDataService: SpatialModellerDataService,
    private mapService: MapService,
    private store$: Store<State>,
    private appFeatureStateService: AppFeatureStateService,
    public dialogService: DialogService
  ) {}

  getSpatialModellerSupplyPointAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppFeaturesSucceeded),
      this.appFeatureStateService.allowWhenSmFeatureAllowed(),
      switchMap(() =>
        this.spatialModellerDataService.getSupplyPointAttributes().pipe(
          map((supplyPointAttributes: SupplyPointAttribute[]) => {
            supplyPointAttributes = supplyPointAttributes.sort(
              (a, b) => a.displayOrder - b.displayOrder
            );
            return initialiseSpatialModellerSucceeded({
              supplyPointAttributes
            });
          })
        )
      )
    )
  );

  getSpatialModellerSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supplyPointClicked),
      withLatestFrom(
        this.store$.select(getSpatialModellerSupplyPoint),
        this.store$.select(getPendingUnsavedSupplyPoint),
        this.store$.select(getPendingUnsavedLocationSupplyPoint)
      ),
      filter(
        ([{ id }, currentSupplyPoint]) =>
          // Clicking twice in the same supply point has no effect to avoid executing the model again without any change
          id != currentSupplyPoint?.supplyKey // !== is not used here because types are different (number vs number | 'undefined')
      ),
      switchMap(
        ([
          { id, location },
          _,
          supplyPointEditedNotSaved,
          locationSupplyPointEditedNotSaved
        ]) =>
          iif(
            () =>
              supplyPointEditedNotSaved || locationSupplyPointEditedNotSaved,
            of(
              unsavedSupplyPointDeselected({
                nextSelectedSupplyPointInfo: {
                  newSelectedLocation: location,
                  newSelectedSupplyPointId: id
                }
              })
            ),
            this.spatialModellerDataService.getSupplyPointValues(id).pipe(
              map((newSupplyPoint: SupplyPoint) => {
                return loadSupplyPointSucceeded({
                  supplyPoint: newSupplyPoint,
                  location
                });
              })
            )
          )
      )
    )
  );

  supplyPointDeselected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supplyPointDeselected),
      withLatestFrom(
        this.store$.select(getPendingUnsavedSupplyPoint),
        this.store$.select(getPendingUnsavedLocationSupplyPoint),
        this.store$.select(getIsSupplyPoint)
      ),
      switchMap(
        ([
          _,
          pendingUnsavedSupplyPoint,
          pendingUnsavedLocationSupplyPoint,
          isSupplyPoint
        ]) =>
          iif(
            () =>
              (pendingUnsavedSupplyPoint ||
                pendingUnsavedLocationSupplyPoint) &&
              isSupplyPoint,
            of(
              unsavedSupplyPointDeselected({
                nextSelectedSupplyPointInfo: {
                  newSelectedSupplyPointId: null,
                  newSelectedLocation: null
                }
              })
            ),
            EMPTY
          )
      )
    )
  );

  supplyPointDeselectedWhilePollingSpatialModellerModelLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mapPinCleared),
        map(() => {
          this.spatialModellerDataService.pollingModelLocationStatus = false;
        })
      ),
    { dispatch: false }
  );

  getResetSpatialModellerSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supplyPointValuesResetAttempt),
      withLatestFrom(this.store$.select(getSpatialModellerSupplyPoint)),
      switchMap(([{ location }, supplyPoint]) => {
        const supplyKey = supplyPoint ? supplyPoint.supplyKey : 0;
        return this.spatialModellerDataService
          .getSupplyPointValues(supplyKey)
          .pipe(
            map((supplyPoint: SupplyPoint) => {
              return loadSupplyPointSucceeded({
                supplyPoint,
                location
              });
            })
          );
      })
    )
  );

  pinSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        loadSupplyPointSucceeded,
        closeSupplyPointSucceeded,
        reopenSupplyPointSucceeded
      ),
      map(({ location }) => {
        return supplyPointSelected({ location });
      })
    )
  );

  saveSupplyPointSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSupplyPointSucceeded),
      filter((props) => props.executeModel),
      map(({ location }) => {
        return supplyPointSelected({ location });
      })
    )
  );

  spatialModellerSetSupplyPointLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supplyPointSelected),
      this.appFeatureStateService.allowWhenSmFeatureSelected(),
      withLatestFrom(
        this.store$.select(getScenarioId),
        this.store$.select(getSpatialModellerLiveModeDisabled),
        this.store$.select(getUserNetworkPlanningSettingsLiveModeDisabled)
      ),
      filter(
        ([
          _,
          scenarioId,
          spatialModellerLiveModeDisabled,
          userSettingsLiveModeDisabled
        ]) => {
          const liveModeDisabled =
            userSettingsLiveModeDisabled ?? spatialModellerLiveModeDisabled;
          return !liveModeDisabled;
        }
      ),
      map(() => {
        return spatialModellerTestStarted();
      })
    )
  );

  spatialModellerGenerateLocationForSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        selectLocationSucceded,
        manualPinDropSucceded,
        manualPinDragSucceded,
        findMyLocationSucceded,
        manualPinClicked,
        generateNewSupplyPointClicked
      ),
      this.appFeatureStateService.allowWhenSmFeatureSelected(),
      this.appFeatureStateService.allowWhenNetworkPlanningSystemIsNotReadOnly(),
      withLatestFrom(
        this.store$.select(getScenarioId),
        this.store$.select(getSpatialModellerLiveModeDisabled),
        this.store$.select(getUserNetworkPlanningSettingsLiveModeDisabled)
      ),
      filter(
        ([
          _,
          scenarioId,
          spatialModellerLiveModeDisabled,
          userSettingsLiveModeDisabled
        ]) => {
          const liveModeDisabled =
            userSettingsLiveModeDisabled ?? spatialModellerLiveModeDisabled;
          return !liveModeDisabled;
        }
      ),
      map(() => {
        return spatialModellerModelLocationStarted();
      })
    )
  );

  spatialModellerTestStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerTestStarted),
      withLatestFrom(
        this.store$.select(getSpatialModellerSupplyPoint),
        this.store$.select(getScenarioId)
      ),
      switchMap(([_, supplyPoint, scenarioId]) =>
        this.spatialModellerDataService
          .executeScenarioTest(
            scenarioId,
            supplyPoint!.uid,
            supplyPoint!.supplyId
          )
          .pipe(
            map((testStatusResponse: TestStatusResponse) => {
              return spatialModellerTestPollingStatus({ testStatusResponse });
            }),
            catchError((error) =>
              of(
                spatialModellerTestErrorOccurred({
                  errorOn: 'Test:',
                  error
                })
              )
            )
          )
      )
    )
  );

  spatialModellerTestAfterLocationGeneratedStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerTestAfterLocationGeneratedStarted),
      withLatestFrom(
        this.store$.select(getSpatialModellerSupplyPoint),
        this.store$.select(getScenarioId)
      ),
      switchMap(([_, supplyPoint, scenarioId]) =>
        this.spatialModellerDataService
          .executeScenarioTest(scenarioId, Number(_.uId), supplyPoint!.supplyId)
          .pipe(
            map((testStatusResponse: TestStatusResponse) => {
              return spatialModellerTestPollingStatus({ testStatusResponse });
            }),
            catchError((error) =>
              of(
                spatialModellerTestErrorOccurred({
                  errorOn: 'Test:',
                  error
                })
              )
            )
          )
      )
    )
  );

  spatialModellerTestPollingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerTestPollingStatus),
      withLatestFrom(this.store$.select(getScenarioId)),
      switchMap(([{ testStatusResponse }, scenarioId]) =>
        this.spatialModellerDataService
          .pollForModelScenarioTestStatus(
            scenarioId,
            testStatusResponse.testId,
            testStatusResponse.jobId
          )
          .pipe(
            map((statusResponse) => {
              return spatialModellerTestResultUpdate({
                testStatusResponse: statusResponse
              });
            }),
            catchError((error) =>
              of(
                spatialModellerTestErrorOccurred({
                  errorOn: 'Test:',
                  error
                })
              )
            )
          )
      )
    )
  );

  spatialModellerTestResultUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerTestResultUpdate),
      withLatestFrom(
        this.store$.select(getSpatialModellerSupplyPoint),
        this.store$.select(getScenarioId)
      ),
      switchMap(([{ testStatusResponse }, supplyPoint, scenarioId]) =>
        this.spatialModellerDataService
          .updateModelScenarioTestResult(
            scenarioId,
            testStatusResponse.testId,
            testStatusResponse.jobId
          )
          .pipe(
            map((statusResponse) => {
              if (!supplyPoint?.isClosed) {
                this.showModelTestResultsMapLayer(statusResponse.testId);
              }
              this.showModelResultsDrivetimeMapLayer(supplyPoint?.location);
              this.showModelResultsCircleMapLayer(supplyPoint?.location);
              return spatialModellerTestCompleted({
                testId: statusResponse.testId
              });
            }),
            catchError((error) =>
              of(
                spatialModellerTestErrorOccurred({
                  errorOn: 'Test:',
                  error
                })
              )
            )
          )
      )
    )
  );

  spatialModellerTestCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerTestCompleted),
      map(({ testId }) => {
        return spatialModellerGetStatsAttempt({ testId });
      })
    )
  );

  spatialModellerModelLocationStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerModelLocationStarted),
      withLatestFrom(this.store$.select(getSpatialModellerSupplyPoint)),
      switchMap(([_, supplyPoint]) =>
        this.spatialModellerDataService
          .executeModelLocationGeneration(supplyPoint!)
          .pipe(
            map((modelLocationStatusResponse: ModelLocationResponse) => {
              return spatialModellerModelLocationPollingStatus({
                supplyPoint: supplyPoint!,
                modelLocationStatusResponse: modelLocationStatusResponse,
                source: SmTestUserActionSources.PIN_DROP
              });
            }),
            catchError((error) =>
              of(
                spatialModellerTestErrorOccurred({
                  errorOn: 'Model Location Generation:',
                  error
                })
              )
            )
          )
      )
    )
  );

  spatialModellerModelLocationStartedFromSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        spatialModellerModelLocationStartedFromSPSave,
        spatialModellerModelLocationStartedFromSPUntouchedTemporarySave
      ),
      withLatestFrom(this.store$.select(getSpatialModellerSupplyPoint)),
      switchMap(([_, supplyPoint]) =>
        this.spatialModellerDataService
          .executeModelLocationGeneration(supplyPoint!)
          .pipe(
            map((modelLocationStatusResponse: ModelLocationResponse) => {
              return spatialModellerModelLocationPollingStatus({
                supplyPoint: supplyPoint!,
                modelLocationStatusResponse: modelLocationStatusResponse,
                source: SmTestUserActionSources.SAVE_SP
              });
            }),
            catchError((error) =>
              of(
                spatialModellerTestErrorOccurred({
                  errorOn: 'Model Location Generation:',
                  error
                })
              )
            )
          )
      )
    )
  );

  spatialModellerModelLocationStartedFromSupplyPointClose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerModelLocationStartedFromSPClose),
      withLatestFrom(this.store$.select(getSpatialModellerSupplyPoint)),
      switchMap(([_, supplyPoint]) => {
        let sp = { ...supplyPoint, ...{ isClosed: true } } as SupplyPoint;

        return this.spatialModellerDataService
          .executeModelLocationGeneration(sp)
          .pipe(
            map((modelLocationStatusResponse: ModelLocationResponse) => {
              return spatialModellerModelLocationPollingStatus({
                supplyPoint: sp,
                modelLocationStatusResponse: modelLocationStatusResponse,
                source: SmTestUserActionSources.CLOSE_SP
              });
            }),
            catchError((error) =>
              of(
                spatialModellerTestErrorOccurred({
                  errorOn: 'Model Location Generation:',
                  error
                })
              )
            )
          );
      })
    )
  );

  spatialModellerModelLocationStartedFromSupplyPointReOpen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerModelLocationStartedFromSPReOpen),
      withLatestFrom(this.store$.select(getSpatialModellerSupplyPoint)),
      switchMap(([_, supplyPoint]) => {
        let sp = { ...supplyPoint, ...{ isClosed: false } } as SupplyPoint;

        return this.spatialModellerDataService
          .executeModelLocationGeneration(sp!)
          .pipe(
            map((modelLocationStatusResponse: ModelLocationResponse) => {
              return spatialModellerModelLocationPollingStatus({
                supplyPoint: sp,
                modelLocationStatusResponse: modelLocationStatusResponse,
                source: SmTestUserActionSources.REOPEN_SP
              });
            }),
            catchError((error) =>
              of(
                spatialModellerTestErrorOccurred({
                  errorOn: 'Model Location Generation:',
                  error
                })
              )
            )
          );
      })
    )
  );

  spatialModellerModelLocationPollingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerModelLocationPollingStatus),
      switchMap(({ supplyPoint, modelLocationStatusResponse, source }) =>
        this.spatialModellerDataService
          .pollForModelLocationStatus(modelLocationStatusResponse.name)
          .pipe(
            map((statusResponse) => {
              if (
                statusResponse.status === 'POLLING_MODEL_LOCATION_CANCELLED'
              ) {
                return spatialModellerModelLocationPollingCancelled({
                  supplyPoint,
                  source
                });
              }
              if (statusResponse.status === 'FAILED') {
                return spatialModellerOpenRetryDialogAfterModelLocationFail({
                  source
                });
              }
              if (source == SmTestUserActionSources.SAVE_SP) {
                return spatialModellerModelLocationSucceedFromSPSave({
                  uId: statusResponse.uId
                });
              }

              if (source == SmTestUserActionSources.CLOSE_SP) {
                return spatialModellerModelLocationSucceedFromSPClose({
                  uId: statusResponse.uId
                });
              }

              if (source == SmTestUserActionSources.REOPEN_SP) {
                return spatialModellerModelLocationSucceedFromSPReOpen({
                  uId: statusResponse.uId
                });
              }

              return spatialModellerModelLocationSucceed({
                uId: statusResponse.uId
              });
            }),
            catchError((error) =>
              of(
                spatialModellerTestErrorOccurred({
                  errorOn: 'Test:',
                  error
                })
              )
            )
          )
      )
    )
  );

  openRetryDialogAfterModelLocationFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(spatialModellerOpenRetryDialogAfterModelLocationFail),
        map(({ source }) => this.openRetryModelLocationDialog(source))
      ),
    { dispatch: false }
  );

  showSnackbarErrorAfterModelLocationFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerOpenRetryDialogAfterModelLocationFail),
      switchMap((_) => {
        return of(
          spatialModellerTestErrorOccurred({
            errorOn: 'Location Generation:',
            error: `Execution failed, please try again.`
          })
        );
      })
    )
  );

  triggerModelRunOnLocationSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerModelLocationSucceed),
      switchMap((_) => {
        return of(
          spatialModellerTestAfterLocationGeneratedStarted({ uId: _.uId })
        );
      })
    )
  );

  private showModelTestResultsMapLayer(testId: number) {
    this.mapService.updateLayer(smModelTestResultsLayerIdentifier, {
      visible: true,
      data: `${environment.baseUrl}api/mapping/spatial-modeller/tests/${testId}/boundaries`
    });
  }

  private showModelResultsDrivetimeMapLayer(location: Point | undefined) {
    var usersVisiblePreference = this.mapService.getLayerVisiblityFromStorage(
      smModelResultsDriveTimeLayerIdentifier
    );
    let layer = this.mapService.getLayer(
      smModelResultsDriveTimeLayerIdentifier
    );
    let dataUrl = `${environment.baseUrl}api/lds/isolines?lat=${location?.latitude}&lng=${location?.longitude}&mode=${layer.props.lds.mode}&range=${layer.props.lds.range}&range_type=${layer.props.lds.range_type}`;

    if (location) {
      this.mapService.updateLayer(smModelResultsDriveTimeLayerIdentifier, {
        visible: usersVisiblePreference,
        data: dataUrl
      });
    }
  }

  private showModelResultsCircleMapLayer(location: Point | undefined) {
    var usersVisiblePreference = this.mapService.getLayerVisiblityFromStorage(
      smModelResultsCircleLayerIdentifier
    );
    let layer = this.mapService.getLayer(smModelResultsCircleLayerIdentifier);
    let dataUrl = `${environment.baseUrl}api/shape/circle?lat=${location?.latitude}&lng=${location?.longitude}&unit=${layer.props.circle.unit}&distance=${layer.props.circle.distance}`;

    if (location) {
      this.mapService.updateLayer(smModelResultsCircleLayerIdentifier, {
        visible: usersVisiblePreference,
        data: dataUrl
      });
    }
  }

  private openRetryModelLocationDialog(source: string) {
    this.dialogService.show(RetrySupplyPointActionDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-95vw-width',
      data: {
        source: source
      },
      disableClose: true
    });
  }
}
