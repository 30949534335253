<div class="zoom-container">
  <button (click)="onZoomOutClicked()" class="zoom-buttons zoom-out">
    <mat-icon>remove</mat-icon>
  </button>
  <button class="zoom-buttons zoom-indicator">
    {{ this.mapService.zoom | number : '.0-0' }}
  </button>
  <button (click)="onZoomInClicked()" class="zoom-buttons zoom-in">
    <mat-icon>add</mat-icon>
  </button>
</div>
