export interface SortOptions {
  value: ListSortOptions | LocatorListSortOptions;
  display: string;
}

export interface SearchAndSortValues {
  filter: string;
  sort: string;
}

export type ListSortOptions =
  | 'NameAsc'
  | 'NameDesc'
  | 'EditedAsc'
  | 'EditedDesc';

export type LocatorListSortOptions = 'CatchmentsHighLow' | 'CatchmentsLowHigh';

export function applyFilter(filterValue: string, data: any[]) {
  return data.filter((s) =>
    s.name.toLowerCase().includes(filterValue.trim().toLowerCase())
  );
}

export const SortValuesArray = [
  { value: 'NameAsc', display: 'Name: A-Z' },
  { value: 'NameDesc', display: 'Name: Z-A' },
  { value: 'EditedDesc', display: 'Date last opened: Recent First' },
  { value: 'EditedAsc', display: 'Date last opened: Oldest First' }
];

export function applySort(option: ListSortOptions, data: any[]) {
  const sortOptions = {
    NameAsc: (data: any[]) =>
      data.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : b.name.toLowerCase() > a.name.toLowerCase()
          ? -1
          : 0
      ),
    NameDesc: (data: any[]) =>
      data.sort((a, b) =>
        b.name.toLowerCase() > a.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? -1
          : 0
      ),
    EditedAsc: (data: any[]) =>
      data.sort((a, b) =>
        a.editedDt > b.editedDt ? 1 : b.editedDt > a.editedDt ? -1 : 0
      ),
    EditedDesc: (locations: any[]) =>
      locations.sort((a, b) =>
        b.editedDt > a.editedDt ? 1 : a.editedDt > b.editedDt ? -1 : 0
      )
  };

  return sortOptions[option](data);
}
