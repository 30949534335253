import { Directive, Host } from '@angular/core';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';
import {
  GeocodeLevel,
  mediumGeocodeLevels,
  strongGeocodeLevels,
  weakGeocodeLevels
} from 'src/app/locator/types/geocode-level';

@Directive({
  selector: '[atlasDxiColumnGeocodeTemplate]'
})
export class AtlasDxiColumnGeocodeTemplateDirective {
  constructor(@Host() dataGridColumn: DxiColumnComponent) {
    dataGridColumn.cellTemplate = function (cellElement: any, cellInfo: any) {
      styleWeakGeocodeLevel(cellInfo.text, cellElement);
      styleMediumGeocodeLevel(cellInfo.text, cellElement);
      styleStrongGeocodeLevel(cellInfo.text, cellElement);
    };
  }
}

function styleWeakGeocodeLevel(text: GeocodeLevel, cellElement: any) {
  if (weakGeocodeLevels.includes(text)) {
    generateDefaultTrafficLight(
      'atlas-dxi-geocode-level-column-red',
      'Low accuracy',
      cellElement
    );
  }
}

function styleMediumGeocodeLevel(text: GeocodeLevel, cellElement: any) {
  if (mediumGeocodeLevels.includes(text)) {
    generateDefaultTrafficLight(
      'atlas-dxi-geocode-level-column-amber',
      'Medium accuracy',
      cellElement
    );
  }
}

function styleStrongGeocodeLevel(
  text: GeocodeLevel | string,
  cellElement: any
) {
  if (strongGeocodeLevels.includes(text as GeocodeLevel) || text === '') {
    generateDefaultTrafficLight(
      'atlas-dxi-geocode-level-column-green',
      'High accuracy',
      cellElement
    );
  }
}

function generateDefaultTrafficLight(
  className: string,
  tooltipText: string,
  cellElement: any
) {
  // Obtain td element for current cell column index
  var parent = cellElement.parentNode.childNodes[cellElement.cellIndex];

  var wrapper = document.createElement('div');
  wrapper.className = `atlas-dxi-geocode-level-column ${className}`;
  var tooltip = document.createElement('span');
  tooltip.className = 'geocode-level-tooltip';
  tooltip.innerText = tooltipText;

  wrapper.appendChild(tooltip);
  parent.appendChild(wrapper);
}
