import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import * as fromLocatorStore from 'src/app/locator-store';
import {
  allShapesFromLocationShapeListClicked,
  shapeFromLocationShapeListClicked
} from 'src/app/locator-store/actions/locator-shape.actions';
import { DeleteCatchmentConfirmationDialogComponent } from './delete-catchment-confirmation-dialog/delete-catchment-confirmation-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
  selector: 'atlas-catchment-list',
  templateUrl: './catchment-list.component.html',
  styleUrls: ['./catchment-list.component.less']
})
export class CatchmentListComponent {
  private _location: LocatorLibraryData;
  @Input()
  get location() {
    return this._location;
  }
  set location(newLocation: LocatorLibraryData | null) {
    if (newLocation) {
      this.dataSource = newLocation ? newLocation.shapes : [];
      if (newLocation.id !== this._location?.id) {
        this.setAll(true);
      }
      this._location = newLocation;

      this.dataSource = newLocation ? [...newLocation.shapes] : [];
      this.dataSource.sort((a, b) => {
        return (
          this.shapeTypeOrder.indexOf(a.type) -
          this.shapeTypeOrder.indexOf(b.type)
        );
      });

      //localeCompare() method treats embedded numbers as strings
      this.dataSource.sort((a, b) => {
        if (a.type === b.type)
          return a.name.localeCompare(b.name, undefined, { numeric: true });
        else {
          return 1;
        }
      });
    }
  }

  @Input()
  set selectedShapes(newSelectedShapes: LocatorShape[] | null) {
    if (newSelectedShapes && newSelectedShapes.length > 0) {
      if (newSelectedShapes!.length === 1 && this.dataSource) {
        const selectedShape = this.dataSource.find(
          (row) => row.id === newSelectedShapes[0].id
        );
        if (selectedShape) {
          this.clearSelection();
          this.selection.select(selectedShape);
        }
      } else {
        if (
          this.location !== undefined &&
          this.selection.selected.length !== this.location?.shapes?.length
        ) {
          this.setAll(true);
        }
      }
    }
  }

  @Input() areMultipleLocationsSelected: boolean | null;

  displayedColumns: string[] = ['type', 'name', 'delete'];

  shapeTypeOrder: LocatorShapeTypes[] = [
    LocatorShapeTypes.Circle,
    LocatorShapeTypes.Car,
    LocatorShapeTypes.Walk,
    LocatorShapeTypes.FreeForm,
    LocatorShapeTypes.PublicTransport
  ];

  locatorShapeTypes = LocatorShapeTypes;
  dataSource: LocatorShape[];
  selection = new SelectionModel<LocatorShape>(true, []);
  allSelected: boolean = false;

  constructor(
    private store$: Store<fromLocatorStore.State>,
    public dialogService: DialogService
  ) {}

  setAll(checked: any) {
    this.clearSelection();
    if (checked && this.dataSource) {
      this.dataSource.forEach((row) => this.selection.select(row));
      this.allSelected = true;
    }
    this.store$.dispatch(
      allShapesFromLocationShapeListClicked({ allSelected: checked })
    );
  }

  selectRow(row: LocatorShape) {
    this.clearSelection();
    this.selection.select(row);
    this.store$.dispatch(shapeFromLocationShapeListClicked({ id: row.id }));
  }

  onDelete(shape: LocatorShape) {
    this.dialogService.show(DeleteCatchmentConfirmationDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-95vw-width',
      data: {
        shape: {
          name: shape.name,
          id: shape.id,
          libraryDataId: this._location.id
        },
        libraryId: this._location.libraryId
      }
    });
  }

  onClick(row: any) {
    this.selectRow(row);
  }

  private clearSelection() {
    if (this.dataSource?.length > 1) this.allSelected = false;
    this.selection.clear();
  }
}
