import { createReducer, on } from '@ngrx/store';
import {
  changeLocationCancelledCompleted,
  changeLocationClicked,
  changeLocationConfirmedSucceeded
} from 'src/app/core/store/actions/spatial-modeller-ui.actions';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import {
  findMyLocationSucceded,
  manualPinDragSucceded,
  manualPinDropSucceded,
  searchTextCleared,
  selectLocationSucceded,
  selectNewLocationForSelectionPinSucceeded
} from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import {
  buildDefaultSupplyPoint,
  getUpdatedSupplyPointValues
} from 'src/app/spatial-modeller/helpers/supply-point.helpers';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';
import {
  createScenarioSucceeded,
  swapScenarioSucceeded,
  updateScenarioSucceeded
} from '../actions/scenario.actions';
import {
  generateNewSupplyPointClicked,
  initialiseSpatialModellerSucceeded,
  spatialModellerTestCompleted,
  spatialModellerTestErrorOccurred,
  spatialModellerTestStarted,
  loadSupplyPointSucceeded,
  manualPinClicked,
  supplyPointDeselected,
  spatialModellerTestPollingStatus,
  updateSupplyPointValues,
  unsavedSupplyPointDeselected,
  pendingUnsavedSupplyPointReset,
  spatialModellerModelLocationSucceed,
  spatialModellerModelLocationSucceedFromSPSave,
  spatialModellerModelLocationSucceedFromSPReOpen,
  spatialModellerModelLocationSucceedFromSPClose,
  spatialModellerModelLocationStartedFromSPReOpen,
  spatialModellerModelLocationStarted,
  spatialModellerModelLocationStartedFromSPSave,
  spatialModellerModelLocationStartedFromSPClose,
  spatialModellerModelLocationStartedFromSPUntouchedTemporarySave
} from '../actions/spatial-modeller-test.actions';
import {
  deleteSupplyPointSucceeded,
  saveSupplyPointSucceeded,
  closeSupplyPointSucceeded,
  reopenSupplyPointSucceeded
} from '../actions/supply-point.actions';

export interface State {
  testId: number | null;
  testModelJobId: string | null;
  testInProgress: boolean;
  testInProgressFromSave: boolean;
  testInProgressFromClose: boolean;
  testInProgressFromReopen: boolean;
  isSupplyPoint: boolean;
  currentSupplyPoint: SupplyPoint | null;
  supplyPointAttributes: SupplyPointAttribute[] | null;
  pendingUnsavedSupplyPoint: boolean;
  pendingUnsavedLocationSupplyPoint: boolean;
  nextSupplyPointInfoToSelect: {
    location: Point | null;
    supplyPointId: number | null;
  } | null;
}

export const initialState: State = {
  testId: null,
  testModelJobId: null,
  testInProgress: false,
  testInProgressFromSave: false,
  testInProgressFromClose: false,
  testInProgressFromReopen: false,
  isSupplyPoint: false,
  currentSupplyPoint: null,
  supplyPointAttributes: null,
  pendingUnsavedSupplyPoint: false,
  pendingUnsavedLocationSupplyPoint: false,
  nextSupplyPointInfoToSelect: null
};

export const spatialModellerTestReducer = createReducer(
  initialState,
  on(
    spatialModellerTestStarted,
    spatialModellerModelLocationStarted,
    spatialModellerModelLocationStartedFromSPClose,
    spatialModellerModelLocationStartedFromSPReOpen,
    (state) => {
      return {
        ...state,
        testInProgress: true,
        testId: null,
        testModelJobId: null
      };
    }
  ),
  on(
    spatialModellerModelLocationStartedFromSPSave,
    spatialModellerModelLocationStartedFromSPUntouchedTemporarySave,
    (state) => {
      return {
        ...state,
        testInProgress: true,
        testId: null,
        testInProgressFromSave: true
      };
    }
  ),
  on(spatialModellerModelLocationStartedFromSPClose, (state) => {
    return {
      ...state,
      testInProgressFromClose: true
    };
  }),
  on(spatialModellerModelLocationStartedFromSPReOpen, (state) => {
    return {
      ...state,
      testInProgressFromReopen: true
    };
  }),
  on(spatialModellerTestPollingStatus, (state, { testStatusResponse }) => {
    return {
      ...state,
      testId: testStatusResponse.testId,
      testModelJobId: testStatusResponse.jobId,
      testInProgress: testStatusResponse.status === 'RUNNING' ? true : false
    };
  }),
  on(spatialModellerTestCompleted, (state, { testId }) => {
    return {
      ...state,
      testId: testId,
      testInProgress: false,
      testInProgressFromSave: false,
      testInProgressFromClose: false,
      testInProgressFromReopen: false
    };
  }),

  on(
    spatialModellerModelLocationSucceed,
    spatialModellerModelLocationSucceedFromSPSave,
    spatialModellerModelLocationSucceedFromSPClose,
    spatialModellerModelLocationSucceedFromSPReOpen,
    (state, { uId }) => {
      return {
        ...state,
        testInProgress: false,
        testInProgressFromSave: false,
        testInProgressFromClose: false,
        testInProgressFromReopen: false
      };
    }
  ),

  on(spatialModellerTestErrorOccurred, (state) => {
    return {
      ...state,
      testInProgress: false,
      testInProgressFromSave: false,
      testInProgressFromClose: false,
      testInProgressFromReopen: false
    };
  }),
  on(initialiseSpatialModellerSucceeded, (state, { supplyPointAttributes }) => {
    return {
      ...state,
      supplyPointAttributes: supplyPointAttributes,
      currentSupplyPoint: buildDefaultSupplyPoint(supplyPointAttributes)
    };
  }),
  on(
    selectLocationSucceded,
    manualPinDropSucceded,
    findMyLocationSucceded,
    manualPinClicked,
    (state, { location }) => {
      return {
        ...state,
        isSupplyPoint: false,
        currentSupplyPoint: {
          ...buildDefaultSupplyPoint(state.supplyPointAttributes),
          location
        },
        nextSupplyPointInfoToSelect: null,
        pendingUnsavedSupplyPoint: false,
        pendingUnsavedLocationSupplyPoint: false
      };
    }
  ),
  on(manualPinDragSucceded, (state, { location }) => {
    const isPreviousSupplyPoint = state.isSupplyPoint;
    return {
      ...state,
      isSupplyPoint: false,
      currentSupplyPoint: state.currentSupplyPoint
        ? isPreviousSupplyPoint
          ? {
              ...buildDefaultSupplyPoint(state.supplyPointAttributes),
              location
            }
          : { ...state.currentSupplyPoint, location }
        : state.currentSupplyPoint
    };
  }),
  on(generateNewSupplyPointClicked, (state, { supplyPointValues }) => {
    const updatedSupplyPoint = getUpdatedSupplyPointValues(
      supplyPointValues,
      state.supplyPointAttributes!,
      state.currentSupplyPoint!
    );
    return {
      ...state,
      currentSupplyPoint: {
        ...state.currentSupplyPoint,
        ...updatedSupplyPoint
      }
    };
  }),
  on(updateSupplyPointValues, (state, { supplyPointValues }) => {
    const updatedSupplyPoint = getUpdatedSupplyPointValues(
      supplyPointValues,
      state.supplyPointAttributes!,
      state.currentSupplyPoint!
    );

    return {
      ...state,
      currentSupplyPoint: {
        ...state.currentSupplyPoint,
        ...updatedSupplyPoint
      },
      pendingUnsavedSupplyPoint: true
    };
  }),
  on(
    loadSupplyPointSucceeded,
    closeSupplyPointSucceeded,
    reopenSupplyPointSucceeded,
    saveSupplyPointSucceeded,
    (state, { supplyPoint, location }) => {
      return {
        ...state,
        isSupplyPoint: true,
        currentSupplyPoint: { ...supplyPoint, location },
        nextSupplyPointInfoToSelect: null,
        pendingUnsavedSupplyPoint: false,
        pendingUnsavedLocationSupplyPoint: false
      };
    }
  ),
  on(selectNewLocationForSelectionPinSucceeded, (state, { location }) => {
    return {
      ...state,
      isSupplyPoint: true,
      currentSupplyPoint: {
        ...(state.currentSupplyPoint as SupplyPoint),
        location
      }
    };
  }),
  on(changeLocationConfirmedSucceeded, (state, { location }) => {
    const supplyPointWithoutAddressValues = {
      ...(state.currentSupplyPoint as SupplyPoint),
      address1: null,
      address2: null,
      address3: null,
      address4: null
    };
    return {
      ...state,
      currentSupplyPoint: {
        ...supplyPointWithoutAddressValues,
        location
      },
      pendingUnsavedLocationSupplyPoint: false
    };
  }),
  on(
    searchTextCleared,
    supplyPointDeselected,
    deleteSupplyPointSucceeded,
    (state) => {
      return {
        ...state,
        isSupplyPoint: false,
        currentSupplyPoint: buildDefaultSupplyPoint(
          state.supplyPointAttributes
        ),
        pendingUnsavedSupplyPoint: false,
        pendingUnsavedLocationSupplyPoint: false,
        testInProgress: false,
        testInProgressFromSave: false,
        testInProgressFromClose: false,
        testInProgressFromReopen: false,
        testId: null,
        testModelJobId: null
      };
    }
  ),
  on(unsavedSupplyPointDeselected, (state, { nextSelectedSupplyPointInfo }) => {
    return {
      ...state,
      nextSupplyPointInfoToSelect: nextSelectedSupplyPointInfo
        ? {
            location: nextSelectedSupplyPointInfo?.newSelectedLocation,
            supplyPointId: nextSelectedSupplyPointInfo?.newSelectedSupplyPointId
          }
        : null
    };
  }),
  on(pendingUnsavedSupplyPointReset, (state) => {
    return {
      ...state,
      pendingUnsavedSupplyPoint: false,
      pendingUnsavedLocationSupplyPoint: false,
      nextSupplyPointInfoToSelect: null,
      isSupplyPoint: false,
      currentSupplyPoint: buildDefaultSupplyPoint(state.supplyPointAttributes)
    };
  }),
  on(
    swapScenarioSucceeded,
    createScenarioSucceeded,
    updateScenarioSucceeded,
    (state) => {
      return {
        ...state,
        testId: null,
        testInProgress: false,
        testInProgressFromSave: false,
        testInProgressFromClose: false,
        testInProgressFromReopen: false,
        isSupplyPoint: false,
        currentSupplyPoint: buildDefaultSupplyPoint(
          state.supplyPointAttributes
        ),
        pendingUnsavedSupplyPoint: false,
        pendingUnsavedLocationSupplyPoint: false,
        nextSupplyPointInfoToSelect: null
      };
    }
  ),

  on(changeLocationClicked, (state) => {
    return {
      ...state,
      pendingUnsavedLocationSupplyPoint: true
    };
  }),
  on(changeLocationCancelledCompleted, (state) => {
    return {
      ...state,
      pendingUnsavedLocationSupplyPoint: false
    };
  }),

  on(
    spatialModellerModelLocationSucceed,
    spatialModellerModelLocationSucceedFromSPSave,
    spatialModellerModelLocationSucceedFromSPClose,
    spatialModellerModelLocationSucceedFromSPReOpen,
    (state, { uId }) => {
      return {
        ...state,
        currentSupplyPoint: {
          ...(state.currentSupplyPoint as SupplyPoint),
          uid: Number(uId)
        }
      };
    }
  )
);
