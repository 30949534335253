import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { WebMercatorViewport, FlyToInterpolator } from '@deck.gl/core/typed';

import bboxPolygon from '@turf/bbox-polygon';
import bbox from '@turf/bbox';
import union from '@turf/union';
import { FeatureCollection, Polygon, MultiPolygon } from '@turf/helpers';

import { Layer } from '../models/layer';
import { debounce } from '../utils/debounce';
import { Point } from '../../atlas-gazetteer/models/point.model';
import { AuthService } from 'src/app/auth/services/auth.service';
import {
  locatorDataShapesLayerIdentifier,
  pinDropLayerIdentifier
} from '../layers/layer.constants';
import { DynamicTilesetService } from './dynamic-tileset.service';
import { BaseMapLayersType } from '../models/base-map-layer-types';
import { BaseMapLayerService } from './base-map-layer.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import * as fromAppFeatureStore from 'src/app/core/store';
import { Store } from '@ngrx/store';
import { PolygonSelectionService } from './polygon-selection-service';
import { mapScreenShotNotification } from 'src/app/core/store';
import { CopyMapOptions } from '../../utils/map-utils';

@Injectable({
  providedIn: 'root'
})

// Layers will be held in a dictionary to access the dictionary always use dictionary.set && dictionary.get if you use array annotation dictionary[] you will run into problems
// info here https://blog.logrocket.com/building-type-safe-dictionary-typescript/
// The map service is a wrapper around the deck gl object and keeps track of the map layers.
// Atlas logic around dropping of pins or selection or any new Atlas bussiness logic should be in its own service that has the mapservice injected.
// lets try and keep this service clean and single responsiblity where possible
export class MapService {
  private deck: any = null;
  public zoom: number = 5;

  private layersToExcludeJwtToken = [pinDropLayerIdentifier];
  private layersDictionary = new Map<string, any>();

  private layerChange = new Subject();
  private layerChange$ = this.layerChange.asObservable();

  private layers = new BehaviorSubject<Layer[]>([]);
  public layers$ = this.layers.asObservable();
  maxFeatureCollection = 1000;

  public baseMapLayers = new Map<string, BaseMapLayersType>([
    ['Positron', 'Positron'],
    ['Satellite', 'Satellite'],
    ['Dark matter', 'Dark_Matter'],
    ['Voyager', 'Voyager']
  ]);

  getMapPosition$ = this.store$.select(fromAppFeatureStore.getInitialMapView);

  getRemoveSystemLayers$ = this.store$.select(
    fromAppFeatureStore.getRemoveSystemLayers
  );

  public selectionParentLayer = new BehaviorSubject<any>({});
  public selectionParentLayer$ = this.selectionParentLayer.asObservable();

  private _updateSetBounds = debounce(this.updateSetBounds.bind(this), 500);

  onViewStateChange = new BehaviorSubject<any>(null);

  centreViewCoordinatesSubject = new BehaviorSubject<any>(null);
  centreViewCoordinates$ = this.centreViewCoordinatesSubject.asObservable();

  removeSystemLayerIdentifiers: string[] = [];

  constructor(
    private authService: AuthService,
    private dynamicTilesetService: DynamicTilesetService,
    private polygonSelectionService: PolygonSelectionService,
    private baseMapLayerService: BaseMapLayerService,
    private localStorageService: LocalStorageService,
    private store$: Store<fromAppFeatureStore.State>
  ) {
    authService.currentJwtToken$.subscribe((jwtToken) => {
      if (jwtToken !== '') {
        this.setJwtTokenToLayers(jwtToken);
      }
    });
    this.getMapPosition$.subscribe((mapview) =>
      this.centreViewCoordinatesSubject.next({
        latitude: mapview.latitude,
        longitude: mapview.longitude
      })
    );

    this.getRemoveSystemLayers$.subscribe((layerIdentifiers) => {
      this.removeSystemLayerIdentifiers = layerIdentifiers;
    });
  }

  async addLayer(layer: Layer) {
    let newLayer: any;
    newLayer = await layer.getLayer(
      this.authService.getCurrentJwtToken(),
      environment.baseUrl,
      this,
      this.dynamicTilesetService,
      this.polygonSelectionService
    );

    this.layersDictionary.set(newLayer.props.id, newLayer);
    if (this.deck) this.updateDeck();

    this.updateVisiblityBasedOnZoomForLayer(newLayer.props.id, this.zoom);
  }

  // Selection layer does not extend from the Layer class and so does not
  // have properties like jwtToken and data load api. It therefore can't use
  // the addLayer method so adding this method here.
  addSelectionLayer(layer: any) {
    this.layersDictionary.set(layer.props.id, layer);
    if (this.deck) this.updateDeck();
  }

  updateLayer(id: string, props: any = {}) {
    this.updateLayerUseTriggerScale(id, props, true);
  }

  getLayer(id: string) {
    return this.layersDictionary.get(id);
  }

  // these are readonly do not add to this dictionary use the addlayer method
  getAllLayers(): Readonly<Map<string, any>> {
    return this.layersDictionary;
  }

  removeLayer(id: string) {
    this.layersDictionary.delete(id);

    if (this.deck) this.updateDeck();
  }

  removeAllLayers() {
    this.layersDictionary.clear();
    if (this.deck) this.updateDeck();
  }

  resetMapService() {
    this.layersDictionary.clear();
    this.layerChange.next([]);
    this.selectionParentLayer.next({});
    if (this.deck) this.updateDeck();
  }

  onLayerChange(ids: string | string[]): Observable<any> {
    ids = Array.isArray(ids) ? ids : [ids];
    return this.layerChange$.pipe(filter((el: any) => ids.includes(el.id)));
  }

  updateController(value: boolean) {
    this.deck.setProps({ controller: value });
  }

  // This can be moved to the deck helper ts more atlas logic
  // avaliable cursors
  // https://www.w3schools.com/cssref/tryit.asp?filename=trycss_cursor
  updateMouseCursor(pointerName: string) {
    if (this.deck && this.deck.props) {
      this.deck.setProps({ getCursor: () => pointerName });
    }
  }

  setDeckInstance(deck: any) {
    this.deck = deck;
    this._onViewStateChange();
    this.updateDeck();
  }

  private updateLayerUseTriggerScale(
    id: string,
    props: any = {},
    syncTriggerScale: boolean
  ) {
    if (this.layersDictionary.get(id) === undefined) {
      throw new Error(`[MapService] Layer ${id} cannot be found.`);
    }

    if (syncTriggerScale && props.visible !== undefined) {
      // sync visibleNotAffectedByTriggerScale property with visible property
      props.visibleNotAffectedByTriggerScale = props.visible;
    }

    const layer = this.layersDictionary.get(id);

    this.layersDictionary.set(id, layer.clone(props));

    this.layerChange.next(this.layersDictionary.get(id));

    if (this.deck) this.updateDeck();
  }

  private updateDeck() {
    let currentlayers = [];

    const layersValuesSorted = [...this.layersDictionary.values()].sort(
      (a, b) => a.props.zOrder - b.props.zOrder
    );

    for (const value of layersValuesSorted) {
      currentlayers.push(value);
    }

    this.deck.setProps({ layers: [...currentlayers] });
    this.layers.next(currentlayers);
  }

  public updateSetBounds(v: any) {
    this.onViewStateChange.next(getViewportBbox(v));
  }

  public _updateCentrePoint(v: any) {
    this.centreViewCoordinatesSubject.next(getCenterPointFromViewport(v));
  }

  slowUpdateSetBounds(v: any) {
    this._updateSetBounds(v);
  }

  slowUpdateCentrePoint(v: any) {
    this._updateCentrePoint(v);
  }

  public _onViewStateChange() {
    this.deck.props.onViewStateChange = ({ viewState }: any) => {
      this.slowUpdateSetBounds(viewState);
      this.slowUpdateCentrePoint(viewState);
      this.zoom = viewState.zoom;
      this.updateVisiblityBasedOnZoomForAllLayers(viewState.zoom);
    };
  }

  centreMap(location: Point) {
    this.deck.setProps({
      initialViewState: {
        longitude: location.longitude,
        latitude: location.latitude,
        zoom: 10,
        bearing: 0,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator()
      }
    });
  }

  zoomMap(location: Point, zoom: number) {
    this.deck?.setProps({
      initialViewState: {
        longitude: location.longitude,
        latitude: location.latitude,
        zoom: zoom,
        transitionDuration: 100
      }
    });
  }

  centreAndZoomExtentMap(featureCollection: FeatureCollection) {
    if (
      featureCollection.features?.length > 0 &&
      featureCollection.features.length <= this.maxFeatureCollection
    ) {
      let vp = this.getViewportForLargestPolygons(featureCollection);
      this.deck?.setProps({
        initialViewState: {
          longitude: vp.longitude,
          latitude: vp.latitude,
          zoom: vp.zoom,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator()
        }
      });
    }
  }

  syncTriggerScale(layerId: string) {
    this.updateVisiblityBasedOnZoomForLayer(layerId, this.zoom);
  }

  changeBaseMapLayer(baseMapLayersType: BaseMapLayersType) {
    this.deck.redraw();
    this.baseMapLayerService.setBaseMapLayerStyle(baseMapLayersType);
  }

  getBaseUrl() {
    return environment.baseUrl;
  }

  getLayerVisiblityFromStorage(layerId: string): boolean {
    var layersVisiblity: { [key: string]: boolean } = {};

    if (this.localStorageService.get('layer-visiblity')) {
      layersVisiblity = JSON.parse(
        this.localStorageService.get('layer-visiblity')
      );
    }

    return layersVisiblity && layersVisiblity[layerId] !== undefined
      ? layersVisiblity[layerId]
      : true;
  }

  saveLayerVisiblityInStorage(layerId: string, visiblity: boolean) {
    // currently we save if the user has toggled the system drivetime layer on or off
    // this is to be respected when new pin drops are made and stored across sessions
    // later this functionality may  be extended to ther layers

    var layersVisiblity: { [key: string]: boolean } = {};

    if (this.localStorageService.get('layer-visiblity')) {
      layersVisiblity = JSON.parse(
        this.localStorageService.get('layer-visiblity')
      );
    }

    layersVisiblity[layerId] = visiblity;

    this.localStorageService.set(
      'layer-visiblity',
      JSON.stringify(layersVisiblity)
    );
  }

  takeMapScreenshot(mode: CopyMapOptions) {
    this.mapScreenShotNotification('Generating map image.');
    this.deck.takeScreenshot = true;
    this.deck.screenShotOption = mode;
    // force map change, this layer exists in all maps, deck redraw didnt work
    if (this.getLayer(pinDropLayerIdentifier)) {
      this.updateLayer(pinDropLayerIdentifier, {
        zOrder: 99
      });
    }
  }

  mapScreenShotNotification(message: string) {
    this.store$.dispatch(mapScreenShotNotification({ message: message }));
  }

  private setJwtTokenToLayers(jwtToken: string) {
    const allLayers = this.getAllLayers();
    for (let layerItem of allLayers) {
      var layer = this.getLayer(layerItem[0]);
      if (
        !this.layersToExcludeJwtToken.includes(layerItem[0]) &&
        (layer.props.type !== 'ThematicBoundaryTileSet' ||
          layer.props.type !== 'BoundaryTileSet')
      ) {
        this.updateLayer(layerItem[0], {
          loadOptions: {
            fetch: {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                'cache-control': 'max-age=5'
              }
            }
          }
        });
      }
    }
  }

  private updateVisiblityBasedOnZoomForAllLayers(zoom: number) {
    const allLayers = this.getAllLayers();
    for (let layerItem of allLayers) {
      this.updateVisiblityBasedOnZoomForLayer(layerItem[0], zoom);
    }
  }

  private updateVisiblityBasedOnZoomForLayer(layerId: string, zoom: number) {
    var layer = this.getLayer(layerId);
    if (layer) {
      this.updateLayerUseTriggerScale(
        layerId,
        {
          visible: this.calculateLayerVisiblityFromTriggerScales(layer, zoom)
        },
        false
      );
    }
  }

  private calculateLayerVisiblityFromTriggerScales(layer: any, zoom: number) {
    var wholeZoomNumber = Math.round(zoom);
    if (layer.props?.visibleNotAffectedByTriggerScale) {
      return (
        layer.props.visibleNotAffectedByTriggerScale &&
        wholeZoomNumber >= layer.props.minTrigger &&
        wholeZoomNumber <= layer.props.maxTrigger
      );
    }
    return layer.props.visible;
  }

  private getViewportForLargestPolygons(featureCollection: any) {
    const deck = new WebMercatorViewport(this.deck.getViewports()[0]);

    const largestPolygon = featureCollection.features.reduce(
      (prev: any, curr: any) => {
        return union(
          prev as unknown as Polygon | MultiPolygon,
          curr as unknown as Polygon | MultiPolygon
        );
      }
    );

    const [minLng, minLat, maxLng, maxLat] = bbox(largestPolygon);
    const newViewport = deck.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat]
      ],
      {
        padding: 100
      }
    );

    return newViewport;
  }
}

function getViewportBbox(viewState: any) {
  const bounds = new WebMercatorViewport(viewState).getBounds();
  return bboxPolygon(bounds);
}

function getCenterPointFromViewport(viewState: any) {
  const viewport = new WebMercatorViewport(viewState);
  const centrePoint = viewport.unproject([
    viewport.width / 2,
    viewport.height / 2
  ]);
  return centrePoint;
}
