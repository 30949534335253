import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromTenantManagementUI from 'src/app/core/store';

@Component({
  selector: 'atlas-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent {}
