import { MapLayerTypes } from '../types/map-layer.types';

export const selectionColour = [25, 125, 255];

export const iconSelectionLayerProperties = {
  getIconColor: () => selectionColour
};

export const atlasMapMarkerSelectionLayerProperties = {
  pointType: 'icon',
  getIconSize: () => 24,
  getIconColor: () => selectionColour, // Remember to set mask to true in your icon mapping to enable colouring, if no color supplied defaults to black
  getIcon: () => 'marker',
  iconMapping: {
    marker: {
      x: 0,
      y: 0,
      width: 24,
      height: 24,
      anchorY: 20,
      mask: true
    }
  },
  iconAtlas: 'assets/map-icons/atlas-marker-stroked-24.svg',
  mapLayerType: 'InSiteEverywhereMapPin'
};

export const generalSelectionLayerProperties = {
  getFillColor: selectionColour
};

export const atlasLabelSelectionLayerProperties = {
  getColor: selectionColour
};

export const lineSelectionLayerProperties = {
  getLineColor: selectionColour
};

export function getSelectionProperties(mapLayerType: MapLayerTypes) {
  const mapLayerTypes = {
    Boundary: generalSelectionLayerProperties,
    H3Hexagon: generalSelectionLayerProperties,
    Point: generalSelectionLayerProperties,
    Icon: iconSelectionLayerProperties,
    BoundaryTileSet: generalSelectionLayerProperties,
    PointTileSet: generalSelectionLayerProperties,
    IconTileSet: iconSelectionLayerProperties,
    InSiteEverywhereMapPin: atlasMapMarkerSelectionLayerProperties,
    Label: atlasLabelSelectionLayerProperties,
    LabelTileSet: atlasLabelSelectionLayerProperties,
    ThematicBoundary: generalSelectionLayerProperties,
    ThematicPoint: generalSelectionLayerProperties,
    ThematicIcon: iconSelectionLayerProperties,
    ThematicBoundaryTileSet: generalSelectionLayerProperties,
    ThematicPointTileSet: generalSelectionLayerProperties,
    ThematicIconTileSet: iconSelectionLayerProperties,
    ThematicInSiteEverywhereMapPin: atlasMapMarkerSelectionLayerProperties,
    ThematicH3HexagonTileSet: generalSelectionLayerProperties,
    ThematicH3Hexagon: generalSelectionLayerProperties,
    Line: lineSelectionLayerProperties,
    LineTileSet: lineSelectionLayerProperties,
    ThematicLine: lineSelectionLayerProperties,
    ThematicLineTileSet: lineSelectionLayerProperties
  };

  return mapLayerTypes[mapLayerType];
}
