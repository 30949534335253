<form [formGroup]="searchForm" autocomplete="off">
  <div
    class="form-controls-padding"
    style="margin-top: 15px; margin-bottom: 10px"
  >
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Search locations</mat-label>
      <input
        matInput
        type="text"
        formControlName="filter"
        placeholder="Search locations"
        (keyup)="onFilterChanged($event)"
      />

      <mat-icon class="search-icon-wrapper" matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Sort</mat-label>
      <mat-select
        [hideSingleSelectionIndicator]="true"
        formControlName="sort"
        (selectionChange)="onSortChanged($event)"
      >
        <mat-option *ngFor="let option of sortOptions" [value]="option.value">
          {{ option.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="selection-controls" *ngIf="totalCount !== 0">
      <div>
        <mat-checkbox
          *ngIf="selectionMode === 'multiple'"
          [checked]="allSelected"
          (change)="selectAll($event)"
        >
          All
        </mat-checkbox>
      </div>
      <mat-checkbox [checked]="isSelected" (change)="toggleSelection($event)" class="select-control">
        Select
      </mat-checkbox>
    </div>

    <div class="list-summary" *ngIf="totalCount! > 0">
      Locations ({{ countMessage }})
    </div>

    <div class="list-container">
      <dx-data-grid
        style="
          padding-top: 10px;
          padding-bottom: 10px;
          width: 290px;
          height: calc(100vh - 55vh);
        "
        id="locationGrid"
        class="standard-grid"
        noDataText="There are currently no locations in this library."
        [dataSource]="customDataSource"
        [showColumnHeaders]="false"
        [showRowLines]="false"
        [showBorders]="false"
        [focusedRowEnabled]="true"
        [autoNavigateToFocusedRow]="true"
        [remoteOperations]="true"
        [columnAutoWidth]="true"
        [selection]="{ mode: selectionMode }"
        [columnWidth]="columnWidth"
        [focusedRowKey]="selectedLocationId"
        (onSelectionChanged)="itemSelected($event)"
        (onSelectionChanged)="onSelectionChanged($event)"
      >
        <dxo-load-panel [enabled]="true"></dxo-load-panel>

        <dxo-scrolling
          mode="virtual"
          rowRenderingMode="virtual"
          [renderAsync]="true"
        ></dxo-scrolling>
        <dxo-paging [pageSize]="100"></dxo-paging>

        <dxi-column
          dataField="name"
          caption="Name"
          sortOrder="asc"
          class="truncate-text"
        ></dxi-column>

        <dxi-column
          [dataField]="getShapeCountDataField()"
          caption=""
          alignment="center"
          cellTemplate="cellTemplate"
          [allowSearch]="false"
        ></dxi-column>
        <div *dxTemplate="let cell of 'cellTemplate'">
          <div class="atlas-dxi-shape-count-column">{{ cell.text }}</div>
        </div>
      </dx-data-grid>
    </div>
  </div>
</form>
