import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UsageSummary } from '../models/usage-summary';

@Injectable({ providedIn: 'root' })
export class UsageService {
  private usageSummaryApiUrl = `${environment.baseUrl}api/usage-summary/`;
  constructor(private http: HttpClient) {}

  getUsageSummary() {
    return this.http.get<UsageSummary>(`${this.usageSummaryApiUrl}`);
  }
}
