import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLocatorStore from 'src/app/locator-store';
import { LocatorImportStatusError } from '../models/locator-import-status-error';
import {
  locatorFileImportHeaderValidationSuccess,
  locatorFileImportHeaderValidationFailed
} from 'src/app/locator-store/actions/locator-import-validation.actions';

const expectedHeaders = [
  'uid',
  'description',
  'address1',
  'address2',
  'address3',
  'address4',
  'address5',
  'postal',
  'country',
  'latitude',
  'longitude'
];

@Injectable({
  providedIn: 'root'
})
export class LocatorImportValidationService {
  constructor(private store$: Store<fromLocatorStore.State>) {}

  async validateFileHeaders(file: File) {
    let actualHeaders = await this.readFirstLine(file);
    let validationErrors = this.validateHeaders(actualHeaders);

    validationErrors.length === 0
      ? this.store$.dispatch(locatorFileImportHeaderValidationSuccess({ file }))
      : this.dispatchErrors(validationErrors);
  }

  private dispatchErrors(validationErrors: string[]) {
    let errorMessageList = validationErrors.map((message) => ({
      error: message
    }));
    const LocatorImportStatusError: LocatorImportStatusError = {
      status: 'FileValidationFailed',
      errors: errorMessageList
    };
    this.store$.dispatch(
      locatorFileImportHeaderValidationFailed({
        errors: JSON.stringify(LocatorImportStatusError)
      })
    );
  }

  private async readFirstLine(file: File): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        let contents = event.target?.result as string;
        let firstLine = contents.split('\r\n')[0].replace(/"/g, '');
        let headers = firstLine!.split(',');
        resolve(headers);
      };

      reader.onerror = (event: ProgressEvent<FileReader>) => {
        reject(event.target?.error);
      };

      reader.readAsText(file.slice(0, 100));
    });
  }

  private validateHeaders(actualHeaders: string[]): string[] {
    let errors: string[] = [];

    if (actualHeaders.length !== expectedHeaders.length) {
      errors.push(
        `The selected csv file doesn't have the expected number of columns: ${expectedHeaders.length}`
      );
    }

    const missingHeaderNames: string[] = this.checkHeaderNames(actualHeaders);
    missingHeaderNames.forEach((missingHeader) => {
      errors.push(`Missing column: ${missingHeader}`);
    });

    if (missingHeaderNames?.length === 0) {
      const headersInWrongOrder: string[] =
        this.checkHeadersOrder(actualHeaders);
      headersInWrongOrder.forEach((wrongOrderHeader) =>
        errors.push(`Column in wrong order: ${wrongOrderHeader}`)
      );
    }
    return errors;
  }

  private checkHeaderNames(fields: string[]): string[] {
    return expectedHeaders.filter((column) => !fields.includes(column));
  }

  private checkHeadersOrder(fields: string[]): string[] {
    let wrongOrderHeaders: string[] = [];
    expectedHeaders.forEach((column, index) => {
      if (fields.includes(column)) {
        if (fields[index] != column) {
          wrongOrderHeaders.push(column);
        }
      }
    });
    return wrongOrderHeaders;
  }
}
