import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { DataGridService } from 'src/app/core/services/datagrid.service';
import { searchTextCleared } from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import { supplyPointDeselected } from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import {
  generateTempLocatorLibraryDataPointSucceded,
  getLocatorLibrarySucceded,
  libraryLocationClicked,
  updateLocationNameSucceeded
} from '../actions/locator-library.actions';
import {
  deleteLibraryLocationsSucceeded,
  locationFromGazetteerClicked
} from '../actions/locator-location.actions';
import { createLocatorShapesSucceded } from '../actions/locator-shape.actions';

@Injectable()
export class LocatorDataGridEffects {
  constructor(
    private actions$: Actions,
    private dataGridService: DataGridService
  ) {}

  refreshGrid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          updateLocationNameSucceeded,
          createLocatorShapesSucceded,
          generateTempLocatorLibraryDataPointSucceded
        ),
        map(() => {
          this.dataGridService.refreshGrid();
        })
      ),
    { dispatch: false }
  );

  refreshGridAndClearSelection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          searchTextCleared,
          supplyPointDeselected,
          deleteLibraryLocationsSucceeded,
          getLocatorLibrarySucceded
        ),
        map(() => {
          this.dataGridService.refreshGridAndClearSelection();
        })
      ),
    { dispatch: false }
  );

  selectRow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locationFromGazetteerClicked, libraryLocationClicked),
        map((location) => {
          this.dataGridService.selectRow(location.libraryDataId);
        })
      ),
    { dispatch: false }
  );
}
