import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

import {
  searchLocationFailed,
  selectLocationFailed,
} from '../actions/gazetteer.actions';

@Injectable()
export class SnackbarEffects {
  constructor(private actions$: Actions, private snackbar: SnackbarService) {}

  snackbarOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectLocationFailed, searchLocationFailed),
        tap(({ errorOn, error: message }) => {
          this.snackbar.error(errorOn, message);
        })
      ),
    { dispatch: false }
  );
}
