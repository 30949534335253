import { createSelector } from '@ngrx/store';
import * as fromLocatorImportLibraryData from '../reducers/locator-import-library-data.reducer';
import * as fromFeature from '../reducers';
import {
  buildLocatorProgressSteps,
  LocatorStatusTypes
} from 'src/app/locator/helpers/locator-status-helper';
import { LocatorStatusProgressStep } from 'src/app/locator/models/locator-status-progress-step';
import { getImportUsingGeocoding } from '../reducers/locator-import-library-data.reducer';

export const getLocatorImportDataStatus = createSelector(
  fromFeature.getLocatorImportLibraryDataState,
  fromLocatorImportLibraryData.getImportStatus
);

export const getLocatorImportDataStatusMessage = createSelector(
  fromFeature.getLocatorImportLibraryDataState,
  fromLocatorImportLibraryData.getImportStatusMessage
);

export const getLocatorImportDataProgressSteps = createSelector(
  getLocatorImportDataStatusMessage,
  generateProgressSteps
);

export const getSelectedLocatorImportUsingGeocoding = createSelector(
  fromFeature.getLocatorImportLibraryDataState,
  getImportUsingGeocoding
);

export const getHasLocatorLibraryLocationsImportLimitBeenExceeded =
  createSelector(
    fromFeature.getLocatorImportLibraryDataState,
    fromLocatorImportLibraryData.getHasLocatorLibraryLocationsImportLimitBeenExceeded
  );

function generateProgressSteps(
  statusMessage: LocatorStatusTypes | undefined
): LocatorStatusProgressStep[] {
  if (statusMessage) {
    return buildLocatorProgressSteps(
      statusMessage as LocatorStatusTypes
    ) as LocatorStatusProgressStep[];
  }

  return [];
}
