import { createReducer, on } from '@ngrx/store';
import { trimDecimalPlaces } from 'src/app/shared/utils/number-utils';
import { deleteSupplyPointSucceeded } from 'src/app/spatial-modeller-store/actions/supply-point.actions';
import { pendingUnsavedSupplyPointReset } from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import {
  legendLayerTurnedOff,
  manualPinClicked,
  supplyPointDeselected,
  supplyPointSelected
} from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import {
  swapScenarioSucceeded,
  createScenarioSucceeded,
  updateScenarioSucceeded
} from 'src/app/spatial-modeller-store/actions/scenario.actions';

import {
  createLibrarySucceeded,
  swapLibrarySucceeded
} from 'src/app/locator-store/actions/locator-library.actions';

import { Point } from '../../models/point.model';
import { SearchResultGroup } from '../../models/search-result-group';
import {
  existingPinDragSucceded,
  findMyLocationSucceded,
  manualPinDragSucceded,
  manualPinDropSucceded,
  reverseLocationSucceded,
  searchLocationAttempt,
  searchLocationFailed,
  searchLocationSucceded,
  searchTextCleared,
  selectLocationSucceded,
  selectNewLocationForSelectionPinSucceeded
} from '../actions/gazetteer.actions';
import { revertToExistingSelectedLocation } from 'src/app/core/store/actions/spatial-modeller-ui.actions';
import {
  deleteLibraryLocationsSucceeded,
  deselectAllLocations,
  locatorLocationSelected
} from 'src/app/locator-store/actions/locator-location.actions';
import {
  addingPolygonCancelled,
  generatingCatchmentsComplete
} from 'src/app/core/store/actions/locator-ui.actions';
import {
  createProfilerLibrarySucceeded,
  swapProfilerLibrarySucceeded,
  updateProfilerLibrarySucceeded
} from 'src/app/profiler-store/actions/profiler-library.actions';

export interface State {
  selectedLocation: {
    location: Point | null;
    address: string;
  };
  searchedText: string;
  foundLocations: SearchResultGroup[];
}

export const initialState: State = {
  selectedLocation: { location: null, address: '' },
  searchedText: '',
  foundLocations: []
};

export const gazetteerReducer = createReducer(
  initialState,
  on(searchLocationAttempt, (state, { searchedText }) => {
    return {
      ...state,
      searchedText: searchedText
    };
  }),
  on(searchLocationSucceded, (state, { foundLocations }) => {
    return {
      ...state,
      foundLocations: foundLocations
    };
  }),
  on(
    selectLocationSucceded,
    selectNewLocationForSelectionPinSucceeded,
    (state, { location }) => {
      const parseLocation = setLocationDecimals(location);
      return {
        ...state,
        selectedLocation: {
          ...state.selectedLocation,
          location: parseLocation,
          address: state.searchedText
        }
      };
    }
  ),
  on(
    searchTextCleared,
    supplyPointDeselected,
    deleteSupplyPointSucceeded,
    legendLayerTurnedOff,
    pendingUnsavedSupplyPointReset,
    swapScenarioSucceeded,
    createScenarioSucceeded,
    updateScenarioSucceeded,
    createLibrarySucceeded,
    swapLibrarySucceeded,
    createLibrarySucceeded,
    deleteLibraryLocationsSucceeded,
    addingPolygonCancelled,
    createProfilerLibrarySucceeded,
    swapProfilerLibrarySucceeded,
    updateProfilerLibrarySucceeded,
    generatingCatchmentsComplete,
    deselectAllLocations,
    (state, _) => {
      return {
        ...state,
        searchedText: '',
        selectedLocation: { location: null, address: '' },
        foundLocations: []
      };
    }
  ),
  on(searchLocationFailed, (state, _) => {
    return {
      ...state,
      foundLocations: []
    };
  }),
  on(
    findMyLocationSucceded,
    manualPinDropSucceded,
    manualPinDragSucceded,
    supplyPointSelected,
    manualPinClicked,
    existingPinDragSucceded,
    revertToExistingSelectedLocation,
    locatorLocationSelected,
    (state, { location }) => {
      const parseLocation = setLocationDecimals(location);
      return {
        ...state,
        selectedLocation: {
          ...state.selectedLocation,
          location: parseLocation
        }
      };
    }
  ),
  on(reverseLocationSucceded, (state, { address }) => {
    return {
      ...state,
      selectedLocation: { ...state.selectedLocation, address },
      searchedText: address
    };
  })
);

function setLocationDecimals(location: Point): Point {
  if (location.latitude && location.longitude) {
    const trimmedLatitude = trimDecimalPlaces(location.latitude.toString(), 6);
    const trimmedLongitude = trimDecimalPlaces(
      location.longitude.toString(),
      6
    );
    return {
      latitude: Number(trimmedLatitude),
      longitude: Number(trimmedLongitude)
    };
  }
  return {
    latitude: 0,
    longitude: 0
  };
}

export const getFoundLocations = (state: State) =>
  state && [...state.foundLocations];

export const getSelectedPoint = (state: State) =>
  state && state.selectedLocation;

export const getSearchedText = (state: State) => state && state.searchedText;
