import { Component, EventEmitter, Output } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { AddLibraryDialogComponent } from '../add-library-dialog/add-library-dialog.component';

@Component({
  selector: 'atlas-add-library',
  templateUrl: './add-library.component.html',
  styleUrls: ['./add-library.component.less']
})
export class AddLibraryComponent {
  constructor(public dialogService: DialogService) {}

  onOpenNewLibraryDialog() {
    this.dialogService.show(AddLibraryDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: 'Library',
        mode: DialogMode.Add,
        headerPrefix: 'Create a new',
        affirmativeButtonText: 'Create'
      },
      disableClose: true
    });
  }
}
