import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'atlas-dialog-cancel-button',
  templateUrl: './dialog-cancel-button.component.html',
  styleUrls: ['./dialog-cancel-button.component.less']
})
export class DialogCancelButtonComponent {
  @Output() clicked = new EventEmitter();
  @Input() label = 'Cancel';
  @Input() disabled = false;
}
