<div #contentListDiv>
  <ul class="version-list">
    <li class="version-item">
      <div class="version-header prod_v1.3.6">v1.3.6 - September 26, 2024</div>
      <ul class="feature-list">
        <li>
          Catchment Reporting: Batch create a catchment for multiple locations.
        </li>
        <li>
          Catchment Reporting: The locations list can be sorted by the number of
          catchments.
        </li>
        <li>
          Improved behaviour for moving logo locations with drag & drop on the
          map.
        </li>
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.3.5">v1.3.5 - August 29, 2024</div>
      <ul class="feature-list">
        <li>
          Up to 40,000 locations in a library can now be visualised on the map
          simultaneously.
        </li>
        <li>
          A selection or all locations in a library can now be deleted at once.
        </li>
        <li>
          Multiple catchments on the same location can now be reported on
          collectively.
        </li>
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.3.4">v1.3.4 - July 25, 2024</div>
      <ul class="feature-list">
        <li>
          Multiple catchments/isolines can be built around the same location
          using the same size.
        </li>
        <li>
          The Locations list is now more performant, capable of faster
          searching.
        </li>
        <li>Libraries are now more efficient, only loading when opened.</li>
        <li>
          Predefined catchments are now available allowing reporting on licensed
          fixed areas (e.g. administrative and postal geographies).
        </li>
        <li>
          Map layer tooltips can now support the wrapping of long text, rather
          than truncation.
        </li>
        <li>CACI testing will no longer affect Usage consumption.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.3.3">v1.3.3 - July 4, 2024</div>
      <ul class="feature-list">
        <li>Public transport catchments/isolines are now supported.</li>
        <li>
          Google search results will only include locations in the licensed
          country.
        </li>
        <li>
          Road speed used by time-of-day is now included in the description of
          catchments/isolines.
        </li>
        <li>
          Extended map layer icons to include various shapes and logos using SVG
          files.
        </li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.3.2">v1.3.2 - May 30, 2024</div>
      <ul class="feature-list">
        <li>
          Migrated walk and drive catchments/isolines to the Traveltime service
          provider.
        </li>
        <li>
          Ability to select midday, rushhour or night road speeds for drive
          isolines.
        </li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.3.1">v1.3.1 - May 23, 2024</div>
      <ul class="feature-list">
        <li>
          Network Planning: Facilitate read only systems (disable edit and add).
        </li>
        <li>
          Usage features extended to include number of logins and date of last
          login.
        </li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.3">v1.3 - May 9, 2024</div>
      <ul class="feature-list">
        <li>Enable users to set their own default catchment sizes.</li>
        <li>
          Support for line geometry map layers to display data such as road
          traffic volumes.
        </li>
        <li>
          Tool-tip improvements by allowing the user to select either two
          columns or left aligned.
        </li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.2.7">v1.2.7 - April 18, 2024</div>
      <ul class="feature-list">
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.2.6">v1.2.6 - March 26, 2024</div>
      <ul class="feature-list">
        <li>
          Network Planning: Users can select from multiple Excel reports, rather
          than just one.
        </li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.2.5">v1.2.5 - March 13, 2024</div>
      <ul class="feature-list">
        <li>
          Network Planning: facilitate AI and Machine Learning algorithms.
        </li>
        <li>
          Network Planning: improvements to the default report map layers to
          enhance the speed of Excel report generation.
        </li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.2.4">v1.2.4 - January 23, 2024</div>
      <ul class="feature-list">
        <li>
          InSite Everywhere release notes available to users from the burger
          menu.
        </li>
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.2.3">v1.2.3 - January 9, 2024</div>
      <ul class="feature-list">
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.2.2">v1.2.2 - November 30, 2023</div>
      <ul class="feature-list">
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.2.1">v1.2.1 - November 28, 2023</div>
      <ul class="feature-list">
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.2">v1.2 - November 27, 2023</div>
      <ul class="feature-list">
        <li>Settings and help implementations.</li>
        <li>
          Allow a user to be moved to a different tenant (if they are assigned
          to multiple tenants).
        </li>
        <li>Facilitate H3 hexagons to be used in map layers (tile service).</li>
        <li>Network Planning: Prevent models from auto-running.</li>
        <li>
          Catchment Reporting: Limit catchment sizes to reasonable extremes.
        </li>
        <li>Profile management: Create, switch, edit, rename, delete.</li>
        <li>Profiling performance improvements.</li>
        <li>Acorn PowerBI reports facilitated for Profiling.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.1.5">v1.1.5 - November 8, 2023</div>
      <ul class="feature-list">
        <li>Internal configuration changes.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.1.4">v1.1.4 - October 2, 2023</div>
      <ul class="feature-list">
        <li>
          Network Planning: Enter different metadata based on the fascia/brand.
        </li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.1.3">v1.1.3 - August 14, 2023</div>
      <ul class="feature-list">
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.1.2">v1.1.2 - June 30, 2023</div>
      <ul class="feature-list">
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.1.1">v1.1.1 - June 28, 2023</div>
      <ul class="feature-list">
        <li>Minor bug fixes and improvements.</li>
      </ul>
    </li>
    <li class="version-item">
      <div class="version-header prod_v1.1">v1.1 - May 26, 2023</div>
      <ul class="feature-list">
        <li>
          Network Planning: Allow the user to select and run one of many Excel
          reports.
        </li>
        <li>
          Catchment Reporting, library management: Create, switch, edit, rename,
          delete.
        </li>
        <li>Circle, walk and polygon catchment implementation.</li>
        <li>
          Enable graphs and carousel in reports panel for richer low-fidelity
          reports.
        </li>
        <li>PowerBI implementation for catchment reports.</li>
        <li>Share PowerBI reports via anonymous link.</li>
        <li>See the properties of a location and add a new catchment.</li>
      </ul>
    </li>
    <li class="version-item" hre>
      <div class="version-header prod_v1.0">v1.0 - Dec 15, 2022</div>
      <ul class="feature-list">
        <li>Map Layers, Properties and Report panel implementations.</li>
        <li>
          Define a new location; (i) Google search, (ii) Map-click, and (iii)
          Use current location.
        </li>
        <li>Google Street view of the selected location.</li>
        <li>
          Network planning, scenario management: Create, switch, edit, rename,
          delete.
        </li>
        <li>Excel reporting for Network Planning.</li>
      </ul>
    </li>
  </ul>
</div>
