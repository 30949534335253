import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const getUsageSummary = createSelector(
  fromFeature.getUsageState,
  (state) => state.usageSummary
);
export const getModelsUsageSummary = createSelector(
  fromFeature.getUsageState,
  (state) => state.usageSummary && state.usageSummary.models
);

export const getModelsUserUsageSummary = createSelector(
  getModelsUsageSummary,
  (model) => model?.userUsage
);

export const getModelsCompanyUsageSummary = createSelector(
  getModelsUsageSummary,
  (model) => model?.companyUsage
);

export const getModelsAllUsersUsageSummary = createSelector(
  getModelsUsageSummary,
  (model) => model?.allUsersUsage
);

export const getCatchmentsUsageSummary = createSelector(
  fromFeature.getUsageState,
  (state) => state.usageSummary && state.usageSummary.catchments
);

export const getCatchmentsUserUsageSummary = createSelector(
  getCatchmentsUsageSummary,
  (Catchment) => Catchment?.userUsage
);

export const getCatchmentsCompanyUsageSummary = createSelector(
  getCatchmentsUsageSummary,
  (Catchment) => Catchment?.companyUsage
);

export const getCatchmentsAllUsersUsageSummary = createSelector(
  getCatchmentsUsageSummary,
  (Catchment) => Catchment?.allUsersUsage
);

export const getRecordsUsageSummary = createSelector(
  fromFeature.getUsageState,
  (state) => state.usageSummary && state.usageSummary.profiledRecords
);

export const getRecordsUserUsageSummary = createSelector(
  getRecordsUsageSummary,
  (profiledRecords) => profiledRecords?.userUsage
);

export const getRecordsCompanyUsageSummary = createSelector(
  getRecordsUsageSummary,
  (profiledRecords) => profiledRecords?.companyUsage
);

export const getRecordsAllUsersUsageSummary = createSelector(
  getRecordsUsageSummary,
  (profiledRecords) => profiledRecords?.allUsersUsage
);
