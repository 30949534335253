<div class="button-container">
  <div
    matTooltip="Run model"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
  >
    <button
      *ngIf="!spatialModellerLiveModeDisabled"
      class="atlas-icon-button"
      mat-flat-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="isSubmitButtonDisabled()"
    >
      <mat-icon
        class="run-model-icon"
        [style.display]="isTestInProgress ? 'none' : 'inline-block'"
        >play_circle_outline</mat-icon
      >
      <mat-spinner
        class="update-button-spinner"
        *ngIf="isTestInProgress"
        diameter="24"
        strokeWidth="2"
      >
      </mat-spinner>
    </button>

    <button
      *ngIf="spatialModellerLiveModeDisabled"
      class="atlas-icon-button"
      mat-flat-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="isSubmitButtonLiveModeOffDisabled()"
    >
      <mat-icon
        class="run-model-icon"
        [style.display]="
          isTestInProgressNotFromSaveCloseReopenAndLiveModeOff()
            ? 'none'
            : 'inline-block'
        "
        >play_circle_outline</mat-icon
      >
      <mat-spinner
        class="update-button-spinner"
        *ngIf="isTestInProgressNotFromSaveCloseReopenAndLiveModeOff()"
        diameter="24"
        strokeWidth="2"
      >
      </mat-spinner>
    </button>
  </div>
  <div
    matTooltip="Restore defaults"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
  >
    <button
      class="atlas-icon-button atlas-icon icon-reset"
      mat-flat-button
      color="primary"
      aria-label="Restore defaults"
      (click)="onReset()"
      [disabled]="isRestoreDefaultsButtonDisabled()"
    ></button>
  </div>
  <div
    matTooltip="Save location"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
  >
    <button
      class="atlas-icon-button"
      mat-flat-button
      color="primary"
      aria-label="Save location"
      (click)="onSaveClicked()"
      [disabled]="isSaveButtonDisabled()"
    >
      <mat-icon
        [style.display]="
          isGenerateLocationInProgressFromSaveLiveModeOff()
            ? 'none'
            : 'inline-block'
        "
        >save</mat-icon
      >
      <mat-spinner
        class="update-button-spinner"
        *ngIf="isGenerateLocationInProgressFromSaveLiveModeOff()"
        diameter="24"
        strokeWidth="2"
      >
      </mat-spinner>
    </button>
  </div>
  <div
    [matTooltip]="isSupplyPointClosed ? 'Reopen location' : 'Close location'"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
  >
    <button
      class="atlas-icon-button"
      mat-flat-button
      color="primary"
      aria-label="Close location"
      (click)="onCloseClicked()"
      [disabled]="isCloseButtonDisabled()"
    >
      <mat-icon
        *ngIf="
          !isSupplyPointClosed &&
          !isGenerateLocationInProgressFromCloseOrReopenLiveModeOff()
        "
        >location_off</mat-icon
      >
      <mat-icon
        *ngIf="
          isSupplyPointClosed &&
          !isGenerateLocationInProgressFromCloseOrReopenLiveModeOff()
        "
        >location_on</mat-icon
      >
      <mat-spinner
        class="update-button-spinner"
        *ngIf="isGenerateLocationInProgressFromCloseOrReopenLiveModeOff()"
        diameter="24"
        strokeWidth="2"
      >
      </mat-spinner>
    </button>
  </div>
  <div
    matTooltip="Delete location"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
  >
    <button
      class="atlas-icon-button"
      mat-flat-button
      color="primary"
      aria-label="Delete location"
      (click)="onDeleteClicked()"
      [disabled]="isDeleteButtonDisabled()"
      mat-flat-button
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <button
    class="atlas-icon-button atlas-icon icon-street-view atlas-secondary-icon-button"
    mat-flat-button
    color="primary"
    aria-label="Street view"
    (click)="onStreetViewClicked()"
    matTooltip="Show street view"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
    [disabled]="isStreetViewDisabled()"
    *ngIf="!(layoutService.isDesktopView | async)"
  ></button>
</div>
