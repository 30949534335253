import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { changeLocationCancelledCompleted } from 'src/app/core/store/actions/spatial-modeller-ui.actions';
import {
  createLibrarySucceeded,
  getLocatorLibrarySucceded,
  swapLibrarySucceeded
} from 'src/app/locator-store/actions/locator-library.actions';
import {
  clearMapAndGazetterForMultiSelect,
  deleteLibraryLocationsSucceeded,
  deselectAllLocations,
  locationFromLocationGridClicked
} from 'src/app/locator-store/actions/locator-location.actions';
import {
  allShapesFromLocationShapeListClicked,
  createLocatorShapesAttempt,
  updateLocatorShapeAttempt,
  deleteLibraryLocationShapeAttempt
} from 'src/app/locator-store/actions/locator-shape.actions';
import {
  searchTextCleared,
  selectLocationSucceded,
  manualPinDropSucceded,
  manualPinDragSucceded,
  findMyLocationSucceded,
  defineNewLocationSucceeded
} from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import {
  hideModelTestResultsMapLayer,
  hideModelResultsDrivetimeMapLayer,
  hidePinDropLayerIdentifier,
  hideModelResultsCircleMapLayer,
  hideLocatorDataShapesMapLayer,
  hideLocatorShapeSpatialFunctionSystemLayer,
  hideLocatorEditPolygonSystemLayer
} from 'src/app/shared/atlas-mapping/helpers/system-layers-helper';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import {
  swapScenarioSucceeded,
  updateScenarioSucceeded,
  createScenarioSucceeded,
  getScenarioSucceded
} from '../actions/scenario.actions';
import {
  supplyPointDeselected,
  legendLayerTurnedOff,
  pendingUnsavedSupplyPointReset,
  supplyPointSelected,
  manualPinClicked,
  generateNewSupplyPointClicked,
  spatialModellerModelLocationStarted
} from '../actions/spatial-modeller-test.actions';
import {
  deleteSupplyPointSucceeded,
  deleteSupplyPointClicked
} from '../actions/supply-point.actions';
import { addingPolygonCancelled } from 'src/app/core/store/actions/locator-ui.actions';

@Injectable()
export class MapLayerEffects {
  constructor(
    private actions$: Actions,
    private pinDropAndSelectionService: PinDropAndSelectionService,
    private mapService: MapService
  ) {}

  hideFeatureResultLayersAndPinDropLayer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(supplyPointSelected, manualPinClicked),
        map(() => {
          this.hideFeatureResultLayersAndPinDropLayer();
        })
      ),
    { dispatch: false }
  );

  hideSpatialModellerMapResultLayers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          legendLayerTurnedOff,
          selectLocationSucceded,
          manualPinDropSucceded,
          manualPinDragSucceded,
          findMyLocationSucceded,
          defineNewLocationSucceeded,
          generateNewSupplyPointClicked,
          deleteSupplyPointClicked,
          spatialModellerModelLocationStarted
        ),
        map(() => {
          this.hideSpatialModellerMapResultLayers();
          this.hideLocatorMapResultsLayers();
        })
      ),
    { dispatch: false }
  );

  hideLocatorMapResultLayers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locationFromLocationGridClicked),
        map(() => {
          this.hideLocatorMapResultsLayers();
          hidePinDropLayerIdentifier(this.mapService);
        })
      ),
    { dispatch: false }
  );

  hideLocatorMapResultLayersWhenEditing$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          updateLocatorShapeAttempt,
          createLocatorShapesAttempt,
          deleteLibraryLocationShapeAttempt
        ),
        map(() => {
          this.hideLocatorMapResultsLayers();
        })
      ),
    { dispatch: false }
  );

  hideLocatorMapResultLayersWhenAllShapesSelected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(allShapesFromLocationShapeListClicked),
        map(({ allSelected }) => {
          allSelected
            ? hideLocatorShapeSpatialFunctionSystemLayer(this.mapService)
            : this.hideLocatorMapResultsLayers();
        })
      ),
    { dispatch: false }
  );

  hideFeatureMapResultLayersAndSelectionLayer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          supplyPointDeselected,
          deleteSupplyPointSucceeded,
          searchTextCleared,
          pendingUnsavedSupplyPointReset,
          swapScenarioSucceeded,
          updateScenarioSucceeded,
          createScenarioSucceeded,
          getScenarioSucceded,
          getLocatorLibrarySucceded,
          swapLibrarySucceeded,
          createLibrarySucceeded,
          deleteLibraryLocationsSucceeded,
          addingPolygonCancelled,
          clearMapAndGazetterForMultiSelect,
          deselectAllLocations
        ),
        map(() => {
          this.pinDropAndSelectionService.removeSelectionLayer();
          this.pinDropAndSelectionService.removeDraggableSelectionLayer();
          this.hideFeatureResultLayersAndPinDropLayer();
        })
      ),
    { dispatch: false }
  );

  refreshBaseDeltaLayers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          swapScenarioSucceeded,
          updateScenarioSucceeded,
          createScenarioSucceeded
        ),
        map(({ scenario }) => {
          this.pinDropAndSelectionService.refreshBaseAndDeltaLayersScenarioData(
            scenario.id
          );
        })
      ),
    { dispatch: false }
  );

  refreshLocatorLibraryLocationsLayers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(swapLibrarySucceeded, createLibrarySucceeded),
        map(({ library }) => {
          this.pinDropAndSelectionService.refreshLocatorLibraryLocationsTileset(
            library.id
          );
        })
      ),
    { dispatch: false }
  );

  updateDraggableSelectionLayerLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeLocationCancelledCompleted),
        map((point) => {
          this.pinDropAndSelectionService.updateDraggableSelectionLayerLocation(
            point.location
          );
          this.mapService.centreMap({
            latitude: point.location.latitude,
            longitude: point.location.longitude
          });
        })
      ),
    { dispatch: false }
  );

  private hideFeatureResultLayersAndPinDropLayer() {
    this.hideSpatialModellerMapResultLayers();
    this.hideLocatorMapResultsLayers();
    hidePinDropLayerIdentifier(this.mapService);
  }

  private hideLocatorMapResultsLayers() {
    hideLocatorDataShapesMapLayer(this.mapService);
    hideLocatorShapeSpatialFunctionSystemLayer(this.mapService);
    hideLocatorEditPolygonSystemLayer(this.mapService);
  }
  private hideSpatialModellerMapResultLayers() {
    hideModelTestResultsMapLayer(this.mapService);
    hideModelResultsDrivetimeMapLayer(this.mapService);
    hideModelResultsCircleMapLayer(this.mapService);
  }
}
