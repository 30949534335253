import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { Router } from '@angular/router';
import { getSelectedPbiReportUrl } from 'src/app/core/helpers/power-bi-reports.helper';
import { SharedReportViewerToken } from 'src/app/core/models/shared-report-viewer-token';
import { PowerBiReportViewerService } from 'src/app/core/services/report-viewer.service';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';

import { LocatorPowerBiReportingInfo } from 'src/app/locator/models/locator-power-bi-reporting-info';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { PowerBiReportTypes } from 'src/app/locator/types/power-bi-report.types';
import {
  applyFilter,
  applySort,
  ListSortOptions,
  SearchAndSortValues
} from 'src/app/shared/utils/list-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'atlas-locator-reporting-form',
  styleUrls: ['locator-reporting-form.component.less'],
  templateUrl: 'locator-reporting-form.component.html'
})
export class LocatorReportingFormComponent implements OnInit {
  private selectedSortOption: ListSortOptions = 'NameAsc';
  private _pbiReports: LocatorPowerBiReportingInfo[] = [];
  @Input()
  set pbiReports(newValue: LocatorPowerBiReportingInfo[]) {
    this._pbiReports = [...newValue];
    this.dataSource = applySort(this.selectedSortOption, [...newValue]);
  }
  get pbiReports(): LocatorPowerBiReportingInfo[] {
    return this._pbiReports;
  }

  @Input() selectedShapes: LocatorShape[];
  @Input() selectedLocationData: LocatorLibraryData;

  locatorReportingForm: UntypedFormGroup;
  dataSource: LocatorPowerBiReportingInfo[];
  selectedReport: LocatorPowerBiReportingInfo | null = null;
  selectedPbiReportUrl: string = '';

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private powerBiReportViewerService: PowerBiReportViewerService
  ) {}

  ngOnInit(): void {
    const initialValue: SearchAndSortValues = {
      filter: ''
    } as SearchAndSortValues;
    this.locatorReportingForm = this.createForm(initialValue);
  }

  createForm(initialLibraryValue: SearchAndSortValues) {
    return this.fb.group({
      filter: [initialLibraryValue.filter]
    });
  }

  onFilterChanged(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource = applyFilter(filterValue, this._pbiReports);
  }

  onSelectionChange(change: MatSelectionListChange) {
    this.selectedPbiReportUrl = '';
    this.selectedReport = change.options.find(
      (option) => option.selected
    )?.value;

    const selectedShapeIds = this.selectedShapes.map((shape) => shape.id);

    getSelectedPbiReportUrl(
      this.powerBiReportViewerService,
      this.router,
      this.selectedReport?.workspaceId!,
      this.selectedReport?.powerBiReportId!,
      [this.selectedLocationData.libraryId, selectedShapeIds]
    ).subscribe((pbiReportUrl) => (this.selectedPbiReportUrl = pbiReportUrl));
  }
}
