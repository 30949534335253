import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'atlas-help-workspace',
  templateUrl: './help-workspace.component.html',
  styleUrls: ['./help-workspace.component.less']
})
export class HelpWorkspaceComponent {
  constructor(private route: Router) {}
  onBack() {
    this.route.navigate(['/home']);
  }
}
