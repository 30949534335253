import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromProfilerStore from 'src/app/profiler-store';
import { ProfilerImportStatusError } from '../model/profiler-import-status-error';
import { 
  fileImportHeaderValidationFailed, 
  fileImportHeaderValidationSuccess 
} from 'src/app/profiler-store/actions/profiler-import-validation.actions';

const expectedHeaders = ['uid', 'address_line_1', 'address_line_2', 'address_line_3', 'address_line_4', 'postcode'];

@Injectable({
  providedIn: 'root'
})
export class ImportValidationService {

  constructor(
    private store$: Store<fromProfilerStore.State>
  ) { }

  async validateFileHeaders(file: File) {
    let actualHeaders = await this.readFirstLine(file);
    let validationErrors = this.validateHeaders(actualHeaders);

    validationErrors.length === 0 
      ? this.store$.dispatch(fileImportHeaderValidationSuccess({ file }))
      : this.dispatchErrors(validationErrors);
  }

  private dispatchErrors(validationErrors: string[]) {
    let errorMessageList = validationErrors.map((message) => ({
      error: message,
    }))
    const profilerImportStatusError: ProfilerImportStatusError = {
      status: 'FileValidationFailed',
      errors: errorMessageList,
    };
    this.store$.dispatch(fileImportHeaderValidationFailed({
      errors: JSON.stringify(profilerImportStatusError)
    }));
  }

  private async readFirstLine(file: File): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        let contents = event.target?.result as string;
        let firstLine = contents.split('\r\n')[0].replace(/"/g, '');
        let headers = firstLine!.split(',');
        resolve(headers);
      };

      reader.onerror = (event: ProgressEvent<FileReader>) => {
        reject(event.target?.error);
      };

      reader.readAsText(file.slice(0, 100));
    });
  }

  private validateHeaders(actualHeaders: string[]): string[] {
    let errors: string[] = [];
    let missingHeaderNames: string[] = this.checkHeaderNames(actualHeaders);
    missingHeaderNames.forEach(missingHeader => {
      errors.push(`Missing column: ${missingHeader}`);
   });
    return errors;
  }

  private checkHeaderNames(fields: string[]): string[] {
    return expectedHeaders.filter(column => !fields.includes(column));
 }
}
