import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, map, switchMap, withLatestFrom, tap } from 'rxjs/operators';

import * as fromScenarioStore from 'src/app/spatial-modeller-store';
import * as fromUIStore from 'src/app/core/store';
import {
  smFeatureClicked,
  smLayersSucceeced
} from 'src/app/core/store/actions/spatial-modeller-ui.actions';
import { MapLayerTemplateService } from 'src/app/core/services/map-layers-template.service';
import { getScenarioId } from 'src/app/spatial-modeller-store';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import { PinDropLayer } from 'src/app/shared/atlas-mapping/layers/pin-drop-layer';
import {
  addLocatorLibraryLocationsSystemTilesetLayers,
  addPinDropLayer,
  addSpatialModelSystemTilesetLayers,
  buildSystemLayers
} from 'src/app/shared/atlas-mapping/helpers/system-layers-helper';
import { buildMapLayer } from 'src/app/shared/atlas-mapping/helpers/map-layer-helper';
import { SelectedFeature } from 'src/app/core/models/feature';
import {
  locatorFeatureClicked,
  locatorLayersSucceeded
} from 'src/app/core/store/actions/locator-ui.actions';
import {
  getScenarioSucceded,
  swapScenarioSucceeded
} from 'src/app/spatial-modeller-store/actions/scenario.actions';
import { LocalStorageService } from '../../services/local-storage.service';
import {
  profilerFeatureClicked,
  profilerLayersSucceeded
} from '../actions/profiler-ui.actions';
import {
  getNetworkPlanningUseTilesetForBaseLayers,
  isLocatorFeatureAllowed,
  isProfilerFeatureAllowed,
  isSMFeatureAllowed
} from 'src/app/core/store';
import { noFeatureSelected } from '../actions/app-feature-ui.actions';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { getLocatorLibrarySucceded } from 'src/app/locator-store/actions/locator-library.actions';
import { getLocatorLibraryId } from 'src/app/locator-store';
import {
  getClassifications,
  getImportStatusMessage,
  getProfilerImportStatus,
  getProfilerLibraryId
} from 'src/app/profiler-store';
import {
  configureProfilerLocationsLayer,
  createProfilerLibrarySucceeded,
  getProfilerLibrarySucceded,
  noLastEditedProfileFound,
  profilerLocationsLayerMedianPointFound,
  swapProfilerLibrarySucceeded
} from 'src/app/profiler-store/actions/profiler-library.actions';
import { showProfilerLibraryLocationsSystemLayer } from 'src/app/profiler/helpers/profiler-map-layer-helper';
import { PollProfilerImportStatusCompleted } from 'src/app/profiler-store/actions/profiler-import-status.actions';
import {
  smScenarioBaseTilesetLayerIdentifier,
  systemTilesetLayerIdentifiers
} from 'src/app/shared/atlas-mapping/layers/layer.constants';

@Injectable()
export class LayerManagementEffects implements OnDestroy {
  constructor(
    private actions$: Actions,
    private store$: Store<fromScenarioStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    private mapLayerTemplateService: MapLayerTemplateService,
    private mapService: MapService,
    private pinDropAndSelectionService: PinDropAndSelectionService,
    private pinDropLayer: PinDropLayer,
    private localStorageService: LocalStorageService,
    private appFeatureStateService: AppFeatureStateService
  ) {}

  private subscription = new Subscription();
  getClickedFeature = new Map<string, any>([
    [SelectedFeature.Modeller, smFeatureClicked()],
    [
      SelectedFeature.Locator,
      locatorFeatureClicked({
        locatorShapeType: this.localStorageService.get('locator-default-shape')
      })
    ],
    [SelectedFeature.Profiler, profilerFeatureClicked()]
  ]);

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadLayersForDefaultFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        getScenarioSucceded,
        swapScenarioSucceeded, // added here to have the Base and Delta layers in sync with the new scenario data
        getLocatorLibrarySucceded,
        getProfilerLibrarySucceded
      ),
      withLatestFrom(
        this.UIStore$.select(isSMFeatureAllowed),
        this.UIStore$.select(isLocatorFeatureAllowed),
        this.UIStore$.select(isProfilerFeatureAllowed)
      ),
      map(
        ([
          _,
          smFeatureAllowed,
          locatorFeatureAllowed,
          profilerFeatureAllowed
        ]) => {
          let selectedFeature =
            this.localStorageService.get('selected-feature');
          if (!selectedFeature) {
            if (smFeatureAllowed) {
              selectedFeature = SelectedFeature.Modeller;
            } else if (locatorFeatureAllowed) {
              selectedFeature = SelectedFeature.Locator;
            } else if (profilerFeatureAllowed) {
              selectedFeature = SelectedFeature.Profiler;
            }
          }

          return this.getFeatureToSelect(
            selectedFeature,
            smFeatureAllowed,
            locatorFeatureAllowed,
            profilerFeatureAllowed
          );
        }
      )
    )
  );

  loadSmLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smFeatureClicked),
      withLatestFrom(
        this.store$.select(getScenarioId),
        this.store$.select(getNetworkPlanningUseTilesetForBaseLayers)
      ),
      filter(([_, scenarioId]) => scenarioId > 0),
      switchMap(([_, scenarioId, useTilesetForBaseLayers]) => {
        return this.mapLayerTemplateService.getAllSpatialModellerLayers().pipe(
          map((templates) => {
            this.addLayersToMapService(
              templates,
              scenarioId,
              SelectedFeature.Modeller,
              useTilesetForBaseLayers,
              false
            );
            this.localStorageService.set(
              'selected-feature',
              SelectedFeature.Modeller
            );

            return smLayersSucceeced();
          })
        );
      })
    )
  );

  loadLocatorLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorFeatureClicked),
      this.appFeatureStateService.allowWhenLocatorFeatureNotLoaded(),
      withLatestFrom(
        this.store$.select(getLocatorLibraryId),
        this.store$.select(
          fromUIStore.getCatchmentReportingUseLibraryLocationsLayer
        )
      ),
      filter(([_, libraryId]) => libraryId > 0),
      switchMap(
        ([
          { locatorShapeType },
          libraryId,
          useLocatorLibraryLocationsLayer
        ]) => {
          return this.mapLayerTemplateService.getAllLocatorLayers().pipe(
            map((templates) => {
              this.addLayersToMapService(
                templates,
                libraryId,
                SelectedFeature.Locator,
                false,
                useLocatorLibraryLocationsLayer
              );
              this.localStorageService.set(
                'selected-feature',
                SelectedFeature.Locator
              );
              return locatorLayersSucceeded({ locatorShapeType });
            })
          );
        }
      )
    )
  );

  loadProfilerLayers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        profilerFeatureClicked,
        createProfilerLibrarySucceeded,
        noLastEditedProfileFound
      ),
      withLatestFrom(this.store$.select(getProfilerLibraryId)),
      switchMap(([_, libraryId]) => {
        return this.mapLayerTemplateService.getAllProfilerLayers().pipe(
          switchMap(async (templates) => {
            this.addLayersToMapService(
              templates,
              libraryId,
              SelectedFeature.Profiler,
              false,
              false
            );
            this.localStorageService.set(
              'selected-feature',
              SelectedFeature.Profiler
            );

            return profilerLayersSucceeded();
          })
        );
      })
    )
  );

  // Profiler locations layer is a dynamic tileset that needs to be added and removed for all changes
  // this is outside of the norms of our other system layers
  profilerLayersSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profilerLayersSucceeded),
      withLatestFrom(
        this.store$.select(getProfilerLibraryId),
        this.store$.select(getClassifications),
        this.store$.select(getImportStatusMessage)
      ),
      filter(
        ([_, libraryId, classification, importStatusMessage]) =>
          libraryId > 0 && importStatusMessage === 'ReportAggregateCompleted'
      ),
      switchMap(([_, libraryId, classifications]) => {
        return this.mapLayerTemplateService.getAllProfilerLayers().pipe(
          switchMap(async (templates) => {
            showProfilerLibraryLocationsSystemLayer(
              libraryId,
              classifications[0],
              this.mapService
            );

            return configureProfilerLocationsLayer();
          })
        );
      })
    )
  );

  // once the locations layer has been configured the median point must be calculated and the map repositioned
  configureProfilerLocationsLayerCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        configureProfilerLocationsLayer,
        swapProfilerLibrarySucceeded,
        PollProfilerImportStatusCompleted
      ),
      withLatestFrom(
        this.store$.select(getProfilerLibraryId),
        this.store$.select(getProfilerImportStatus)
      ),
      filter(
        ([_, libraryId, profilerImportStatus]) =>
          profilerImportStatus?.status === 'ReportAggregateCompleted'
      ),
      switchMap(([_, libraryId, profilerImportStatus]) => {
        return this.mapLayerTemplateService
          .getProfileMedianPoint(libraryId)
          .pipe(
            switchMap(async (point) => {
              this.mapService.zoomMap(point.medianPoint, point.zoom);

              return profilerLocationsLayerMedianPointFound();
            })
          );
      })
    )
  );

  // if the agruments grow to large consider an options class to cater for them
  private async addLayersToMapService(
    templates: any[],
    featureContainerId: number, // scenarioId or libraryId
    selectedFeature: SelectedFeature,
    networkPlanningUseTilesetForBaseLayers: boolean,
    locatorUseLibraryLocationsLayer?: boolean
  ) {
    this.mapService.removeAllLayers();
    if (featureContainerId != 0) {
      if (selectedFeature == SelectedFeature.Modeller) {
        if (this.mapService.getAllLayers().size == 0) {
          this.addMapLayers(
            templates,
            selectedFeature,
            featureContainerId,
            networkPlanningUseTilesetForBaseLayers,
            locatorUseLibraryLocationsLayer
          );
        } else {
          await this.syncScenarioWithMapData(featureContainerId);
        }
      } else if (selectedFeature == SelectedFeature.Locator) {
        if (this.mapService.getAllLayers().size == 0) {
          this.addMapLayers(
            templates,
            selectedFeature,
            featureContainerId,
            networkPlanningUseTilesetForBaseLayers,
            locatorUseLibraryLocationsLayer
          );
        }
      } else if (selectedFeature == SelectedFeature.Profiler) {
        if (this.mapService.getAllLayers().size == 0) {
          this.addMapLayers(
            templates,
            selectedFeature,
            featureContainerId,
            networkPlanningUseTilesetForBaseLayers,
            locatorUseLibraryLocationsLayer
          );
        }
      }
    }
  }

  // Layers added to the map array are added above the Mapbox map layer.
  // A layer with a lower value you will be closer to the map back cloth
  private async addMapLayers(
    mapTemplates: any[],
    selectedFeature: SelectedFeature,
    featureContainerId?: number, // Optional parameter used for Modeller scenario Id and locator library id
    networkPlanningUseTilesetForBaseLayers?: boolean,
    locatorUseLibraryLocationsLayer?: boolean
  ) {
    this.addSystemLayers(
      selectedFeature,
      featureContainerId,
      networkPlanningUseTilesetForBaseLayers,
      locatorUseLibraryLocationsLayer
    );

    this.addLayers(mapTemplates);

    await addPinDropLayer(
      this.mapService,
      this.pinDropAndSelectionService,
      this.subscription,
      this.pinDropLayer
    );

    if (featureContainerId != 0 && selectedFeature == 'Modeller') {
      this.syncScenarioWithMapData(featureContainerId!);
    }

    if (networkPlanningUseTilesetForBaseLayers) {
      addSpatialModelSystemTilesetLayers(
        this.mapService,
        featureContainerId!,
        mapTemplates
      );
    }

    if (locatorUseLibraryLocationsLayer) {
      addLocatorLibraryLocationsSystemTilesetLayers(
        this.mapService,
        featureContainerId!,
        mapTemplates
      );
    }
  }

  private addSystemLayers(
    selectedFeature: SelectedFeature,
    scenarioId?: number, // optional parameter used only for Modeller
    networkPlanningUseTilesetForBaseLayers?: boolean,
    locatorUseLibraryLocationsLayer?: boolean
  ) {
    return buildSystemLayers[selectedFeature](
      this.mapService,
      scenarioId!,
      networkPlanningUseTilesetForBaseLayers!,
      locatorUseLibraryLocationsLayer!
    );
  }

  private addLayers(mapTemplates: any[]) {
    mapTemplates.forEach(async (t) => {
      if (!systemTilesetLayerIdentifiers.includes(t.identifier)) {
        var layer = buildMapLayer(t.type, t);
        layer.zOrder = t.zOrder;
        layer.type = t.type;
        await this.mapService.addLayer(layer);
      }
    });
  }

  private syncScenarioWithMapData(scenarioId: number) {
    this.pinDropAndSelectionService.refreshBaseAndDeltaLayersScenarioData(
      scenarioId
    );
  }

  private getFeatureToSelect(
    selectedFeature: any,
    smFeatureAllowed: boolean,
    locatorFeatureAllowed: boolean,
    profilerFeatureAllowed: boolean
  ) {
    const isFeatureAllowed = new Map<string, boolean>([
      [SelectedFeature.Modeller, smFeatureAllowed],
      [SelectedFeature.Locator, locatorFeatureAllowed],
      [SelectedFeature.Profiler, profilerFeatureAllowed]
    ]);

    if (isFeatureAllowed.get(selectedFeature)) {
      return this.getClickedFeature.get(selectedFeature);
    } else {
      for (const feature in SelectedFeature) {
        if (isFeatureAllowed.get(feature))
          return this.getClickedFeature.get(feature);
      }
    }
    return noFeatureSelected();
  }
}
