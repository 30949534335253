import { createSelector } from '@ngrx/store';
import * as fromCore from '../reducers';

export const getUserLocatorSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.locator
);

export const getUserGeneralSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.general
);

export const getUserNetworkPlanningSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.networkPlanning
);

export const getUserNetworkPlanningSettingsLiveModeDisabled = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.networkPlanning?.liveModeDisabled
);

export const getUserSettings = createSelector(
  getUserGeneralSettings,
  getUserLocatorSettings,
  getUserNetworkPlanningSettings,
  (general, locator, networkPlanning) => {
    return { general, locator, networkPlanning };
  }
);
