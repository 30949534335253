import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, Subscription } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LoadOptions } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';

import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import {
  ListSortOptions,
  SortOptions,
  SearchAndSortValues,
  LocatorListSortOptions
} from 'src/app/shared/utils/list-utils';
import * as fromLocator from 'src/app/locator-store';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { getLocatorLibraryDataSucceded } from 'src/app/locator-store/actions/locator-library.actions';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { DataGridService } from '../../services/datagrid.service';
import {
  clearMapAndGazetterForMultiSelect,
  deselectAllLocations
} from 'src/app/locator-store/actions/locator-location.actions';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'atlas-library-locations-list',
  styleUrls: ['./locator-library-locations-list.component.less'],
  templateUrl: './locator-library-locations-list.component.html'
})
export class LocatorLibraryLocationsListComponent implements OnInit {
  private _libraryId: number | null;
  private subscription: Subscription = new Subscription();
  private selectedSortOption: ListSortOptions | LocatorListSortOptions = 'NameAsc';

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  @Input()
  template: TemplateRef<any>;

  @Input() selectedLocationId: number | null;

  @Input()
  set libraryId(newValue: number | null) {
    this._libraryId = newValue;
    this.resetForm();
    this.resetGridlist();
  }
  get libraryId() {
    return this._libraryId;
  }

  @Output()
  locationsClickSelected = new EventEmitter<LocatorLibraryData[]>();

  sortOptions: SortOptions[] = [
    { value: 'NameAsc', display: 'Name: A-Z' },
    { value: 'NameDesc', display: 'Name: Z-A' },
    { value: 'CatchmentsHighLow', display: 'Catchments: High to Low' },
    { value: 'CatchmentsLowHigh', display: 'Catchments: Low to High' },
  ];

  defaultSort: any[] = [{ selector: 'name', desc: false }];
  selectionMode: 'single' | 'multiple' = 'single';
  isSelected = false;
  allSelected = false;
  searchForm: UntypedFormGroup;
  countMessage: string = '';
  totalCount: number | undefined = 0;
  filteredCount: number;
  columnWidth: number = 250;

  customDataSource = new DataSource({
    store: new CustomStore({
      key: 'id',
      load: async (loadOptions: LoadOptions) => {
        // Default sort configuration
        if (loadOptions.sort == null) {
          loadOptions.sort = this.defaultSort;
        }

        this.allSelected
          ? (loadOptions.userData = { selectAll: true })
          : (loadOptions.userData = {});

        const result: any = await lastValueFrom(
          this.locatorDataService.getLocatorDataByLibrarySearch(
            this.libraryId!,
            loadOptions
          )
        );

        this.totalCount = result.allRecordsCount;
        this.filteredCount = result.locatorLoadResult.totalCount;

        if (this.searchForm.value.filter && loadOptions.filter)
          this.countMessage = `Showing ${this.filteredCount} of ${this.totalCount} total`;
        else this.countMessage = `Showing ${this.totalCount} total`;

        this.store$.dispatch(
          getLocatorLibraryDataSucceded({
            locations: result.locatorLoadResult.data
          })
        );

        return result.locatorLoadResult;
      }
    })
  });

  constructor(
    private fb: UntypedFormBuilder,
    private store$: Store<fromLocator.State>,
    private locatorDataService: LocatorService,
    private dataGridService: DataGridService,
    private gazetteerStore$: Store<fromGazetteerStore.State>
  ) {}

  ngOnInit(): void {
    const initialValue: SearchAndSortValues = {
      filter: '',
      sort: 'NameAsc'
    } as SearchAndSortValues;
    this.searchForm = this.createForm(initialValue);
  }

  ngAfterViewInit() {
    this.dataGridService.setDataGrid(this.dataGrid);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm(initialLibraryValue: SearchAndSortValues) {
    return this.fb.group({
      filter: [initialLibraryValue.filter],
      sort: [initialLibraryValue.sort]
    });
  }

  itemSelected(event: any) {
    let selectedRowData = event.selectedRowsData;

    if (selectedRowData) {
      this.locationsClickSelected.emit(selectedRowData);

      // If the user is multiselecting then we need to clear the map
      // and the gazetteer
      if (selectedRowData.length > 1) {
        this.clearMapAndGazetteerForMultiSelect();
      }

      if (selectedRowData.length === 0) {
        this.dispatchDeselectAll();
      }
    }
  }

  onSortChanged(event: any) {
    this.selectedSortOption = event.value;
    this.dataGrid.instance.clearSorting();

    switch (this.selectedSortOption) {
      case 'NameAsc':
        this.dataGrid.instance.columnOption('name', { sortOrder: 'asc' });
        break;
      case 'NameDesc':
        this.dataGrid.instance.columnOption('name', { sortOrder: 'desc' });
        break;
      case 'CatchmentsHighLow':
        this.dataGrid.instance.columnOption(
          this.allSelected ? 'shapeCount' : 'shapes.length',
          { sortOrder: 'desc' }
        );
        break;
      case 'CatchmentsLowHigh':
        this.dataGrid.instance.columnOption(
          this.allSelected ? 'shapeCount' : 'shapes.length',
          { sortOrder: 'asc' }
        );
        break;
 
      default:
        throw new Error('Sort Option invalid');
    }
  }

  onFilterChanged(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    this.dataGrid.instance.searchByText(filterValue);
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    if (event.selectedRowKeys.length <= 1 && this.allSelected) {
      this.allSelected = false;
      this.dataGrid?.instance
        .getDataSource()
        .reload()
        .then((dataSource: any[]) => {
          if (event.selectedRowKeys.length === 1) {
            const selectedItem = dataSource.find(
              (s) => s.id === event.selectedRowKeys[0]
            );
            this.locationsClickSelected.emit([selectedItem]);
          }
        });
    }
  }

  toggleSelection(event: MatCheckboxChange) {
    if (!event.checked) {
      this.deselectedSelectedItems();
    }
    this.isSelected = event.checked;
    this.selectionMode = event.checked ? 'multiple' : 'single';
    this.columnWidth = this.isSelected ? 212 : 250;
  }

  deselectedSelectedItems() {
    this.resetGridlist();
    this.dispatchDeselectAll();
  }

  async selectAll(event: MatCheckboxChange) {
    this.allSelected = event.checked;
    if (event.checked) {
      this.dataGrid?.instance.selectAll().then(async () => {
        await this.reloadGrid();
      });
    } else {
      this.dataGrid?.instance.deselectAll().then(async () => {
        await this.reloadGrid();
      });
      this.dispatchDeselectAll();
    }
  }

  getShapeCountDataField(): string {
    let result = this.allSelected ? 'shapeCount' : 'shapes.length';
    return result;
  }

  private clearMapAndGazetteerForMultiSelect() {
    this.gazetteerStore$.dispatch(clearMapAndGazetterForMultiSelect());
  }

  private dispatchDeselectAll() {
    this.store$.dispatch(deselectAllLocations());
  }

  private async resetGridlist() {
    if (this.dataGrid) {
      this.dataGrid.instance.clearFilter();
      this.dataGrid.instance.clearSelection();
      this.dataGrid?.instance.option('focusedRowKey', null);
      this.selectionMode = 'single';
      this.isSelected = false;
      this.allSelected = false;
      await this.reloadGrid();
    }
  }

  private resetForm() {
    this.searchForm?.patchValue({
      filter: '',
      sort: 'NameAsc'
    });
  }

  private async reloadGrid() {
    await this.dataGrid?.instance.getDataSource().reload();
  }
}
