import { createReducer, on } from '@ngrx/store';
import {
  findMyLocationSucceded,
  manualPinDragSucceded,
  manualPinDropSucceded,
  searchTextCleared,
  selectLocationSucceded
} from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import { Stats } from 'src/app/spatial-modeller/models/stats';
import { deleteSupplyPointSucceeded } from '../actions/supply-point.actions';
import { spatialModellerGetStatsSucceded } from '../actions/spatial-modeller-stats.actions';
import {
  generateNewSupplyPointClicked,
  manualPinClicked,
  pendingUnsavedSupplyPointReset,
  spatialModellerModelLocationStarted,
  spatialModellerModelLocationStartedFromSPClose,
  spatialModellerModelLocationStartedFromSPReOpen,
  spatialModellerModelLocationStartedFromSPSave,
  supplyPointSelected
} from '../actions/spatial-modeller-test.actions';
import {
  legendLayerTurnedOff,
  spatialModellerTestStarted,
  supplyPointDeselected
} from '../actions/spatial-modeller-test.actions';
import {
  swapScenarioSucceeded,
  createScenarioSucceeded,
  updateScenarioSucceeded
} from '../actions/scenario.actions';

export interface State {
  stats: Stats[] | null;
}

export const initialState: State = {
  stats: null
};

export const spatialModellerStatsReducer = createReducer(
  initialState,
  on(spatialModellerGetStatsSucceded, (state, { stats }) => {
    return {
      ...state,
      stats: stats
    };
  }),
  on(
    spatialModellerTestStarted,
    searchTextCleared,
    supplyPointDeselected,
    deleteSupplyPointSucceeded,
    legendLayerTurnedOff,
    pendingUnsavedSupplyPointReset,
    swapScenarioSucceeded,
    createScenarioSucceeded,
    updateScenarioSucceeded,
    spatialModellerModelLocationStarted,
    spatialModellerModelLocationStartedFromSPSave,
    spatialModellerModelLocationStartedFromSPClose,
    spatialModellerModelLocationStartedFromSPReOpen,
    selectLocationSucceded,
    manualPinDropSucceded,
    manualPinDragSucceded,
    findMyLocationSucceded,
    manualPinClicked,
    generateNewSupplyPointClicked,
    supplyPointSelected,
    (state, _) => {
      return {
        ...state,
        stats: null
      };
    }
  )
);
