import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { SnackbarService } from 'src/app/core/services/snackbar.service';

import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import { findMyLocationClicked } from '../../store/actions/gazetteer.actions';
import * as fromUIStore from 'src/app/core/store';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import * as fromProfilerStore from 'src/app/profiler-store';
import { getProfilerLibraryId } from 'src/app/profiler-store';
import { combineLatest, delay, map } from 'rxjs';

@Component({
  selector: 'atlas-find-my-location',
  templateUrl: './atlas-find-my-location.component.html',
  styleUrls: ['../less/atlas-gazetteer-button.less']
})
export class FindMyLocationComponent {
  // It prevents the user to click in this button before the layer has been created
  pinDropLayerAvailable$ =
    this.pinDropAndSelectionService.pinDropLayerAvailability$;

  isLocationChanging$ = this.UIStore$.select(fromUIStore.isLocationChanging);
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );

  profilerStatus$ = this.profilerStore$.select(
    fromProfilerStore.getImportStatusMessage
  );
  libraryId$ = this.profilerStore$.select(getProfilerLibraryId);
  userLibraries: ProfilerLibrary[];

  isNetworkPlanningReadonlySystem$ = this.UIStore$.select(
    fromUIStore.getNetworkPlanningReadonlySystem
  );

  isFindMyLocationButtonDisabled$ = combineLatest([
    this.pinDropLayerAvailable$,
    this.isLocationChanging$,
    this.isProfilerFeatureSelected$,
    this.libraryId$,
    this.isNetworkPlanningReadonlySystem$
  ]).pipe(
    delay(100),
    map(
      ([
        pinDropLayerAvailable,
        isLocationChanging,
        isProfilerFeatureSelected,
        libraryId,
        isNetworkPlanningReadonlySystem
      ]) => {
        return (
          !pinDropLayerAvailable ||
          isLocationChanging ||
          (isProfilerFeatureSelected && libraryId === 0) ||
          isNetworkPlanningReadonlySystem
        );
      }
    )
  );

  constructor(
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private snackbarService: SnackbarService,
    private pinDropAndSelectionService: PinDropAndSelectionService,
    private UIStore$: Store<fromUIStore.State>,
    private profilerStore$: Store<fromProfilerStore.State>
  ) {}

  onFindMyLocation() {
    this.getGeoLocation();
  }

  getGeoLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.showGeoPosition(position),
        (error) => this.showGeoLocationError(error)
      );
    } else {
      this.snackbarService.error(
        'Geo location error',
        'Geolocation is not supported by this browser.'
      );
    }
  }

  showGeoPosition(position: GeolocationPosition) {
    this.gazetteerStore$.dispatch(
      findMyLocationClicked({
        location: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }
      })
    );
  }

  showGeoLocationError(error: GeolocationPositionError) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.snackbarService.error(
          'Geo location error',
          'User denied the request for Geolocation.'
        );
        break;
      case error.POSITION_UNAVAILABLE:
        this.snackbarService.error(
          'Geo location error',
          'Location information is unavailable.'
        );
        break;
      case error.TIMEOUT:
        this.snackbarService.error(
          'Geo location error',
          'The request to get user location timed out.'
        );
        break;
      default:
        this.snackbarService.error(
          'Geo location error',
          'An unknown error occurred.'
        );
        break;
    }
  }
}
