import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLocatorStore from 'src/app/locator-store';
import { LocatorImportStatusError } from '../models/locator-import-status-error';
import {
  locatorFileImportHeaderValidationSuccess,
  locatorFileImportHeaderValidationFailed
} from 'src/app/locator-store/actions/locator-import-validation.actions';

const expectedHeaders = [
  'uid',
  'description',
  'address1',
  'address2',
  'address3',
  'address4',
  'address5',
  'postal',
  'country',
  'latitude',
  'longitude'
];

@Injectable({
  providedIn: 'root'
})
export class LocatorImportValidationService {
  constructor(private store$: Store<fromLocatorStore.State>) {}

  async validateFileHeaders(file: File) {
    let actualHeaders = await this.readFirstLine(file);
    let validationErrors = this.validateHeaders(actualHeaders);

    validationErrors.length === 0
      ? this.store$.dispatch(locatorFileImportHeaderValidationSuccess({ file }))
      : this.dispatchErrors(validationErrors);
  }

  private dispatchErrors(validationErrors: string[]) {
    let errorMessageList = validationErrors.map((message) => ({
      error: message
    }));
    const LocatorImportStatusError: LocatorImportStatusError = {
      status: 'FileValidationFailed',
      errors: errorMessageList
    };
    this.store$.dispatch(
      locatorFileImportHeaderValidationFailed({
        errors: JSON.stringify(LocatorImportStatusError)
      })
    );
  }

  private async readFirstLine(file: File): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        let contents = event.target?.result as string;
        let firstLine = contents.split('\r\n')[0].replace(/"/g, '');
        let headers = firstLine!.split(',');
        resolve(headers);
      };

      reader.onerror = (event: ProgressEvent<FileReader>) => {
        reject(event.target?.error);
      };

      reader.readAsText(file.slice(0, 100));
    });
  }

  private validateHeaders(actualHeaders: string[]): string[] {
    let errors: string[] = [];

    if (actualHeaders.length > expectedHeaders.length) {
      errors.push(
        `The number of columns is bigger than expected. Please check your file again.`
      );
      return errors;
    }

    const missingHeaderNames: string[] = this.checkHeaderNames(actualHeaders);
    if (missingHeaderNames.length > 0) {
      let headerError = `All columns are not present or columns are not named correctly. Missing columns: `;
      missingHeaderNames.forEach((missingHeader, index) => {
        const separator: string =
          index !== missingHeaderNames.length - 1 ? ', ' : '';
        headerError = headerError.concat(missingHeader + separator);
      });
      errors.push(`${headerError}. Please check your file again.`);
      return errors;
    }

    if (missingHeaderNames?.length === 0) {
      const headersInWrongOrder: string[] =
        this.checkHeadersOrder(actualHeaders);
      let wrongOrderColumns: string = '';
      headersInWrongOrder.forEach((wrongOrderHeader, index) => {
        const separator: string =
          index !== missingHeaderNames.length - 1 ? ', ' : '';
        wrongOrderColumns = wrongOrderColumns.concat(
          wrongOrderHeader,
          separator
        );
      });
      if (wrongOrderColumns.length > 0) {
        errors.push(
          `Columns do not appear in the right order (${wrongOrderColumns}). Please check your file again.`
        );
        return errors;
      }
    }
    return errors;
  }

  private checkHeaderNames(fields: string[]): string[] {
    return expectedHeaders.filter((column) => !fields.includes(column));
  }

  private checkHeadersOrder(fields: string[]): string[] {
    let wrongOrderHeaders: string[] = [];
    expectedHeaders.forEach((column, index) => {
      if (fields.includes(column)) {
        if (fields[index] != column) {
          wrongOrderHeaders.push(column);
        }
      }
    });
    return wrongOrderHeaders;
  }
}
