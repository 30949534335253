import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UsageSummary } from '../../models/usage-summary';
import { UsageService } from '../../services/usage.service';
import {
  usageErrorOccured,
  getUsageSummaryAttempt,
  getUsageSummarySucceeded
} from '../actions/usage.actions';
@Injectable()
export class UsageEffects {
  constructor(private actions$: Actions, private usageService: UsageService) {}
  getUsageSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsageSummaryAttempt),
      switchMap(() => {
        return this.usageService.getUsageSummary().pipe(
          map((usageSummary: UsageSummary) => {
            return getUsageSummarySucceeded({ usageSummary });
          }),
          catchError((error) =>
            of(
              usageErrorOccured({
                errorOn: 'Error getting usage',
                error: error
              })
            )
          )
        );
      })
    )
  );
}
